<template>
  <section class="panel" v-if="store.step < 4">
    <ButtonMobile class="button button--full button--big button--centered button--red" @click="goForth()" v-if="Object.keys(store.cart).length != 0 && mutations.inCart() || hasTableFormLength">
      Перейти далее
    </ButtonMobile>
    <ButtonMobile class="button button--full button--big button--centered" @click="goForth()" v-else>
      Пропустить
    </ButtonMobile>
    <ButtonMobile class="button button--quad panel__button" v-if="store.step > 1 || store.subStep > 1" @click="goBack()">
      <svg width="24" height="24" viewBox="0 0 24 24"><path d="M6.0539 12.5907L16.6195 20.843C16.6471 20.8648 16.6803 20.8783 16.7153 20.882C16.7502 20.8857 16.7855 20.8795 16.8171 20.8641C16.8486 20.8487 16.8752 20.8247 16.8938 20.7948C16.9123 20.765 16.922 20.7305 16.9219 20.6954V18.8837C16.9219 18.7688 16.868 18.6587 16.7789 18.5883L8.3414 12.0001L16.7789 5.41178C16.8703 5.34147 16.9219 5.23131 16.9219 5.11647V3.30475C16.9219 3.14772 16.7414 3.061 16.6195 3.15709L6.0539 11.4094C5.9641 11.4795 5.89146 11.5691 5.84149 11.6714C5.79153 11.7738 5.76555 11.8862 5.76555 12.0001C5.76555 12.114 5.79153 12.2263 5.84149 12.3287C5.89146 12.431 5.9641 12.5206 6.0539 12.5907Z" fill="#404040"/></svg>
    </ButtonMobile>
    <ButtonMobile class="button button--quad panel__button" v-if="Object.keys(store.cart).length > 0" @click="openOrder">
      <svg width="24" height="24" viewBox="0 0 24 24"><path d="M7.00001 18C5.90001 18 5.01001 18.9 5.01001 20C5.01001 21.1 5.90001 22 7.00001 22C8.10001 22 9.00001 21.1 9.00001 20C9.00001 18.9 8.10001 18 7.00001 18ZM1.00001 3C1.00001 3.55 1.45001 4 2.00001 4H3.00001L6.60001 11.59L5.25001 14.03C4.52001 15.37 5.48001 17 7.00001 17H18C18.55 17 19 16.55 19 16C19 15.45 18.55 15 18 15H7.00001L8.10001 13H15.55C16.3 13 16.96 12.59 17.3 11.97L20.88 5.48C20.9643 5.32843 21.0075 5.15747 21.0054 4.98406C21.0034 4.81064 20.956 4.64077 20.8681 4.49126C20.7803 4.34175 20.6549 4.21778 20.5043 4.13162C20.3538 4.04546 20.1834 4.00009 20.01 4H5.21001L4.54001 2.57C4.45964 2.39931 4.33231 2.25504 4.17291 2.15409C4.01352 2.05315 3.82868 1.9997 3.64001 2H2.00001C1.45001 2 1.00001 2.45 1.00001 3ZM17 18C15.9 18 15.01 18.9 15.01 20C15.01 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18Z" fill="#404040"/></svg>
      <div class="button__circle">{{ totalProductsCount }}</div>
    </ButtonMobile>
  </section>
</template>

<script>
import ButtonMobile from './ButtonMobile.vue'

export default {
  inject: ['store', 'mutations'],
  components: { ButtonMobile },
  methods: {
    goForth() {
      let lastStep = this.store.step == 3 && this.store.subStep == 5;
      let lastSubStep = (this.store.step == 1 && this.store.subStep == 4) || (this.store.step > 1 && this.store.subStep == 5);

      if(lastStep) {
        this.mutations.setMaxStep(this.store.maxStep + 1);
        this.mutations.toggleModal();
      } else {
        if(lastSubStep) {

          if(this.store.maxStep == this.store.step) this.mutations.setMaxSubStep(1);
          if(this.store.maxStep == this.store.step) this.mutations.setMaxStep(this.store.maxStep + 1);
          this.mutations.setStep(this.store.step + 1);
          this.mutations.setSubStep(1);
          
        } else {
          if(this.store.maxSubStep <= this.store.subStep && this.store.maxStep <= this.store.step) {
            this.mutations.setMaxSubStep(this.store.maxSubStep + 1);
          }
          this.mutations.setSubStep(this.store.subStep + 1);
        }
        
        this.store.subnavigationMobileSwiper.slideTo(this.store.subStep - 1);
        this.scrollToSubNavigation();
        this.mutations.getProducts(this.store.kitchenId);
      }
      this.mutations.setCurrentPage(1);
    },
    goBack() {
      if(this.store.subStep == 1 && this.store.step == 2 ) {
        this.mutations.setSubStep(4);
        this.mutations.setStep(this.store.step - 1);
      } else if(this.store.subStep == 1 && this.store.step > 2) {
        this.mutations.setSubStep(5);
        this.mutations.setStep(this.store.step - 1);
      } else {
        this.mutations.setSubStep(this.store.subStep - 1);
      }

      this.store.subnavigationMobileSwiper.slideTo(this.store.subStep - 1);
      this.scrollToSubNavigation();
      this.mutations.getProducts(this.store.kitchenId);
      this.mutations.setCurrentPage(1);
    },
    openOrder() {
      this.mutations.setActiveSidebar('order');
      this.mutations.toggleCover();
    },
    scrollToSubNavigation() {
      window.scrollTo({
        top: 760,
        left: 0,
        // behavior: 'smooth'
      });
    }
  },
  computed: {
    totalProductsCount() {
      return Object.values(this.store.cart).map(el => el.COUNT).reduce((a, b) => a + b, 0).toLocaleString();
    },
    hasTableFormLength() {
      return this.mutations.isTableFormStep() && this.mutations.tables().filter(product => product.length != null).length > 0;
    }
  }
}
</script>

<style scoped>
.panel {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 5;
  padding: 16px;
  display: flex;
  align-items: center;
}

.panel__button {
  margin-left: 16px;
}

.button__circle {
  position: absolute;
  top: 6px;
  right: 7px;
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  background: #CC3333;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: white;
}
</style>