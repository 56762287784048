<template>
  <header class="header">
    <div class="header__content">
      <div class="header__cover" />
      <a class="header__logobox" :href="backLink">
        <img class="header__logo" :src="logoUrl" />
      </a>
      <a v-if="$route.path != '/'" class="header__back" :href="backLink">
        Вернуться в каталог
        <svg class="header__arrow" width="14" height="15" viewBox="0 0 14 15">
          <path
            d="M7.75427 4.1709L4.42519 7.49999L7.75427 10.8291L8.5791 10.0042L6.07485 7.49999L8.5791 4.99574L7.75427 4.1709Z"
            fill="#404040"
          />
        </svg>
      </a>
      <div>
        <a v-if="store.partner != ''" class="header__tel clicktrack" :href="`tel:${phoneNumber}`">{{
          styledPhoneNumber
        }}</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  inject: ['store'],
  computed: {
    backLink () {
      return this.store.isPartner ? `/#/?partnerId=${this.$route.query.partnerId}` : '/'
    },
    logoUrl () {
      return this.store.isPartner
        ? `https://33komoda.ru/${this.store.partner.DETAIL_PICTURE}`
        : require('@/assets/logo.svg')
    },
    phoneNumber () {
      return this.store.isPartner ? this.store.partner.PHONE : '+73433511975'
    },
    styledPhoneNumber () {
      return `${this.phoneNumber.slice(0, 2)} (${this.phoneNumber.slice(2, 5)}) ${this.phoneNumber.slice(
        5,
        8
      )}-${this.phoneNumber.slice(8, 10)}-${this.phoneNumber.slice(10, 12)}`
    }
  },
  created () {
    // (function () {
    //   const clickTracks = document.querySelectorAll('.clicktrack');
    //   function stopIt(e) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }
    //   function track(clickTrack) {
    //     let initial = clickTrack.innerText;
    //     let
    //       part,
    //       pattern,
    //       final,
    //       type;
    //     clickTrack.getAttribute('href') != null && clickTrack.getAttribute('href').match('mail') != null ? type = 'email' : type = 'phone';
    //     if (type == 'email') {
    //       part = clickTrack.innerText.slice(0, clickTrack.innerText.match('@').index);
    //       pattern = part.split('').map(() => '•').join('');
    //       final = clickTrack.innerText.replace(part, pattern);
    //     } else {
    //       part = clickTrack.innerText;
    //       part[0] == '+' ? part = part.slice(8) : part = part.slice(7);
    //       final = clickTrack.innerText.replace(part, ' ••• •• ••');
    //     }
    //     clickTrack.innerText = final;
    //     const button = document.createElement('div');
    //     button.setAttribute('class', 'clicktrack__button');
    //     button.innerText = 'Показать номер';
    //     button.addEventListener('click', show.bind(null, clickTrack, button, initial, type));
    //     clickTrack.parentNode.appendChild(button);
    //   }
    //   function show(clickTrack, button, initial, type) {
    //     button.remove();
    //     clickTrack.innerText = initial;
    //     setTimeout(() => clickTrack.removeEventListener('click', stopIt), 0);
    //     if (type == 'email') {
    //       console.log("there supposed to be no email in the app");
    //     } else {
    //       window.ym(38236335,'reachGoal','clicktrack');
    //       console.log("Цель отправлена 38236335: clicktrack");
    //     }
    //   }
    //   clickTracks.forEach(clickTrack => track.bind(null, clickTrack)());
    //   clickTracks.forEach(clickTrack => clickTrack.addEventListener('click', stopIt));
    // })();
  }
}
</script>

<style scoped>
.header {
  position: relative;
  background: white;
  margin-bottom: 40px;
  min-width: 1024px;
}

.header__content {
  max-width: 1440px;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.header__logobox {
  margin-right: auto;
}

.header__logo {
  height: 40px;
}

.header__cover {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.header__back {
  margin: 0 32px 0 auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
}

.header__arrow {
  margin-left: 6px;
}

.header__tel {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
</style>
