<template>
  <section class="subnavigation" v-if="store.step < 4">
    <swiper
      :slides-per-view="2"
      :free-mode="true"
      :slides-offset-before="16"
      :slides-offset-after="16"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(subcategory, index) in subcategories"
        :key="index"
        class="subnavigation__item"
        :class="{
          'subnavigation__item--skiped':
            (!mutations.inCartSubStep(index + 1) && store.step < store.maxStep) ||
            (!mutations.inCartSubStep(index + 1) && index < store.maxSubStep),
          'subnavigation__item--active': store.subStep == index + 1,
          'subnavigation__item--selected':
            mutations.inCartSubStep(index + 1) || (subcategory.id == 6 && mutations.hasChoosenTables()),
          'subnavigation__item--clickable': store.maxStep > store.step || store.maxSubStep > index
        }"
        @click="handler(index + 1, subcategory)"
      >
        <svg class="subnavigation__tail" width="12" height="80" viewBox="0 0 12 80" fill="white">
          <path d="M24 40V0H0L24 40Z" />
          <path d="M24 40V80H0L24 40Z" />
        </svg>
        {{ subcategory.name }}
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

export default {
  inject: ['store', 'mutations'],
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    subcategories () {
      return this.store.subcategories[this.store.step - 1]
    }
  },
  methods: {
    onSwiper (swiper) {
      this.store.subnavigationMobileSwiper = swiper
    },
    handler (subStep) {
      this.goToSubStep(subStep)
      this.store.subnavigationMobileSwiper.slideTo(subStep - 1)
    },
    goToSubStep (subStep) {
      this.mutations.setSubStep(subStep)
      this.mutations.getProducts(this.store.kitchenId)
      this.mutations.setCurrentPage(1)
    }
  },
  mounted () {
    if (this.store.step < 4 && this.store.subStep > 1) {
      this.store.subnavigationMobileSwiper.slideTo(this.store.subStep - 1)
    }
  }
}
</script>

<style scoped>
.subnavigation {
  width: 100%;
  margin-bottom: 24px;
}

.subnavigation__item {
  position: relative;
  /* width: 150px !important; */
  height: 80px;
  background: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.subnavigation__item:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.subnavigation__item:first-of-type svg {
  display: none;
}

.subnavigation__item:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.subnavigation__item:last-of-type:after {
  display: none;
}

.subnavigation__item:after {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 24px solid white;
  top: 0;
  right: -12px;
  z-index: 20;
}

.subnavigation__tail {
  position: absolute;
  top: 0;
  left: -12px;
}

.subnavigation__item--skiped {
  background: #e5e5e5;
}

.subnavigation__item--skiped:after {
  border-left-color: #e5e5e5;
}

.subnavigation__item--skiped .subnavigation__tail {
  fill: #e5e5e5;
}

.subnavigation__item--selected {
  background: #f2f9e5;
  color: #80c300;
}

.subnavigation__item--selected:after {
  border-left-color: #f2f9e5;
}

.subnavigation__item--selected .subnavigation__tail {
  fill: #f2f9e5;
}

.subnavigation__item--active {
  background: #cc3333;
  pointer-events: none !important;
  color: white;
}

.subnavigation__item--active:after {
  border-left-color: #cc3333;
}

.subnavigation__item--active .subnavigation__tail {
  fill: #cc3333;
}

.subnavigation__item--clickable {
  cursor: pointer;
  pointer-events: unset;
}
</style>
