<template>
  <button><slot></slot></button>
</template>

<script>
export default {

}
</script>

<style scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background: white;
}

.button--full {
  width: 100%;
}

.button--centered {
  justify-content: center;
}

.button--bordered {
  border: 1px solid #E5E5E5;
  padding: 11px 16px;
  transition: .3s;
}

.button--red {
  background: #CC3333;
  color: white;
  border: unset;
  transition: .3s;
}

.button--gray {
  background: #404040;
  color: white;
  border: unset;
  pointer-events: none;
}

.button--big {
  padding: 16px;
}

.button--quad {
  padding: 11px;
  position: relative;
}

.button__icon {
  margin-left: 24px;
}

.button__circle {
  position: absolute;
  top: 6px;
  right: 7px;
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  background: #CC3333;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: white;
}
</style>