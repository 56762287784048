<template>
  <transition name="slide" mode="out-in">
    <section class="filter" v-if="store.activeSidebar == 'filter' && store.step < 4">
      <div class="filter__box">
        <div v-if="state == ''">
          <header class="filter__header">
            Фильтры
            <svg @click="close" style="margin-right: -12px;" width="48" height="48" viewBox="0 0 48 48"><path d="M24.9106 24L29.5248 18.4998C29.6022 18.4084 29.5371 18.2695 29.4176 18.2695H28.0149C27.9323 18.2695 27.8532 18.3064 27.7987 18.3697L23.993 22.9066L20.1873 18.3697C20.1346 18.3064 20.0555 18.2695 19.9711 18.2695H18.5684C18.4488 18.2695 18.3838 18.4084 18.4612 18.4998L23.0754 24L18.4612 29.5002C18.4438 29.5206 18.4327 29.5455 18.4291 29.572C18.4255 29.5985 18.4296 29.6255 18.4409 29.6497C18.4522 29.674 18.4702 29.6945 18.4928 29.7088C18.5154 29.7231 18.5416 29.7306 18.5684 29.7305H19.9711C20.0537 29.7305 20.1328 29.6936 20.1873 29.6303L23.993 25.0934L27.7987 29.6303C27.8514 29.6936 27.9305 29.7305 28.0149 29.7305H29.4176C29.5371 29.7305 29.6022 29.5916 29.5248 29.5002L24.9106 24Z" fill="#404040"/></svg>
          </header>

          <div class="filter__block">
            <div class="filter__option" @click="setState('Цена')">
              Цена
              <div class="filter__tag" v-if="store.filtersFields.price.length > 0 && (typeof store.filtersFields.price[0] != 'undefined' && store.filtersFields.price[0] != '') || (typeof store.filtersFields.price[1] != 'undefined' && store.filtersFields.price[1] != '')"></div>
              <svg class="filter__arrow" width="16" height="16" viewBox="0 0 16 16"><path d="M11.9641 7.60625L4.92031 2.10469C4.90191 2.0902 4.87979 2.08119 4.8565 2.07871C4.8332 2.07622 4.80968 2.08035 4.78863 2.09063C4.76758 2.10091 4.74986 2.11692 4.7375 2.13682C4.72514 2.15672 4.71864 2.1797 4.71875 2.20312V3.41094C4.71875 3.4875 4.75469 3.56094 4.81406 3.60781L10.4391 8L4.81406 12.3922C4.75313 12.4391 4.71875 12.5125 4.71875 12.5891V13.7969C4.71875 13.9016 4.83906 13.9594 4.92031 13.8953L11.9641 8.39375C12.0239 8.34705 12.0724 8.28732 12.1057 8.21909C12.139 8.15086 12.1563 8.07593 12.1563 8C12.1563 7.92407 12.139 7.84915 12.1057 7.78092C12.0724 7.71269 12.0239 7.65295 11.9641 7.60625Z" fill="#404040"/></svg>
            </div>

            <div v-for="filterName, filterId in store.filters" :key="filterName">
              <div class="filter__option" v-if="uniqueChars(filterName, filterId).length > 1"  @click="setState(filterName)">
                {{ filterName }} 
                <div class="filter__tag" v-if="store.filtersFields[filterId].length > 0"></div>
                <svg class="filter__arrow" width="16" height="16" viewBox="0 0 16 16"><path d="M11.9641 7.60625L4.92031 2.10469C4.90191 2.0902 4.87979 2.08119 4.8565 2.07871C4.8332 2.07622 4.80968 2.08035 4.78863 2.09063C4.76758 2.10091 4.74986 2.11692 4.7375 2.13682C4.72514 2.15672 4.71864 2.1797 4.71875 2.20312V3.41094C4.71875 3.4875 4.75469 3.56094 4.81406 3.60781L10.4391 8L4.81406 12.3922C4.75313 12.4391 4.71875 12.5125 4.71875 12.5891V13.7969C4.71875 13.9016 4.83906 13.9594 4.92031 13.8953L11.9641 8.39375C12.0239 8.34705 12.0724 8.28732 12.1057 8.21909C12.139 8.15086 12.1563 8.07593 12.1563 8C12.1563 7.92407 12.139 7.84915 12.1057 7.78092C12.0724 7.71269 12.0239 7.65295 11.9641 7.60625Z" fill="#404040"/></svg>
              </div>
            </div>
          </div>

          <div class="filter__buttons">
            <ButtonMobile class="button button--red filter__button" @click="close">Показать</ButtonMobile>
            <ButtonMobile class="button button--bordered filter__button" @click="resetFilter">Сбросить</ButtonMobile>
          </div>
        </div>




        <div v-if="state == 'Цена'">
          <header class="filter__header">
            Цена
            <svg @click="resetState" style="margin-right: -12px;" width="48" height="48" viewBox="0 0 48 48"><path d="M24.9106 24L29.5248 18.4998C29.6022 18.4084 29.5371 18.2695 29.4176 18.2695H28.0149C27.9323 18.2695 27.8532 18.3064 27.7987 18.3697L23.993 22.9066L20.1873 18.3697C20.1346 18.3064 20.0555 18.2695 19.9711 18.2695H18.5684C18.4488 18.2695 18.3838 18.4084 18.4612 18.4998L23.0754 24L18.4612 29.5002C18.4438 29.5206 18.4327 29.5455 18.4291 29.572C18.4255 29.5985 18.4296 29.6255 18.4409 29.6497C18.4522 29.674 18.4702 29.6945 18.4928 29.7088C18.5154 29.7231 18.5416 29.7306 18.5684 29.7305H19.9711C20.0537 29.7305 20.1328 29.6936 20.1873 29.6303L23.993 25.0934L27.7987 29.6303C27.8514 29.6936 27.9305 29.7305 28.0149 29.7305H29.4176C29.5371 29.7305 29.6022 29.5916 29.5248 29.5002L24.9106 24Z" fill="#404040"/></svg>
          </header>

          <div class="filter__block">
            <div class="range">
              <div class="range__box">
                Цена от
                <input class="range__input" type="number" id="1" name="price_min" :placeholder="minPrice.toLocaleString()" v-model="store.filtersFields.price[0]" @change="filterInputHandler">
              </div>
              <div class="range__box range__box--last">
                Цена до
                <input class="range__input" type="number" id="2" name="price_max" :placeholder="maxPrice.toLocaleString()" v-model="store.filtersFields.price[1]" @change="filterInputHandler">
              </div>
            </div>
          </div>

          <div class="filter__buttons">
            <ButtonMobile class="button button--red filter__button" @click="resetState">Показать</ButtonMobile>
            <ButtonMobile class="button button--bordered filter__button" @click="resetFilter">Сбросить</ButtonMobile>
          </div>
        </div>

        <div v-for="filterName, filterId in store.filters" :key="filterName">
          <div v-if="state == filterName">
            <header class="filter__header">
              {{ filterName }}
              <svg @click="resetState" style="margin-right: -12px;" width="48" height="48" viewBox="0 0 48 48"><path d="M24.9106 24L29.5248 18.4998C29.6022 18.4084 29.5371 18.2695 29.4176 18.2695H28.0149C27.9323 18.2695 27.8532 18.3064 27.7987 18.3697L23.993 22.9066L20.1873 18.3697C20.1346 18.3064 20.0555 18.2695 19.9711 18.2695H18.5684C18.4488 18.2695 18.3838 18.4084 18.4612 18.4998L23.0754 24L18.4612 29.5002C18.4438 29.5206 18.4327 29.5455 18.4291 29.572C18.4255 29.5985 18.4296 29.6255 18.4409 29.6497C18.4522 29.674 18.4702 29.6945 18.4928 29.7088C18.5154 29.7231 18.5416 29.7306 18.5684 29.7305H19.9711C20.0537 29.7305 20.1328 29.6936 20.1873 29.6303L23.993 25.0934L27.7987 29.6303C27.8514 29.6936 27.9305 29.7305 28.0149 29.7305H29.4176C29.5371 29.7305 29.6022 29.5916 29.5248 29.5002L24.9106 24Z" fill="#404040"/></svg>
            </header>

            <div class="filter__block">
              <label class="checkbox" v-for="char, ind in uniqueChars(filterName, filterId)" :key="char">
                <input type="checkbox" :id="ind" :value="char" v-model="store.filtersFields[filterId]" @click="filterInputHandler">
                <div>{{ char }} <span v-if="filterName == 'Высота' || filterName == 'Ширина' || filterName == 'Глубина' || filterName == 'Толщина' ">мм</span></div>
                <div class="checkbox__checkbox"></div>
              </label>
            </div>

            <div class="filter__buttons">
              <ButtonMobile class="button button--red filter__button" @click="resetState">Показать</ButtonMobile>
              <ButtonMobile class="button button--bordered filter__button" @click="resetFilter">Сбросить</ButtonMobile>
            </div>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import ButtonMobile from './ButtonMobile.vue'

export default {
  inject: ['store', 'mutations'],
  components: { ButtonMobile },
  data: function () {
    return {
      state: ''
    }
  },
  methods: {
    close() {
      this.mutations.setActiveThumb('');
      this.mutations.setActiveSidebar('');
      this.mutations.toggleCover();
    },
    uniqueChars(filterName, filterId) {
      let array = this.store.productsAllFixed.map(product => product.CHARS[filterId][filterName]).filter(product =>  product != null).sort((a, b) => a - b);
      return [...new Set(array)]
    },
    filterInputHandler() {
      setTimeout(() => {
        this.mutations.filterProducts();
      }, 0);
    },
    filterButtonHandler() {
      setTimeout(() => {
        this.mutations.filterProducts();
      }, 0);
      this.store.activeSidebar = 'order';
    },
    setState(name) {
      this.state = name;
    },
    resetState() {
      this.state = '';
    },
    resetFilter() {
      this.mutations.resetProducts();
      this.close();
      this.resetState();
    }
  },
  computed: {
    minPrice() {
      let array = this.store.productsAllFixed.map(product =>  Number(product.CATALOG_PRICE_4));
      return Math.min( ...array );
    },
    maxPrice() {
      let array = this.store.productsAllFixed.map(product =>  Number(product.CATALOG_PRICE_4));
      return Math.max( ...array );
    }
  }
}
</script>

<style scoped>
.slide-enter-active, 
.slide-leave-active {
  transition: transform .3s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100%);
}


.filter {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.filter__box {
  background: #FFFFFF;
  border-radius: 30px 30px 0px 0px;
  height: 100%;
  margin-top: 64px;
}

.filter__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 3px 0 0 0;
  border-bottom: 1px solid #F1F1F1;
  margin: 0 16px 16px 16px;
}

.filter__close {
  cursor: pointer;
}

.filter__block {
  margin-bottom: 32px;
}

.filter__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
}

.filter__option {
  display: flex;
  align-items: center;
  padding: 18px 0;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 1px solid #F1F1F1;
}

.filter__tag {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #CC3333;
  margin-left: 8px;
}

.filter__arrow {
  margin-left: auto;
}

.filter__block {
  padding: 0 16px;
}

.filter__buttons {
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin: 56px -8px 56px 0;
}

.filter__button {
  width: calc(50% - 8px);
  margin-right: 8px;
  justify-content: center;
  line-height: 24px;
}



.range {
  position: relative;
}

.range__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 21px;
  padding: 4px 0;
}

.range__box--last {
  margin-bottom: 0;
}

.range__input {
  width: 230px;
  height: 48px;
  background: white;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  color: #404040;
  text-align: center;
  transition: .3s;
}


.range__input::placeholder {
  color: rgba(64, 64, 64, 0.5);
  transition: .3s;
}

.range__label {
  position: absolute;
  font-size: 14px;
  line-height: 16px;
  color: rgba(64, 64, 64, 0.5);
  top: 2px;
  bottom: 0;
  left: 40px;
  margin: auto;
  text-transform: lowercase;
  display: flex;
  align-items: center;
}

.range__item:last-of-type {
  margin-right: 0;
}

.range__slider {
  margin: 28px 0 34px 0;
}


.checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
  cursor: pointer;
  border-bottom: 1px solid #F1F1F1;
  padding: 16px 0;
}

.checkbox:last-of-type {
  margin-bottom: 0;
}

.checkbox input {
  display: none;
}

.checkbox input:checked ~ .checkbox__checkbox::after {
  visibility: visible;
  opacity: 1;
  transition: .3s;
}

.checkbox__checkbox {
  width: 24px;
  height: 24px;
  background: white;
  margin-left: auto;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.checkbox__checkbox::after {
  content: '';
  width: 8px;
  height: 8px;
  background: #CC3333;
  border-radius: 2px;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}



.radio {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
  cursor: pointer;
}

.radio:hover .radio__checkbox {
  border-color: #404040;
  transition: .3s;
}

.radio:last-of-type {
  margin-bottom: 0;
}

.radio input {
  display: none;
}

.radio input:checked ~ .radio__checkbox::after {
  visibility: visible;
  opacity: 1;
  transition: .3s;
}

.radio__checkbox {
  width: 32px;
  height: 32px;
  background: white;
  border: 1px solid #E5E5E5;
  border-radius: 100%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.radio__checkbox::after {
  content: '';
  width: 10px;
  height: 10px;
  background: #CC3333;
  border-radius: 100%;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}
</style>