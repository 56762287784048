<template>
  <footer class="footer" :class="{ 'footer--checkout': store.step > 3 || router.currentRoute.value.path == '/' }">
    <div class="footer__content">
      <div>© «{{ partnerName }}», {{ new Date().getFullYear() }}</div>
      <div class="footer__dev">
        Дизайн и разработка
        <a class="footer__icon" href="https://toproi.ru/" target="_blank">
          <svg width="40" height="16" viewBox="0 0 40 16">
            <path
              d="M12.4762 13.9394L7.93939 9.33333L3.4026 13.9394L0 10.4848L7.93939 2.42424L15.8788 10.4848L12.4762 13.9394ZM28 3.42857L23.5394 8L28 12.5714L24.6545 16L16.8485 8L24.6545 0L28 3.42857ZM35.1515 12.9697C32.4738 12.9697 30.303 10.799 30.303 8.12121C30.303 5.44347 32.4738 3.27273 35.1515 3.27273C37.8293 3.27273 40 5.44347 40 8.12121C40 10.799 37.8293 12.9697 35.1515 12.9697Z"
              fill="#CC3333"
            />
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = inject('store')

const partnerName = computed(() => {
  return store.isPartner ? store.partner.NAME : '33 Комода'
})
</script>

<style scoped>
.footer {
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto 80px auto;
  min-width: 1024px;
}

.footer--checkout {
  margin-bottom: 0;
}

.footer__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  padding: 40px 0;
  border-top: 1px solid #e5e5e5;
}

.footer__dev {
  display: flex;
  align-items: center;
}

.footer__icon {
  margin-left: 16px;
}
</style>
