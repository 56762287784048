<template>
  <section class="table">    
    <div v-if="mutations.tables().length > 0">
      <div v-for="(product, index) in mutations.tables()" :key="index">
        <TableForm  :index="index" :product="product" :ref="`table${index}`" />
      </div>
    </div>

    <div v-else class="table__item">
      <div class="table__header">
        <div class="table__note">Выбор формы и размера столешницы не доступен</div>
        <div class="table__subnote">Вернитесь на вкладку "Цвет столешницы" и укажите требуемое число отрезов.</div>

        <Button class="button button--bordered" @click="mutations.setSubStep(1)">
          Вернуться назад
          <svg style="margin-left: 24px;" width="14" height="14" viewBox="0 0 14 14"><g clip-path="url(#backPanel)"><path d="M3.53144 7.34442L9.69473 12.1583C9.71083 12.171 9.73018 12.1789 9.75057 12.181C9.77095 12.1832 9.79153 12.1796 9.80995 12.1706C9.82836 12.1616 9.84387 12.1476 9.85469 12.1302C9.8655 12.1128 9.87119 12.0927 9.87109 12.0722V11.0153C9.87109 10.9483 9.83965 10.8841 9.78769 10.8431L4.86582 6.99989L9.78769 3.15673C9.84101 3.11571 9.87109 3.05146 9.87109 2.98446V1.92763C9.87109 1.83603 9.76582 1.78544 9.69473 1.84149L3.53144 6.65536C3.47906 6.69622 3.43668 6.74849 3.40754 6.80819C3.37839 6.8679 3.36324 6.93346 3.36324 6.99989C3.36324 7.06633 3.37839 7.13189 3.40754 7.19159C3.43668 7.25129 3.47906 7.30356 3.53144 7.34442Z" fill="#404040"/></g><defs><clipPath id="backPanel"><rect width="14" height="14" fill="white"/></clipPath></defs></svg>
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import TableForm from './TableForm.vue'
import Button from './Button.vue'

export default {
  inject: ['store', 'mutations'],
  components: { TableForm, Button },
}
</script>

<style scoped>
.table {
  position: relative;
}

.table__item {
  border-top: 1px solid #E5E5E5;
  padding: 32px 0;
}

.table__item:last-of-type {
  border-bottom: 1px solid #E5E5E5;
}

.table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

.table__note {
  width: 100%;
  margin-bottom: 16px;
}

.table__subnote {
  width: 100%;
  font-weight: 400;
  margin-bottom: 32px;
}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
  cursor: pointer;
}

.radio:hover .radio__checkbox {
  border-color: #404040;
  transition: .3s;
}

.radio:last-of-type {
  margin-bottom: 32px;
}

.radio input {
  display: none;
}

.radio input:checked ~ .radio__checkbox::after {
  visibility: visible;
  opacity: 1;
  transition: .3s;
}

.radio__checkbox {
  width: 32px;
  height: 32px;
  background: white;
  border: 1px solid #E5E5E5;
  border-radius: 100%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.radio__checkbox::after {
  content: '';
  width: 10px;
  height: 10px;
  background: #CC3333;
  border-radius: 100%;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}
</style>