<template>
  <div class="quantity">
    <div class="quantity__button" :class="{'quantity__button--disabled': store.bufferCount == 1 && !store.cart[product.ID]}" @click="decrease">
      <svg width="14" height="14" viewBox="0 0 14 14"><path d="M11.9219 6.48047H2.07812C2.01797 6.48047 1.96875 6.52969 1.96875 6.58984V7.41016C1.96875 7.47031 2.01797 7.51953 2.07812 7.51953H11.9219C11.982 7.51953 12.0312 7.47031 12.0312 7.41016V6.58984C12.0312 6.52969 11.982 6.48047 11.9219 6.48047Z" fill="#404040"/></svg>
    </div>
    <div class="quantity__count" v-if="store.cart[product.ID]">{{ store.cart[product.ID].COUNT }}</div>
    <div class="quantity__count" v-else>{{ store.bufferCount }}</div>
    <div class="quantity__button" :class="{'quantity__button--disabled': mutations.isFacadeColourStep()}" @click="increase">
      <svg width="14" height="14" viewBox="0 0 14 14"><path d="M11.0837 7.58342H7.58366V11.0834H6.41699V7.58342H2.91699V6.41675H6.41699V2.91675H7.58366V6.41675H11.0837V7.58342Z" fill="#404040"/></svg>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  props: ['product'],
  methods: {
    decrease() {
      if(this.store.cart[this.product.ID]) {
        if(this.mutations.isTableColourStep()) this.mutations.removeTable(this.product);
        this.mutations.removeItem(this.product);
      } else {
        this.mutations.setBufferCount('minus');
      } 
    },
    increase() {
      if(this.store.cart[this.product.ID]) {
        this.mutations.addItem(this.product, 1);
      } else {
        this.mutations.setBufferCount('plus');
      } 
    }
  }
}
</script>

<style>
.quantity {
  display: flex;
  align-items: center;
  width: fit-content;
}

.quantity__button {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #ECECEC;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .3s;
}

.quantity__button:hover {
  background: #ECECEC;
  transition: .3s;
}


.quantity__button--disabled {
  opacity: .5;
  pointer-events: none;
}

.quantity__count {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
</style>