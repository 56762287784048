<template>
  <section class="panel" v-if="store.step < 4">
    <div class="panel__content">
      <Button class="button button--bordered panel__button" v-if="store.step > 1 || store.subStep > 1" @click="goBack()">
        Вернуться назад
        <svg style="margin-left: 24px;" width="14" height="14" viewBox="0 0 14 14"><g clip-path="url(#backPanel)"><path d="M3.53144 7.34442L9.69473 12.1583C9.71083 12.171 9.73018 12.1789 9.75057 12.181C9.77095 12.1832 9.79153 12.1796 9.80995 12.1706C9.82836 12.1616 9.84387 12.1476 9.85469 12.1302C9.8655 12.1128 9.87119 12.0927 9.87109 12.0722V11.0153C9.87109 10.9483 9.83965 10.8841 9.78769 10.8431L4.86582 6.99989L9.78769 3.15673C9.84101 3.11571 9.87109 3.05146 9.87109 2.98446V1.92763C9.87109 1.83603 9.76582 1.78544 9.69473 1.84149L3.53144 6.65536C3.47906 6.69622 3.43668 6.74849 3.40754 6.80819C3.37839 6.8679 3.36324 6.93346 3.36324 6.99989C3.36324 7.06633 3.37839 7.13189 3.40754 7.19159C3.43668 7.25129 3.47906 7.30356 3.53144 7.34442Z" fill="#404040"/></g><defs><clipPath id="backPanel"><rect width="14" height="14" fill="white"/></clipPath></defs></svg>
      </Button>
      
      <Button class="button button--red panel__button" @click="goForth()" v-if="Object.keys(store.cart).length != 0 && mutations.inCart() || hasTableFormLength">
        Перейти далее
        <svg style="margin-left: 24px;" width="14" height="14" viewBox="0 0 14 14"><path d="M12.4687 2.59766H11.5131C11.3791 2.59766 11.2519 2.65918 11.1699 2.76445L5.53298 9.90527L2.83006 6.48047C2.78917 6.42855 2.73705 6.38658 2.67761 6.35769C2.61818 6.3288 2.55297 6.31375 2.48689 6.31367H1.53123C1.43963 6.31367 1.38904 6.41895 1.44509 6.49004L5.18982 11.2342C5.36482 11.4557 5.70115 11.4557 5.87752 11.2342L12.5549 2.77266C12.6109 2.70293 12.5603 2.59766 12.4687 2.59766Z" fill="white"/></svg>
      </Button>

      <Button class="button button--bordered panel__button" @click="goForth()" v-else>
        Пропустить
        <svg style="margin-left: 24px;" width="14" height="14" viewBox="0 0 14 14"><path d="M7.70821 7L11.2971 2.72207C11.3572 2.65098 11.3066 2.54297 11.2137 2.54297H10.1227C10.0584 2.54297 9.99688 2.57168 9.95449 2.6209L6.99453 6.14961L4.03457 2.6209C3.99356 2.57168 3.93203 2.54297 3.86641 2.54297H2.77539C2.68242 2.54297 2.63184 2.65098 2.69199 2.72207L6.28086 7L2.69199 11.2779C2.67852 11.2938 2.66987 11.3132 2.66709 11.3338C2.6643 11.3544 2.66748 11.3754 2.67626 11.3942C2.68504 11.4131 2.69905 11.429 2.71662 11.4402C2.7342 11.4513 2.75459 11.4571 2.77539 11.457H3.86641C3.93067 11.457 3.99219 11.4283 4.03457 11.3791L6.99453 7.85039L9.95449 11.3791C9.99551 11.4283 10.057 11.457 10.1227 11.457H11.2137C11.3066 11.457 11.3572 11.349 11.2971 11.2779L7.70821 7Z" fill="#404040"/></svg>
      </Button>

      <div class="panel__price">
        <Button class="button button--bordered panel__button" v-if="store.maxStep > 3" @click="mutations.setStep(4)">
          Подтверждение заказа
        </Button>
        Итого: {{ mutations.totalPrice() }} ₽
      </div>
    </div>
  </section>
</template>

<script>
import Button from './Button.vue'

export default {
  inject: ['store', 'mutations'],
  components: { Button },
  methods: {
    goForth() {
      let lastStep = this.store.step == 3 && this.store.subStep == 5;
      let lastSubStep = (this.store.step == 1 && this.store.subStep == 4) || (this.store.step > 1 && this.store.subStep == 5);

      if(lastStep) {
        this.mutations.setMaxStep(this.store.maxStep + 1);
        this.mutations.toggleModal();
      } else {
        if(lastSubStep) {

          if(this.store.maxStep == this.store.step) this.mutations.setMaxSubStep(1);
          if(this.store.maxStep == this.store.step) this.mutations.setMaxStep(this.store.maxStep + 1);
          this.mutations.setStep(this.store.step + 1);
          this.mutations.setSubStep(1);
          
        } else {
          if(this.store.maxSubStep <= this.store.subStep && this.store.maxStep <= this.store.step) {
            this.mutations.setMaxSubStep(this.store.maxSubStep + 1);
          }
          this.mutations.setSubStep(this.store.subStep + 1);
        }
        
        this.scrollToSubNavigation();
        this.mutations.getProducts(this.store.kitchenId);
      }
      this.closeDetails();
      this.mutations.setCurrentPage(1);
    },
    goBack() {
      if(this.store.subStep == 1 && this.store.step == 2 ) {
        this.mutations.setSubStep(4);
        this.mutations.setStep(this.store.step - 1);
      } else if(this.store.subStep == 1 && this.store.step > 2) {
        this.mutations.setSubStep(5);
        this.mutations.setStep(this.store.step - 1);
      } else {
        this.mutations.setSubStep(this.store.subStep - 1);
      }

      this.scrollToSubNavigation();
      this.mutations.getProducts(this.store.kitchenId);
      this.closeDetails();
      this.mutations.setCurrentPage(1);
    },
    scrollToSubNavigation() {
      window.scrollTo({
        top: 630,
        left: 0,
        // behavior: 'smooth'
      });
    },
    closeDetails() {
      this.mutations.setActiveThumb('');
      this.mutations.setActiveSidebar('order');
    }
  },
  computed: {
    hasTableFormLength() {
      return this.mutations.isTableFormStep() && this.mutations.tables().filter(product => product.length != null).length > 0;
    }
  }
}
</script>

<style scoped>
.panel {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 7;
}

.panel:after {
  content: '';
  width: 100%;
  height: 24px;
  position: absolute;
  left: 0;
  bottom: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
}

.panel__content {
  max-width: 1440px;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.panel__price {
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.panel__button {
  margin-right: 24px;
}
</style>