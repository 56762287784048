<template>
  <div class="app">
    <div class="error">
      <div class="error__content">
        <img v-lazy="require('@/assets/error.webp')" class="error__img" />
        <div class="error__title">
          Упс
        </div>
        <div class="error__subtitle">
          Увы, но эта страница затерялась где-то в галактике Интернета
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error {
  background: #e5e5e5;
  height: 100vh;
}
.error__content {
  padding: 0 16px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.error__title {
  font-weight: 900;
  font-size: 60px;
  line-height: 54px;
  color: #cf4646;
  margin-bottom: 16px;
}
.error__subtitle {
  font-size: 14px;
  line-height: 21px;
}
.error__img {
  width: 100%;
  margin-bottom: 40px;
}
</style>
