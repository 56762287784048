<template>
  <footer class="footer" :class="{'footer--checkout': store.step > 3}">
    <div>© «{{ partnerName }}», {{new Date().getFullYear()}}</div>
    <div class="footer__dev">
      Разработка сайта
      <a class="footer__icon" href="https://toproi.ru/" target="_blank">
        <svg width="30" height="12" viewBox="0 0 40 16"><path d="M12.4762 13.9394L7.93939 9.33333L3.4026 13.9394L0 10.4848L7.93939 2.42424L15.8788 10.4848L12.4762 13.9394ZM28 3.42857L23.5394 8L28 12.5714L24.6545 16L16.8485 8L24.6545 0L28 3.42857ZM35.1515 12.9697C32.4738 12.9697 30.303 10.799 30.303 8.12121C30.303 5.44347 32.4738 3.27273 35.1515 3.27273C37.8293 3.27273 40 5.44347 40 8.12121C40 10.799 37.8293 12.9697 35.1515 12.9697Z" fill="#CC3333"/></svg>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  inject: ['store'],
  computed: {
    partnerName() {
      return this.store.isPartner ? this.store.partner.NAME : '33 Комода';
    },
  },
}
</script>

<style scoped>
.footer {
  position: relative;
  padding: 25px 16px 17px 16px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 14px;
  color: rgba(64, 64, 64, 0.5);
  margin-top: 32px;
}

.footer:after {
  content: '';
  height: 1px;
  width: calc(100% - 32px);
  position: absolute;
  top: 0;
  left: 16px;
  background: #E5E5E5;
}

.footer--checkout {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.footer__dev {
  display: flex;
  align-items: center;
}

.footer__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
</style>