<template>
  <div class="navigation">
    <div class="navigation__item" v-for="(category, index) in store.categories" :key="index"
      :class="{
        'navigation__item--active': store.step == index + 1,
        'navigation__item--clickable': store.maxStep >= index + 1
      }" 
      @click="goToStep(index + 1)"
    >
      <div class="navigation__step" 
        :class="{
          'navigation__step--done': mutations.inCartStep(index + 1) && store.maxStep > index + 1,
          'navigation__step--empty': !mutations.inCartStep(index + 1) && store.maxStep > index + 1
        }"
      >
        <svg v-if="mutations.inCartStep(index + 1) && store.maxStep > index + 1" class="navigation__icon" width="14" height="15" viewBox="0 0 14 15"><g clip-path="url(#doneNav)"><path d="M12.4687 3.09766H11.5131C11.3791 3.09766 11.2519 3.15918 11.1699 3.26445L5.53298 10.4053L2.83006 6.98047C2.78917 6.92855 2.73705 6.88658 2.67761 6.85769C2.61818 6.8288 2.55297 6.81375 2.48689 6.81367H1.53123C1.43963 6.81367 1.38904 6.91895 1.44509 6.99004L5.18982 11.7342C5.36482 11.9557 5.70115 11.9557 5.87752 11.7342L12.5549 3.27266C12.6109 3.20293 12.5603 3.09766 12.4687 3.09766Z" fill="#80C300"/></g><defs><clipPath id="doneNav"><rect width="14" height="14" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>
        <svg v-if="!mutations.inCartStep(index + 1) && store.maxStep > index + 1" class="navigation__icon" width="14" height="15" viewBox="0 0 14 15"><path d="M7.70831 7.5L11.2972 3.22207C11.3573 3.15098 11.3068 3.04297 11.2138 3.04297H10.1228C10.0585 3.04297 9.99698 3.07168 9.9546 3.1209L6.99464 6.64961L4.03468 3.1209C3.99366 3.07168 3.93214 3.04297 3.86652 3.04297H2.7755C2.68253 3.04297 2.63195 3.15098 2.6921 3.22207L6.28097 7.5L2.6921 11.7779C2.67863 11.7938 2.66998 11.8132 2.66719 11.8338C2.6644 11.8544 2.66759 11.8754 2.67637 11.8942C2.68515 11.9131 2.69916 11.929 2.71673 11.9402C2.7343 11.9513 2.7547 11.9571 2.7755 11.957H3.86652C3.93077 11.957 3.9923 11.9283 4.03468 11.8791L6.99464 8.35039L9.9546 11.8791C9.99562 11.9283 10.0571 11.957 10.1228 11.957H11.2138C11.3068 11.957 11.3573 11.849 11.2972 11.7779L7.70831 7.5Z" fill="#CC3333"/></svg>
        Шаг{{ index + 1 }}
      </div>
      <div class="navigation__title">{{ category }}</div>
    </div>
    <div class="navigation__item" :class="{'navigation__item--clickable': store.maxStep > 3}" @click="goToCart">
      <div class="navigation__step navigation__step--hidden">Шаг 4</div>
      <div class="navigation__title">Подтверждение заказа</div>
    </div>
    <div class="navigation__print">33</div>
  </div>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  methods: {
    goToStep(step) {
      if(this.store.maxStep >= step) {
        this.mutations.setStep(step);
        this.mutations.setSubStep(1);
        this.mutations.getProducts(this.store.kitchenId);
        this.store.subnavigationMobileSwiper.slideTo(0);
      }
    },
    goToCart() {
      if(this.store.maxStep > 3 )this.store.step = 4;
    }
  }
}
</script>

<style scoped>
.navigation {
  position: relative;
  padding: 24px;
  background: white;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  width: calc(100% - 32px);
  margin: auto;
}

.navigation__item {
  position: relative;
  z-index: 1;
  width: fit-content;
  padding-bottom: 24px;
  border-left: 1px solid #E5E5E5;
  padding-left: 33px;
}

.navigation__item--active:after {
  content: '';
  position: absolute;
  top: 0;
  left: -2px;
  width: 3px;
  height: 100%;
  background: #CC3333;
}

.navigation__item--clickable {
  cursor: pointer;
}

.navigation__item--active {
  cursor: default;
}

.navigation__step {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  color: rgba(64, 64, 64, 0.5);
  margin-bottom: 8px;
}

.navigation__step--done {
  background: rgba(128, 195, 0, 0.1);
  border-radius: 4px;
  color: #80C300;
  padding: 0 8px;
}

.navigation__step--empty {
  background: rgba(204, 51, 51, 0.1);
  border-radius: 4px;
  color: #CC3333;
  padding: 0 8px;
}

.navigation__step--hidden {
  visibility: hidden;
}

.navigation__icon {
  margin-right: 8px;
}

.navigation__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.navigation__print {
  font-weight: 600;
  font-size: 280px;
  line-height: 328px;
  color: rgba(64, 64, 64, 0.1);
  transform: rotate(-30deg);
  position: absolute;
  right: -70px;
  top: 60px;
}
</style>