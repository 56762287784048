<template>
  <div class="perPage">
    Показывать по:
    <select class="perPage__select" v-model="perPage">
      <option>24</option>
      <option>36</option>
      <option>48</option>
    </select>
    <svg class="perPage__icon" width="14" height="14" viewBox="0 0 14 14"><path d="M3.67093 6.24573L7.00002 9.57481L10.3291 6.24573L9.50427 5.4209L7.00002 7.92515L4.49577 5.4209L3.67093 6.24573Z" fill="#404040"/></svg>
  </div>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  data: function () {
    return {
      perPage: this.store.perPage,
    }
  },
  watch: {
    perPage: function (newValue) {
      this.mutations.setPerPage(newValue);
      this.mutations.setCurrentPage(1);
    }
  }
}
</script>

<style>
.perPage {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.perPage__select {
  position: relative;
  margin-left: 24px;
  width: 68px;
  height: 40px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  padding: 12px 16px;
  background: transparent;
  z-index: 1;
}

.perPage__icon {
  position: absolute;
  right: 14px;
  z-index: 0;
}
</style>