<template>
  <section class="app">
    <HeaderMobile />
    <IntroDescriptionMobile />

    <div class="details">
      <div class="details__tabs">
        <div :class="['details__tab', { 'details__tab--active': detailTab == 1 }]" @click="detailTab = 1">
          Описание
        </div>
        <div :class="['details__tab', { 'details__tab--active': detailTab == 2 }]" @click="detailTab = 2">
          Характеристики
        </div>
      </div>
      <div class="details__data">
        <div v-if="detailTab == 1" class="details__item">
          <div
            :class="['details__text', { 'details__text--active': showDescription }]"
            v-html="store.kitchen.DETAIL_TEXT"
          />
          <div v-if="!showDescription" class="details__cover" />
          <button v-if="!showDescription" class="details__more" @click="hideDescription">
            Подробнее
          </button>
        </div>
        <div v-if="detailTab == 2" class="details__item">
          <div v-for="char in store.kitchen.PROPERTIES" :key="char.ID">
            <div
              v-if="
                char.ID != 620 &&
                  char.ID != 171 &&
                  char.ID != 669 &&
                  char.ID != 546 &&
                  char.ID != 626 &&
                  char.ID != 679 &&
                  char.ID != 718
              "
              class="details__chars"
            >
              <div class="details__key">{{ char.NAME }}:</div>
              <div class="details__val">
                {{ char.VALUE }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="grid__tabs">
        <div :class="['grid__tab', { 'grid__tab--active': gridTab == 1 }]" @click="gridTab = 1">
          Цвет фасада
        </div>
        <div :class="['grid__tab', { 'grid__tab--active': gridTab == 2 }]" @click="gridTab = 2">
          Цвет столешницы
        </div>
        <div :class="['grid__tab', { 'grid__tab--active': gridTab == 3 }]" @click="gridTab = 3">
          Доп. комплектующие
        </div>
      </div>
      <div>
        <div v-if="gridTab == 1" class="grid__item">
          <div v-if="fronts != ''" class="grid__wrapper">
            <ThumbDescMobile v-for="front in fronts" :key="front.ID" :product="front" />
          </div>
          <Loader v-else />
        </div>
        <div v-if="gridTab == 2" class="grid__item">
          <div v-if="tableTops != ''" class="grid__wrapper">
            <ThumbDescMobile v-for="tableTop in tableTops" :key="tableTop.ID" :product="tableTop" />
          </div>
          <Loader v-else />
        </div>
        <div v-if="gridTab == 3" class="grid__item">
          <div v-if="others != ''" class="grid__wrapper">
            <ThumbDescMobile v-for="other in others" :key="other.ID" :product="other" />
          </div>
          <Loader v-else />
        </div>
      </div>
    </div>

    <FooterMobile class="footer--checkout" />
    <CallMobile />
  </section>
</template>

<script setup>
import HeaderMobile from '@/components/mobile/HeaderMobile.vue'
import IntroDescriptionMobile from '@/components/mobile/IntroDescriptionMobile.vue'
import ThumbDescMobile from '@/components/mobile/ThumbDescMobile.vue'
import Loader from '@/components/Loader.vue'
import FooterMobile from '@/components/mobile/FooterMobile.vue'
import CallMobile from '@/components/mobile/CallMobile.vue'

import { ref, inject } from 'vue'

const store = inject('store')
const detailTab = ref(1)
const gridTab = ref(1)
const fronts = ref('')
const tableTops = ref('')
const restCats = ref([])
const others = ref([])
const showDescription = ref(false)

const hideDescription = () => {
  showDescription.value = true
}

const getDataFronts = async () => {
  try {
    const dataFronts = await fetch(`https://33komoda.ru/kitchen-planning/?do=getFronts&id=${store.kitchen.ID}`)
    const responseFronts = await dataFronts.json()

    fronts.value = responseFronts.items
  } catch (err) {
    console.log(err)
  }
}

const getDataTableTops = async () => {
  try {
    const dataTableTops = await fetch(`https://33komoda.ru/kitchen-planning/?do=getTabletops&id=${store.kitchen.ID}`)
    const responseTableTops = await dataTableTops.json()

    tableTops.value = responseTableTops.items
  } catch (err) {
    console.log(err)
  }
}

const getDataRest = async () => {
  try {
    const dataPanels = await fetch(`https://33komoda.ru/kitchen-planning/?do=getWallboards&id=${store.kitchen.ID}`)
    const responsePanels = await dataPanels.json()

    const dataPlanks = await fetch(`https://33komoda.ru/kitchen-planning/?do=getPlanks&id=${store.kitchen.ID}`)
    const responsePlanks = await dataPlanks.json()

    const dataPlinths = await fetch(`https://33komoda.ru/kitchen-planning/?do=getPlinths&id=${store.kitchen.ID}`)
    const responsePlinths = await dataPlinths.json()

    const dataDryings = await fetch(`https://33komoda.ru/kitchen-planning/?do=getDryings&id=${store.kitchen.ID}`)
    const responseDryings = await dataDryings.json()

    const dataClosers = await fetch(`https://33komoda.ru/kitchen-planning/?do=getClosers&id=${store.kitchen.ID}`)
    const responseClosers = await dataClosers.json()

    const dataThermoplanks = await fetch(
      `https://33komoda.ru/kitchen-planning/?do=getThermoplanks&id=${store.kitchen.ID}`
    )
    const responseThermoplanks = await dataThermoplanks.json()

    const dataSocles = await fetch(`https://33komoda.ru/kitchen-planning/?do=getSocle&id=${store.kitchen.ID}`)
    const responseSocles = await dataSocles.json()

    restCats.value = [
      responsePanels,
      responsePlanks,
      responsePlinths,
      responseDryings,
      responseClosers,
      responseThermoplanks,
      responseSocles
    ]

    restCats.value.forEach(cat => {
      if (cat.items != null) {
        others.value = others.value.concat(cat.items)
      }
    })
  } catch (err) {
    console.log(err)
  }
}

getDataFronts()
getDataTableTops()
getDataRest()
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
.details {
  width: calc(100% - 32px);
  background: white;
  border-radius: 8px;
  padding: 0 16px 16px;
  margin: 0 auto 16px auto;
}
.details__tabs {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 16px;
}
.details__tab {
  font-weight: 700;
  font-size: 14px;
  padding: 20px 16px;
  border-bottom: 3px solid transparent;
}
.details__tab--active {
  position: relative;
  pointer-events: none;
  border-bottom: 3px solid #cc3333;
}
.details__data {
  flex: 1;
}
.details__chars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.details__key {
  margin-right: 16px;
  opacity: 0.6;
}
.details__val {
  text-align: right;
  max-width: 180px;
}
.details__item {
  position: relative;
  font-size: 14px;
  line-height: 18px;
}
.details__item >>> p:first-of-type {
  margin-top: 0;
}
.details__item >>> p:last-of-type {
  margin-bottom: 0;
}
.details__text {
  max-height: 300px;
  overflow: hidden;
}
.details__text--active {
  max-height: 100%;
}
.details__more {
  font-size: 14px;
  line-height: 18px;
  color: #cc3333;
  cursor: pointer;
  margin-top: 24px;
}
.details__cover {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  position: absolute;
  width: 100%;
  height: 120px;
  z-index: 10;
  bottom: 40px;
  left: 0;
}

.grid {
  width: calc(100% - 32px);
  background: white;
  padding: 0 16px;
  border-radius: 8px;
  margin: 0 auto 48px auto;
}
.grid__tabs {
  display: flex;
  align-items: center;
  overflow: scroll;
  margin-bottom: 18px;
  border-bottom: 1px solid #e5e5e5;
  scrollbar-width: none;
}
.grid__tab {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  height: 58px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 3px solid transparent;
}
.grid__tab--active {
  border-bottom: 3px solid #cc3333;
  pointer-events: none;
}
.grid__item {
  position: relative;
  min-height: 400px;
}
.grid__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-bottom: -24px;
}
</style>
