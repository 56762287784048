import { reactive } from 'vue'

export const store = reactive({
  isPartner: window.location.hostname != '33komoda.ru',
  partner: '',
  partnerId: document.URL.match(/partnerId=([0-9]+)/) == null ? '' : document.URL.match(/partnerId=([0-9]+)/)[1],
  kitchenId: '',
  kitchen: '',
  productsAll: '',
  productsAllFixed: '',
  filters: '',
  filtersFields: '',
  step: 1,
  subStep: 1,
  maxStep: 1,
  maxSubStep: 1,
  perPage: 24,
  currentPage: 1,
  activeSidebar: '',
  activeThumb: '',
  bufferCount: 1,
  subnavigationMobileSwiper: '',
  showModal: false,
  currentFacadeColor: '',
  imgModal: '',
  cart: {},
  showBodyCover: false,
  modalFinish: false,
  showModalCover: false,
  categories: ['Шкафы и цвет фасадов', 'Столешница и стеновая панель', 'Дополнительные комплектующие'],
  subcategories: [
    [
      { id: 1, name: 'Верхние шкафы', action: 'getTopModules', step: 1, subStep: 1 },
      { id: 2, name: 'Нижние шкафы', action: 'getBottomModules', step: 1, subStep: 2 },
      { id: 3, name: 'Цвет фасада', action: 'getFronts', step: 1, subStep: 3 },
      { id: 4, name: 'Барные столы', action: 'getBars', step: 1, subStep: 4 }
    ],
    [
      { id: 5, name: 'Цвет столешницы', action: 'getTabletops', step: 2, subStep: 1 },
      {
        id: 6,
        name: 'Размер столешницы',
        nameFull: 'Форма и размер столешницы',
        action: 'getModules',
        step: 2,
        subStep: 2
      },
      { id: 7, name: 'Стеновая панель', action: 'getWallboards', step: 2, subStep: 3 },
      { id: 8, name: 'Планки', action: 'getPlanks', step: 2, subStep: 4 },
      { id: 9, name: 'Плинтус', action: 'getPlinths', step: 2, subStep: 5 }
    ],
    [
      { id: 10, name: 'Мойки', nameFull: 'Мойки и смесители', action: 'getSinks', step: 3, subStep: 1 },
      { id: 11, name: 'Сушки', nameFull: 'Сушки и тарелки', action: 'getDryings', step: 3, subStep: 2 },
      { id: 12, name: 'Доводчики', action: 'getClosers', step: 3, subStep: 3 },
      { id: 13, name: 'Термопланки', action: 'getThermoplanks', step: 3, subStep: 4 },
      { id: 14, name: 'Цоколь', action: 'getSocle', step: 3, subStep: 5 }
    ]
  ]
})

export const mutations = {
  async getProducts (kitchenId) {
    store.showBodyCover = true
    try {
      let action = store.subcategories[store.step - 1][store.subStep - 1].action
      let ownerString = `https://33komoda.ru/kitchen-planning/?do=${action}&id=${kitchenId}`
      let partnerString = `https://33komoda.ru/kitchen-planning/?do=${action}&id=${kitchenId}&partner_id=${store.partnerId}`
      let requestString = store.isPartner ? partnerString : ownerString

      const response = await fetch(requestString)
      const json = await response.json()

      store.productsAll = json.items
      store.productsAllFixed = json.items
      store.filters = json.filter
      store.filtersFields = Object.assign({}, store.filters)

      for (const filter in store.filtersFields) {
        store.filtersFields[filter] = []
      }
      store.filtersFields.price = []
      store.showBodyCover = false
    } catch (err) {
      store.productsAll = 'error'
      store.productsAllFixed = 'error'
      console.log(err)
    }
  },
  setPerPage (perPageNumber) {
    store.perPage = perPageNumber
  },
  setCurrentPage (pageNumber) {
    store.currentPage = pageNumber
  },
  setStep (stepNumber) {
    store.step = stepNumber
  },
  getStep () {
    return store.step
  },
  setSubStep (subStepNumber) {
    store.subStep = subStepNumber
  },
  getSubStep () {
    return store.subStep
  },
  setMaxStep (maxStepNumber) {
    store.maxStep = maxStepNumber
  },
  setMaxSubStep (maxSubStepNumber) {
    store.maxSubStep = maxSubStepNumber
  },
  setActiveSidebar (activeSidebar) {
    store.activeSidebar = activeSidebar
  },
  setActiveThumb (product) {
    store.activeThumb = product
  },
  toggleCover () {
    store.showModalCover == true ? (store.showModalCover = false) : (store.showModalCover = true)
  },
  addItem (product, count) {
    if (Object.prototype.hasOwnProperty.call(store.cart, product.ID)) {
      store.cart[product.ID].COUNT++
    } else {
      store.cart[product.ID] = product
      store.cart[product.ID].COUNT = count || 1
    }

    if (!Object.prototype.hasOwnProperty.call(store.cart[product.ID], 'step')) {
      store.cart[product.ID].step = store.step
      store.cart[product.ID].subStep = store.subStep
    }

    if (!Object.prototype.hasOwnProperty.call(store.cart[product.ID], 'caregoryId')) {
      store.cart[product.ID].categoryId = mutations.currentCategory().id
    }

    mutations.addTable(product)
    store.cart[product.ID].categoryName = store.subcategories[store.step - 1][store.subStep - 1].name
    store.bufferCount = 1
  },
  removeItem (product) {
    if (store.cart[product.ID].COUNT > 1) {
      store.cart[product.ID].COUNT--
    } else {
      delete store.cart[product.ID]
    }
  },
  removeTable (product) {
    if (store.cart[product.ID]) {
      store.cart[product.ID].subproducts.pop()
    } else {
      product.subproducts.pop()
    }
  },
  removeAll (product) {
    delete store.cart[product.ID]
    mutations.removeTableAll(product)
    // store.bufferCount = 1;
  },
  removeTableAll (product) {
    if (Object.prototype.hasOwnProperty.call(product, 'subproducts')) {
      product.subproducts = []
    }
  },
  addTable (product) {
    if (mutations.isTableColourStep()) {
      if (Object.prototype.hasOwnProperty.call(store.cart[product.ID], 'subproducts')) {
        if (store.bufferCount == 1) {
          store.cart[product.ID].subproducts.push({
            form: null,
            length: null,
            price: null,
            id: product.ID,
            index: store.cart[product.ID].subproducts.length
          })
        } else {
          for (let index = 0; index < store.bufferCount; index++) {
            store.cart[product.ID].subproducts.push({
              form: null,
              length: null,
              price: null,
              id: product.ID,
              index: store.cart[product.ID].subproducts.length
            })
          }
        }
      } else {
        if (store.bufferCount == 1) {
          store.cart[product.ID].subproducts = [{ form: null, length: null, price: null, id: product.ID, index: 0 }]
        } else {
          store.cart[product.ID].subproducts = [{ form: null, length: null, price: null, id: product.ID, index: 0 }]

          for (let index = 0; index < store.bufferCount - 1; index++) {
            store.cart[product.ID].subproducts.push({
              form: null,
              length: null,
              price: null,
              id: product.ID,
              index: store.cart[product.ID].subproducts.length
            })
          }
        }
      }
    }
  },
  setBufferCount (type) {
    type == 'plus' ? store.bufferCount++ : store.bufferCount--
  },
  toggleModal (state) {
    if (state) {
      store.modalFinish = true
    } else {
      store.modalFinish = false
    }
    store.showModal ? (store.showModal = false) : (store.showModal = true)
  },
  filterProducts () {
    store.showBodyCover = true
    let filter = store.filtersFields
    let array = store.productsAllFixed

    for (const key in filter) {
      if (filter[key].length > 0) {
        if (key == 'price') {
          array = array.filter(product => {
            if (
              typeof filter.price[0] != 'undefined' &&
              typeof filter.price[1] != 'undefined' &&
              filter.price[0] != '' &&
              filter.price[1] != ''
            ) {
              return (
                Number(product.CATALOG_PRICE_4) > filter[key][0] && Number(product.CATALOG_PRICE_4) < filter[key][1]
              )
            } else if (typeof filter.price[0] != 'undefined' && filter.price[0] != '') {
              return Number(product.CATALOG_PRICE_4) > filter[key][0]
            } else if (typeof filter.price[1] != 'undefined' && filter.price[1] != '') {
              return Number(product.CATALOG_PRICE_4) < filter[key][1]
            } else {
              return product
            }
          })
        } else {
          array = array.filter(product => filter[key].includes(Object.values(product.CHARS[key])[0]))
        }
      }
    }

    if (array.length > 0) {
      mutations.setCurrentPage(1)
      store.productsAll = array
    } else {
      store.productsAll = null
    }
    setTimeout(() => {
      store.showBodyCover = false
    }, 0)
  },
  resetProducts () {
    for (const filter in store.filtersFields) {
      store.filtersFields[filter] = []
    }
    mutations.setCurrentPage(1)
    store.productsAll = store.productsAllFixed
  },
  updateStore () {
    if (localStorage.getItem('stepKit')) mutations.setStep(+localStorage.getItem('stepKit'))
    if (localStorage.getItem('subStepKit')) mutations.setSubStep(+localStorage.getItem('subStepKit'))
    if (localStorage.getItem('maxStepKit')) mutations.setMaxStep(+localStorage.getItem('maxStepKit'))
    if (localStorage.getItem('maxSubStepKit')) mutations.setMaxSubStep(+localStorage.getItem('maxSubStepKit'))

    if (localStorage.getItem('cartKit')) {
      let cart = JSON.parse(localStorage.getItem('cartKit'))
      store.cart = cart
    }
    // mutations.getProducts(store.kitchenId);
  },
  cleanStore () {
    mutations.setStep(1)
    mutations.setSubStep(1)
    mutations.setMaxStep(1)
    mutations.setMaxSubStep(1)

    localStorage.setItem('kitechenIdKit', store.kitchenId)
    localStorage.setItem('stepKit', 1)
    localStorage.setItem('subStepKit', 1)
    localStorage.setItem('maxStepKit', 1)
    localStorage.setItem('maxSubStepKit', 1)
    localStorage.setItem('cartKit', '')
  },
  // Computed
  currentCategory () {
    return store.subcategories[store.step - 1][store.subStep - 1]
  },
  productsCurrent () {
    let from = (store.currentPage - 1) * store.perPage
    let to = store.currentPage * store.perPage

    return store.productsAll.slice(from, to)
  },
  inCart () {
    let array = Object.values(store.cart)
    return array.filter(product => product.step == store.step && product.subStep == store.subStep).length > 0
  },
  inCartStep (step) {
    let array = Object.values(store.cart)
    return array.filter(product => product.step == step).length > 0
  },
  inCartSubStep (subStep) {
    let array = Object.values(store.cart)
    return array.filter(product => product.step == store.step && product.subStep == subStep).length > 0
  },
  isFacadeColourStep () {
    return store.step == 1 && store.subStep == 3
  },
  isTableColourStep () {
    return store.step == 2 && store.subStep == 1
  },
  isTableFormStep () {
    return store.step == 2 && store.subStep == 2
  },
  tables () {
    let cartArray = Object.values(store.cart)
    let array = cartArray.filter(product => Object.prototype.hasOwnProperty.call(product, 'subproducts'))
    array = array.map(product => product.subproducts)
    array = [].concat.apply([], array)
    return array
  },
  facades () {
    let cartArray = Object.values(store.cart)
    let array = cartArray.filter(product => product.categoryId == 3)
    return array
  },
  hasChoosenTables () {
    let tables = mutations.tables()
    return tables.filter(table => table.length != null).length > 0
  },
  cartArray () {
    return Object.values(store.cart)
  },
  totalPrice () {
    let cartArray = Object.values(store.cart)
    let array = cartArray.map(el => {
      if (el.subproducts) {
        return el.subproducts.map(el => el.price).reduce((a, b) => a + b, 0)
      } else {
        return el.COUNT * el.CATALOG_PRICE_4
      }
    })

    return array.reduce((a, b) => a + b, 0).toLocaleString()
  },
  isFiltered () {
    let obj = Object.assign({}, store.filtersFields)

    delete obj.price
    return (
      Object.values(obj).flat().length > 0 ||
      (typeof store.filtersFields.price[0] != 'undefined' && store.filtersFields.price[0] != '') ||
      (typeof store.filtersFields.price[1] != 'undefined' && store.filtersFields.price[1] != '')
    )
  },
  isFilteredBy720 () {
    if (store.filtersFields.VYSOTA_VERKHNEGO_SHKAFA) {
      return store.filtersFields.VYSOTA_VERKHNEGO_SHKAFA.includes('720')
    } else {
      return false
    }
  },
  isFilteredBy913 () {
    if (store.filtersFields.VYSOTA_VERKHNEGO_SHKAFA) {
      return store.filtersFields.VYSOTA_VERKHNEGO_SHKAFA.includes('913')
    } else {
      return false
    }
  },
  isFilteredBySink () {
    if (store.filtersFields.TIP_MODULYA) {
      return store.filtersFields.TIP_MODULYA.includes('Мойка')
    } else {
      return false
    }
  },
  isFilteredByMixer () {
    if (store.filtersFields.TIP_MODULYA) {
      return store.filtersFields.TIP_MODULYA.includes('Смеситель')
    } else {
      return false
    }
  },
  isFilteredBy26 () {
    if (store.filtersFields.TOLSHCHINA_MM) {
      return store.filtersFields.TOLSHCHINA_MM.includes('26')
    } else {
      return false
    }
  },
  isFilteredBy38 () {
    if (store.filtersFields.TOLSHCHINA_MM) {
      return store.filtersFields.TOLSHCHINA_MM.includes('38')
    } else {
      return false
    }
  }
}
