<template>
  <section class="details" v-if="store.activeSidebar == 'details' && store.step < 4">
    <header class="details__header">
      Обзор товара
      <svg class="details__close" @click="close" width="22" height="23" viewBox="0 0 22 23"><path d="M12.1131 11.5L17.7527 4.77754C17.8472 4.66582 17.7678 4.49609 17.6217 4.49609H15.9072C15.8062 4.49609 15.7095 4.54121 15.6429 4.61855L10.9916 10.1637L6.34021 4.61855C6.27576 4.54121 6.17908 4.49609 6.07595 4.49609H4.3615C4.21541 4.49609 4.13591 4.66582 4.23045 4.77754L9.87009 11.5L4.23045 18.2225C4.20927 18.2474 4.19568 18.2778 4.1913 18.3102C4.18692 18.3426 4.19193 18.3756 4.20572 18.4052C4.21952 18.4348 4.24154 18.4599 4.26915 18.4774C4.29676 18.4949 4.32881 18.5041 4.3615 18.5039H6.07595C6.17693 18.5039 6.27361 18.4588 6.34021 18.3814L10.9916 12.8363L15.6429 18.3814C15.7074 18.4588 15.8041 18.5039 15.9072 18.5039H17.6217C17.7678 18.5039 17.8472 18.3342 17.7527 18.2225L12.1131 11.5Z" fill="#404040"/></svg>
    </header>
    <div class="details__imgbox">
      <img class="details__img" :class="imgClass" v-if="store.activeThumb.PREVIEW_PICTURE" :src="`https://33komoda.ru${store.activeThumb.PREVIEW_PICTURE}`">
      <svg v-else height="120" viewBox="0 0 24 24" width="120" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
    </div>

    <div class="details__title">{{ store.activeThumb.NAME_CUSTOM }}</div>
    <div class="details__sku">#{{ store.activeThumb.XML_ID }}</div>
    <div class="details__pricebox">
      <div class="details__price">{{ Number(store.activeThumb.CATALOG_PRICE_4).toLocaleString() }} ₽</div>
      <!-- <div class="details__priceold">5 200 ₽</div> -->
    </div>

    <Quantity class="details__quantity" :product="store.activeThumb"/>

    <Button v-if="!inCart" @click="addHandler" class="button button--red button--full details__button">
      Добавить в заказ
      <svg class="button__icon" width="14" height="14" viewBox="0 0 14 14"><path d="M12.618 9.59629H4.47637L4.88516 8.76367L11.6773 8.75137C11.907 8.75137 12.1039 8.5873 12.1449 8.36035L13.0855 3.09531C13.1102 2.95723 13.0732 2.81504 12.983 2.70703C12.9384 2.65388 12.8828 2.61106 12.82 2.58155C12.7572 2.55204 12.6887 2.53654 12.6193 2.53613L3.97871 2.50742L3.90488 2.16016C3.8584 1.93867 3.65879 1.77734 3.43184 1.77734H1.31953C1.19153 1.77734 1.06878 1.82819 0.978269 1.9187C0.887761 2.00921 0.836914 2.13196 0.836914 2.25996C0.836914 2.38796 0.887761 2.51071 0.978269 2.60122C1.06878 2.69173 1.19153 2.74258 1.31953 2.74258H3.04082L3.36348 4.27656L4.15781 8.12246L3.13516 9.7918C3.08205 9.86348 3.05006 9.94859 3.04281 10.0375C3.03556 10.1264 3.05334 10.2156 3.09414 10.2949C3.17617 10.4576 3.3416 10.5602 3.5248 10.5602H4.3834C4.20036 10.8033 4.10149 11.0994 4.10176 11.4037C4.10176 12.1775 4.73066 12.8064 5.50449 12.8064C6.27832 12.8064 6.90723 12.1775 6.90723 11.4037C6.90723 11.0988 6.80605 10.8021 6.62559 10.5602H8.82812C8.64508 10.8033 8.54622 11.0994 8.54648 11.4037C8.54648 12.1775 9.17539 12.8064 9.94922 12.8064C10.723 12.8064 11.352 12.1775 11.352 11.4037C11.352 11.0988 11.2508 10.8021 11.0703 10.5602H12.6193C12.8846 10.5602 13.102 10.3441 13.102 10.0775C13.1012 9.94967 13.0498 9.82732 12.9592 9.73716C12.8685 9.647 12.7458 9.59636 12.618 9.59629ZM4.17969 3.45898L12.052 3.48496L11.2809 7.80254L5.09707 7.81348L4.17969 3.45898ZM5.50449 11.8357C5.2666 11.8357 5.07246 11.6416 5.07246 11.4037C5.07246 11.1658 5.2666 10.9717 5.50449 10.9717C5.74238 10.9717 5.93652 11.1658 5.93652 11.4037C5.93652 11.5183 5.89101 11.6282 5.80998 11.7092C5.72896 11.7902 5.61907 11.8357 5.50449 11.8357ZM9.94922 11.8357C9.71133 11.8357 9.51719 11.6416 9.51719 11.4037C9.51719 11.1658 9.71133 10.9717 9.94922 10.9717C10.1871 10.9717 10.3812 11.1658 10.3812 11.4037C10.3812 11.5183 10.3357 11.6282 10.2547 11.7092C10.1737 11.7902 10.0638 11.8357 9.94922 11.8357Z" fill="white"/></svg>
    </Button>

    <Button v-if="inCart" class="button button--gray button--full details__button">
      Добавленно
      <svg class="button__icon" width="14" height="14" viewBox="0 0 14 14"><path d="M12.469 2.59766H11.5133C11.3793 2.59766 11.2522 2.65918 11.1701 2.76445L5.53323 9.90527L2.8303 6.48047C2.78941 6.42855 2.73729 6.38658 2.67786 6.35769C2.61842 6.3288 2.55322 6.31375 2.48714 6.31367H1.53147C1.43987 6.31367 1.38928 6.41895 1.44534 6.49004L5.19006 11.2342C5.36506 11.4557 5.70139 11.4557 5.87776 11.2342L12.5551 2.77266C12.6112 2.70293 12.5606 2.59766 12.469 2.59766Z" fill="white"/></svg>
    </Button>

    <Button v-if="inCart" @click="removeHandler" class="button button--bordered button--full details__button">
      Удалить из заказа
      <svg class="button__icon" width="14" height="14" viewBox="0 0 14 14"><path d="M4.92188 2.51562H4.8125C4.87266 2.51562 4.92188 2.46641 4.92188 2.40625V2.51562H9.07812V2.40625C9.07812 2.46641 9.12734 2.51562 9.1875 2.51562H9.07812V3.5H10.0625V2.40625C10.0625 1.92363 9.67012 1.53125 9.1875 1.53125H4.8125C4.32988 1.53125 3.9375 1.92363 3.9375 2.40625V3.5H4.92188V2.51562ZM11.8125 3.5H2.1875C1.94551 3.5 1.75 3.69551 1.75 3.9375V4.375C1.75 4.43516 1.79922 4.48438 1.85938 4.48438H2.68516L3.02285 11.6348C3.04473 12.101 3.43027 12.4688 3.89648 12.4688H10.1035C10.5711 12.4688 10.9553 12.1023 10.9771 11.6348L11.3148 4.48438H12.1406C12.2008 4.48438 12.25 4.43516 12.25 4.375V3.9375C12.25 3.69551 12.0545 3.5 11.8125 3.5ZM9.99824 11.4844H4.00176L3.6709 4.48438H10.3291L9.99824 11.4844Z" fill="#404040"/></svg>
    </Button>

    <div v-if="charsNotEmpty">
      <div class="details__title details__title--margin">Характеристики</div>
      <div v-for="(value, key) in store.activeThumb.CHARS" :key="key">
        <div class="details__char details__char--first" v-if="key != 'VYSOTA_MM'">
          {{ Object.keys(value)[0] }}:
          <div class="details__value">{{ Object.values(value)[0] }} <span v-if="Object.keys(value)[0] == 'Высота' || Object.keys(value)[0] == 'Ширина' || Object.keys(value)[0] == 'Глубина' || Object.keys(value)[0] == 'Толщина' ">мм</span></div>
        </div>
      </div>
    </div>

    <div v-if="store.activeThumb.DETAIL_TEXT">
      <div class="details__title details__title--margin">Описание</div>
      <div class="details__description" v-html="store.activeThumb.DETAIL_TEXT"></div>
    </div>
  </section>
</template>

<script>
import Quantity from '../Quantity.vue'
import Button from './Button.vue'

export default {
  inject: ['store', 'mutations'],
  components: { Quantity, Button },
  methods: {
    addHandler() {
      if(this.mutations.currentCategory().id == 3) {
        this.addFacadeColor();
      } else if(this.mutations.currentCategory().id == 5) {
        this.addTable();
      } else if(this.mutations.currentCategory().id == 7) {
        this.addWallPanel();
      } else {
        this.mutations.addItem(this.store.activeThumb, this.store.bufferCount);
        this.close();
      }
    },
    addFacadeColor() {
      this.store.imgModal = 'unset';
      this.store.currentFacadeColor = this.store.activeThumb;
      this.mutations.toggleModal();
    },
    addTable() {
      this.tablesInCart.forEach(product => this.mutations.removeAll(product));
      this.mutations.addItem(this.store.activeThumb, this.store.bufferCount);
    },
    addWallPanel() {
      this.wallPanelsInCart.forEach(product => this.mutations.removeAll(product));
      this.mutations.addItem(this.store.activeThumb, this.store.bufferCount);
    },
    removeHandler() {
      this.mutations.removeAll(this.store.activeThumb);
      this.close();
    },
    close() {
      this.mutations.setActiveThumb('');
      this.mutations.setActiveSidebar('order');
    }
  },
  computed: {
    tablesInCart() {
      let cartArray = Object.values(this.store.cart);
      return cartArray.filter(product => product.categoryId == 5);
    },
    wallPanelsInCart() {
      let cartArray = Object.values(this.store.cart);
      return cartArray.filter(product => product.categoryId == 7);
    },
    inCart() {
      return Object.keys(this.store.cart).includes(this.store.activeThumb.ID);
    },
    charsNotEmpty() {
      return Object.prototype.hasOwnProperty.call(this.store.activeThumb, "CHARS") && Object.values(this.store.activeThumb.CHARS).filter(el => el != null).length > 0;
    },
    imgClass() {
      let imgClass;

      if(this.mutations.currentCategory().id == 1 || this.mutations.currentCategory().id == 2) {
        imgClass = 'thumb__img--special';
      } else if( this.mutations.currentCategory().id == 4 || 
        this.mutations.currentCategory().id == 8 || 
        this.mutations.currentCategory().id == 9 || 
        this.mutations.currentCategory().id == 10 || 
        this.mutations.currentCategory().id == 11 || 
        this.mutations.currentCategory().id == 12 || 
        this.mutations.currentCategory().id == 13 || 
        this.mutations.currentCategory().id == 14) {
        imgClass = 'thumb__img--contain';
      } else {
        imgClass = 'thumb__img--cover';
      }
      return imgClass;
    }
  }
}
</script>

<style scoped>
.details {
  position: relative;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 32px 40px;
  z-index: 1;
}

.details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 24px;
}

.details__close {
  cursor: pointer;
}

.details__imgbox {
  position: relative;
  width: 100%;
  height: 300px;
  background: #F5F5F5;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.thumb__img--special {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: auto;
  height: 100%;
  max-width: initial !important;
}

.thumb__img--cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumb__img--contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.details__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  max-height: 54px;
  overflow: hidden;
}

.details__title--margin {
  margin-top: 32px;
}

.details__sku {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #CC3333;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.details__pricebox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.details__price {
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
}

.details__priceold {
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: #CC3333;
  margin-left: 16px;
}

.details__quantity {
  margin-bottom: 16px;
}

.details__char {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.details__char--first {
  margin-top: 16px;
}

.details__value {
  font-weight: 600;
  margin-left: 5px;
}

.details__description {
  font-size: 14px;
  line-height: 21px;
  margin-top: 16px;
}

.details__button {
  margin-bottom: 16px;
}
</style>