<template>
  <div class="form__item" :class="{'form__item--last': lastItem}">
    <div class="form__header" @click="toggleForm">
      Отрез №{{ index + 1 }}: {{ store.cart[product.id].NAME }}
      <svg class="form__icon" :class="{'form__icon--hidden': hidden}" width="16" height="16" viewBox="0 0 24 24"><path d="M6.0539 12.5904L16.6195 20.8428C16.6471 20.8645 16.6803 20.878 16.7153 20.8818C16.7502 20.8855 16.7855 20.8793 16.8171 20.8639C16.8486 20.8484 16.8752 20.8244 16.8938 20.7946C16.9123 20.7647 16.922 20.7303 16.9219 20.6951V18.8834C16.9219 18.7686 16.868 18.6584 16.7789 18.5881L8.3414 11.9998L16.7789 5.41153C16.8703 5.34122 16.9219 5.23106 16.9219 5.11622V3.3045C16.9219 3.14747 16.7414 3.06075 16.6195 3.15685L6.0539 11.4092C5.9641 11.4792 5.89146 11.5688 5.84149 11.6712C5.79152 11.7735 5.76555 11.8859 5.76555 11.9998C5.76555 12.1137 5.79152 12.2261 5.84149 12.3284C5.89146 12.4308 5.9641 12.5204 6.0539 12.5904Z" fill="#404040"/></svg>
    </div>
    <div class="form__content" :class="{'form__content--hidden': hidden}">
      <div class="form__imgbox">
        <transition name="fader" mode="out-in">
          <img class="form__img" :src="require(`@/assets/tableform${form}.png`)" :key="`${form}`">
        </transition>
      </div>
      
      <div class="form__name">Выберите форму</div>
      <div class="form__selectbox">
        <select class="select" v-model="form">
          <option value="1">Прямая</option>
          <option value="2">Радиус левый</option>
          <option value="3">Радиус правый</option>
          <option value="4">Скос левый</option>
          <option value="5">Скос правый</option>
        </select>
        <svg class="form__selecticon" width="16" height="16" viewBox="0 0 16 16"><path d="M13.1313 4.6875H2.86876C2.56095 4.6875 2.38907 5.0125 2.5797 5.23438L7.71095 11.1844C7.85782 11.3547 8.14063 11.3547 8.28907 11.1844L13.4203 5.23438C13.6109 5.0125 13.4391 4.6875 13.1313 4.6875Z" fill="#E5E5E5"/></svg>
      </div>

      <div class="form__name">Укажите длину</div>
      <input class="form__input" placeholder="150 мм - 3 000 мм" v-model.number="length">
      <div class="form__alert" v-if="invalidLength">Укажите длину от 150 мм до 3 000 мм</div>

      <ButtonMobile type="submit" class="button button--red button--full modal__button" @click="validate">
        Подтвердить
        <svg width="14" height="14" viewBox="0 0 14 14"><path d="M12.469 2.59766H11.5133C11.3793 2.59766 11.2522 2.65918 11.1701 2.76445L5.53323 9.90527L2.8303 6.48047C2.78941 6.42855 2.73729 6.38658 2.67786 6.35769C2.61842 6.3288 2.55322 6.31375 2.48714 6.31367H1.53147C1.43987 6.31367 1.38928 6.41895 1.44534 6.49004L5.19006 11.2342C5.36506 11.4557 5.70139 11.4557 5.87776 11.2342L12.5551 2.77266C12.6112 2.70293 12.5606 2.59766 12.469 2.59766Z" fill="white"/></svg>
      </ButtonMobile>
    </div>
  </div>
</template>

<script>
import ButtonMobile from './ButtonMobile.vue'

export default {
  inject: ['store', 'mutations'],
  components: { ButtonMobile },
  props: ['index', 'product'],
  data: function () {
    return {
      form: this.store.cart[this.product.id].subproducts[this.product.index]['form'] || 1,
      length: this.store.cart[this.product.id].subproducts[this.product.index]['length'],
      hidden: this.index > 0,
      lastItem: this.index == this.mutations.tables().length - 1,
      invalidLength: false
    }
  },
  methods: {
    toggleForm() {
      this.hidden = this.hidden ? this.hidden = false : this.hidden = true;
    },
    validate() {
      if(this.length < 150 || this.length > 3000) {
        this.invalidLength = true;
      } else {
        this.invalidLength = false;
        this.confirm();
        this.closeTableForm();
        this.$el.scrollIntoView()
      }
    },
    confirm() {
      this.store.cart[this.product.id].subproducts[this.product.index]['form'] = this.form;
      this.store.cart[this.product.id].subproducts[this.product.index]['length'] = this.length;
      this.store.cart[this.product.id].subproducts[this.product.index]['price'] = this.length / 1000 * this.store.cart[this.product.id].CATALOG_PRICE_4;
    },
    closeTableForm() {
      let nextTableForm = this.$parent.$refs[`table${this.index + 1}`];
      this.hidden = true;
      if(nextTableForm) nextTableForm.hidden = false;
    }
  }
}
</script>

<style scoped>
.fader-enter-active {
  transition: opacity .3s ease-in;
}

.fader-leave-active {
  transition: opacity .3s ease-out;
}

.fader-enter-from,
.fader-leave-to {
  opacity: 0;
}

.form__item {
  border-top: 1px solid #E5E5E5;
}

.form__item--last {
  border-bottom: 1px solid #E5E5E5;
}

.form__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  padding: 18px 0;
}

.form__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 16px;
}

.form__content {
  margin: 16px 0 32px 0;
}

.form__content--hidden {
  display: none;
}

.form__icon {
  position: relative;
  transform: rotate(0deg);
}

.form__icon--hidden {
  transform: rotate(-90deg);
}

.form__imgbox {
  flex: 1;
  height: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F4F4F4;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
}

.form__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.form__input {
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  text-align: center;
  -webkit-user-select:text;
  margin-bottom: 16px;
}

.form__input:focus::placeholder {
  color: transparent;
}

.form__alert {
  font-size: 12px;
  line-height: 14px;
  color: #CC3333;
  margin-bottom: 24px;
}

.form__selectbox {
  position: relative;
  margin-bottom: 16px;
}

.form__selecticon {
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.select {
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 8px;
  height: 48px;
  padding: 16px;
  width: 100%;
  text-align: center;
}
</style>