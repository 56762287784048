<template>
  <div class="app app--desktop">
    <Cover v-if="kitchens == ''" />
    <Header />

    <div class="app__grid">
      <div class="grid">
        <router-link v-for="kitchen in kitchens" :key="kitchen.id" :to="kitchenLink(kitchen.ID)" class="grid__item">
          <swiper
            class="grid__imgbox"
            :navigation="{
              nextEl: `.grid__arrow--next${kitchen.id}`,
              prevEl: `.grid__arrow--prev${kitchen.id}`,
            }"
            :slides-per-view="1"
          >
            <swiper-slide v-for="(pic, index) in kitchen.PICTURES" :key="index">
              <img v-lazy="`https://33komoda.ru/${pic}`" class="grid__img" />
            </swiper-slide>

            <div class="grid__arrow grid__arrow--prev" :class="`grid__arrow--prev${kitchen.id}`">
              <ArrowLeftSvg class="grid__icon--prev" />
            </div>
            <div class="grid__arrow grid__arrow--next" :class="`grid__arrow--next${kitchen.id}`">
              <ArrowRightSvg class="grid__icon--next" />
            </div>
          </swiper>

          <div class="grid__name">
            {{ kitchen.NAME.replace(/\..*/g, "$'") }}
          </div>
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Cover from "@/components/Cover.vue";
import Header from "@/components/desktop/Header.vue";
import Footer from "@/components/desktop/Footer.vue";

import ArrowLeftSvg from "@/components/svg/ArrowLeftSvg.vue";
import ArrowRightSvg from "@/components/svg/ArrowRightSvg.vue";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation]);

import { ref } from "vue";
import { store } from "@/store";

const kitchens = ref("");

const kitchenLink = (id) => {
  return !store.isPartner ? `/${id}` : `${id}/description?partnerId=${store.partnerId}`;
};

const getKitchens = async () => {
  try {
    const data = await fetch(`https://33komoda.ru/kitchen-planning/?do=getKitchens`);
    const response = await data.json();
    kitchens.value = response.items;
  } catch (err) {
    console.log(err);
  }
};

getKitchens();
</script>

<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -32px;
}
.grid__item {
  position: relative;
  width: calc(100% / 3 - 32px);
  margin: 0 32px 24px 0;
}
.grid__item:hover .grid__img {
  filter: brightness(0.9);
  transition: 0.3s;
}
.grid__item:hover .grid__arrow {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}
.grid__imgbox {
  height: 307px;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;
}
.grid__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.3s;
}
.grid__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}
.grid__arrow {
  cursor: pointer;
  width: 118px;
  height: 118px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  top: 0;
  position: absolute;
  bottom: 0;
  margin: auto;
  transition: 0.3s;
}
.grid__arrow--prev {
  left: -59px;
}
.grid__arrow--next {
  right: -59px;
}
.grid__arrow.swiper-button-disabled {
  opacity: 0.5;
  transition: 0.3s;
  pointer-events: none;
}
.grid__icon--next {
  position: relative;
  right: 20px;
}

.grid__icon--prev {
  position: relative;
  left: 20px;
}
.grid__arrow.swiper-button-disabled {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
</style>
