<template>
  <section class="cover" :class="{'cover--active': store.showModalCover}"></section>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
}
</script>

<style scoped>
.cover {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 19;
  background: rgba(0, 0, 0, .5);
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}

.cover--active {
  visibility: visible;
  opacity: 1;
  transition: .3s;
}
</style>