<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { store, mutations } from './store'

export default {
  name: 'App',
  provide: {
    store,
    mutations
  },
  mounted () {
    history.pushState({}, '')
  }
}
</script>

<style>
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  src: url('./assets/fonts/Roboto-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  font-display: swap;
  src: url('./assets/fonts/Roboto-Bold.woff2');
  font-weight: 600;
}

* {
  box-sizing: border-box;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
}

.app--desktop {
  min-width: 1200px;
}

a {
  text-decoration: none;
  color: #404040;
}

button {
  background: unset;
  padding: unset;
  border: unset;
  color: #404040;
  font-family: Roboto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: #404040;
  font-family: Roboto;
}

input {
  border: none;
  padding: unset;
  outline: none;
  font-family: Roboto;
  color: #404040;
  font-size: 14px;
  line-height: 16px;
  user-select: all;
  -webkit-user-select: text;
}

textarea {
  font-family: Roboto;
  color: #404040;
  font-size: 14px;
  line-height: 16px;
  user-select: all;
  -webkit-user-select: text;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app {
  font-family: Roboto;
  color: #404040;
  background: #f1f1f1;
}

.app--mobile {
  background: white;
}

.app__grid {
  max-width: 1440px;
  padding: 0 40px;
  min-width: 1024px;
  margin: 0 auto 40px auto;
}

.app__flex {
  max-width: 1440px;
  padding: 0 40px;
  display: flex;
  align-items: flex-start;
  font-family: Roboto;
  margin: 0 auto 32px auto;
  min-width: 1200px;
}

.app__sidebar {
  width: 376px;
  margin-left: 24px;
  position: sticky;
  top: 16px;
}

.clicktrack__button {
  font-size: 14px;
  margin-top: 6px;
  color: #cc3333;
  cursor: pointer;
}
img[lazy='loading'] {
  opacity: 0;
  transition: 0.3s;
}
img[lazy='loaded'] {
  opacity: 1;
  transition: 0.3s;
}
</style>
