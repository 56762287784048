<template>
  <div class="app app--desktop">
    <Cover v-if="store.kitchen == '' && (store.productsAll == '' || store.productsAll == null)" />
    <Header />
    <Intro />
    <Subnavigation ref="subnavigation" />

    <div v-if="store.step < 5" class="app__flex">
      <Body ref="body" />
      <div class="app__sidebar">
        <Order />
        <Details />
        <Filter ref="filter" />
      </div>
    </div>

    <Checkout />
    <Success />
    <Footer />
    <Panel />
    <Modal ref="modal" />
  </div>
</template>

<script setup>
import Cover from '@/components/Cover.vue'
import Header from '@/components/desktop/Header.vue'
import Intro from '@/components/desktop/Intro.vue'
import Subnavigation from '@/components/desktop/Subnavigation.vue'
import Body from '@/components/desktop/Body.vue'
import Order from '@/components/desktop/Order.vue'
import Details from '@/components/desktop/Details.vue'
import Filter from '@/components/desktop/Filter.vue'
import Footer from '@/components/desktop/Footer.vue'
import Panel from '@/components/desktop/Panel.vue'
import Checkout from '@/components/desktop/Checkout.vue'
import Success from '@/components/desktop/Success.vue'
import Modal from '@/components/desktop/Modal.vue'

import { watch, inject } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = inject('store')
const mutations = inject('mutations')

store.kitchenId = router.currentRoute.value.params.id
mutations.setActiveSidebar('order')

if (store.kitchenId == localStorage.getItem('kitechenIdKit') && localStorage.getItem('stepKit') != 5) {
  mutations.updateStore()
} else {
  mutations.cleanStore()
}

if (store.step < 4) mutations.getProducts(store.kitchenId)

watch(mutations.getSubStep, (i, d) => {
  console.log(store.maxSubStep)
  localStorage.setItem('stepKit', store.step)
  localStorage.setItem('subStepKit', store.subStep)
  localStorage.setItem('maxStepKit', store.maxStep)
  localStorage.setItem('maxSubStepKit', store.maxSubStep)
})

watch(mutations.getStep, () => {
  if (store.step == 4) localStorage.setItem('stepKit', store.step)
  if (store.step == 5) localStorage.setItem('stepKit', store.step)
})

watch(
  store.cart,
  () => {
    let cartString = JSON.stringify(store.cart)
    localStorage.setItem('cartKit', cartString)
  },
  { deep: true }
)
</script>
