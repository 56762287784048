<template>
  <transition name="slide" mode="out-in">
    <section class="order" v-if="store.activeSidebar == 'order' && store.step < 4">
      <div class="order__box">
        <header class="order__header">
          Состав заказа
          <svg @click="close" width="48" height="48" viewBox="0 0 48 48"><path d="M24.9106 24L29.5248 18.4998C29.6022 18.4084 29.5371 18.2695 29.4176 18.2695H28.0149C27.9323 18.2695 27.8532 18.3064 27.7987 18.3697L23.993 22.9066L20.1873 18.3697C20.1346 18.3064 20.0555 18.2695 19.9711 18.2695H18.5684C18.4488 18.2695 18.3838 18.4084 18.4612 18.4998L23.0754 24L18.4612 29.5002C18.4438 29.5206 18.4327 29.5455 18.4291 29.572C18.4255 29.5985 18.4296 29.6255 18.4409 29.6497C18.4522 29.674 18.4702 29.6945 18.4928 29.7088C18.5154 29.7231 18.5416 29.7306 18.5684 29.7305H19.9711C20.0537 29.7305 20.1328 29.6936 20.1873 29.6303L23.993 25.0934L27.7987 29.6303C27.8514 29.6936 27.9305 29.7305 28.0149 29.7305H29.4176C29.5371 29.7305 29.6022 29.5916 29.5248 29.5002L24.9106 24Z" fill="#404040"/></svg>
        </header>

        <div class="order__grid">

          <div v-if="isFirstStep">
            <div class="order__text">Здесь появятся добавленные вами товары. Выберите что-нибудь в каталоге.</div>
          </div>

          <div v-else>
            <div v-for="category in categories" :key="category.id">

              <div v-if="category.id == 5">
                <div class="order__item" v-for="product, index in mutations.tables()" :key="index" @click="toggleActiveTable(product);">
                  <div class="order__imgbox">
                    <img class="order__img" v-if="store.cart[product.id].PREVIEW_PICTURE" :src="`https://33komoda.ru${store.cart[product.id].PREVIEW_PICTURE}`">
                    <svg v-else height="48" viewBox="0 0 24 24" width="48" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
                  </div>

                  <div class="order__content"> 
                    <div class="order__name">Столешница №{{ index + 1 }}</div>
                    <div class="order__name" v-if="product.form == '1'">Прямая</div>
                    <div class="order__name" v-if="product.form == '2'">Левый радиус</div>
                    <div class="order__name" v-if="product.form == '3'">Правый радиус</div>
                    <div class="order__name" v-if="product.form == '4'">Левый скос</div>
                    <div class="order__name" v-if="product.form == '5'">Правый скос</div>
                    <div v-if="product.length == null">{{ store.cart[product.id].NAME_CUSTOM }}</div>
                    <div v-else>{{ product.length.toLocaleString() }}мм / {{ product.price.toLocaleString()}} ₽</div>
                  </div>         
                </div>
              </div>
              
              <div v-if="inCart(category.id)">
                <div v-for="product in mutations.cartArray()" :key="product.ID">

                  <div class="order__item" v-if="isSameCategory(product, category) && !isTable(product)" @click="toggleActiveThumb(product)">
                    <div class="order__imgbox">
                      <img class="order__img" v-if="product.PREVIEW_PICTURE" :src="`https://33komoda.ru${product.PREVIEW_PICTURE}`">
                      <svg v-else height="48" viewBox="0 0 24 24" width="48" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
                    </div>

                    <div class="order__content" v-if="isFacade(product)">
                      <div class="order__name">
                        <span v-if="product.facade == 'top'">Верхние фасады</span>
                        <span v-if="product.facade == 'bottom'">Нижние фасады</span>
                        <span v-if="product.facade == 'both'">Верхние и нижние фасады</span>
                      </div>
                      <div class="order__subname">{{ product.NAME_CUSTOM }}</div>
                    </div>             

                    <div class="order__content" v-else>   
                      <div class="order__name">{{ product.NAME_CUSTOM }}</div>
                      <div><span v-if="product.COUNT > 1">{{ product.COUNT }} x</span> {{ Number(product.CATALOG_PRICE_4).toLocaleString() }} ₽</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div class="order__item" v-if="(category.step < store.maxStep) || (category.subStep <= store.maxSubStep && category.step == store.maxStep)" @click="goToCategory(category.step, category.subStep)">
                  <div class="order__imgbox">
                    <div class="order__close order__close--nomargin">
                      <svg width="22" height="22" viewBox="0 0 22 22"><path d="M12.1131 11L17.7527 4.27754C17.8472 4.16582 17.7678 3.99609 17.6217 3.99609H15.9072C15.8062 3.99609 15.7096 4.04121 15.643 4.11855L10.9916 9.66367L6.34021 4.11855C6.27576 4.04121 6.17908 3.99609 6.07595 3.99609H4.3615C4.21541 3.99609 4.13591 4.16582 4.23045 4.27754L9.8701 11L4.23045 17.7225C4.20927 17.7474 4.19568 17.7778 4.1913 17.8102C4.18692 17.8426 4.19193 17.8756 4.20572 17.9052C4.21952 17.9348 4.24154 17.9599 4.26915 17.9774C4.29676 17.9949 4.32881 18.0041 4.3615 18.0039H6.07595C6.17693 18.0039 6.27361 17.9588 6.34021 17.8814L10.9916 12.3363L15.643 17.8814C15.7074 17.9588 15.8041 18.0039 15.9072 18.0039H17.6217C17.7678 18.0039 17.8472 17.8342 17.7527 17.7225L12.1131 11Z" fill="#404040"/></svg>
                    </div>
                  </div>
                  <div class="order__content">
                    <div class="order__name">Без товаров из раздела</div>
                    <div>{{ category.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>      

          <div class="order__summ">Итого: {{ mutations.totalPrice() }} ₽</div>
        </div>
        
        <div class="order__close" @click="close">
          <svg width="22" height="22" viewBox="0 0 22 22"><path d="M12.1129 11L17.7526 4.27754C17.8471 4.16582 17.7676 3.99609 17.6215 3.99609H15.9071C15.8061 3.99609 15.7094 4.04121 15.6428 4.11855L10.9915 9.66367L6.34009 4.11855C6.27564 4.04121 6.17896 3.99609 6.07583 3.99609H4.36138C4.21528 3.99609 4.13579 4.16582 4.23032 4.27754L9.86997 11L4.23032 17.7225C4.20915 17.7474 4.19556 17.7778 4.19118 17.8102C4.1868 17.8426 4.1918 17.8756 4.2056 17.9052C4.2194 17.9348 4.24141 17.9599 4.26903 17.9774C4.29664 17.9949 4.32869 18.0041 4.36138 18.0039H6.07583C6.17681 18.0039 6.27349 17.9588 6.34009 17.8814L10.9915 12.3363L15.6428 17.8814C15.7073 17.9588 15.804 18.0039 15.9071 18.0039H17.6215C17.7676 18.0039 17.8471 17.8342 17.7526 17.7225L12.1129 11Z" fill="#404040"/></svg>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  methods: {
    inCart(id) {
      let array = Object.values(this.store.cart);
      return array.filter(product => product.categoryId == id).length > 0;
    },
    toggleActiveThumb(product) {
      this.mutations.setActiveThumb(product);
      this.mutations.setActiveSidebar('details');
    },
    toggleActiveTable(product) {
      this.mutations.setActiveThumb(this.store.cart[product.id]);
      this.mutations.setActiveSidebar('details');
    },
    goToCategory(step, subStep) {
      this.mutations.setStep(step);
      this.mutations.setSubStep(subStep);
      this.mutations.getProducts(this.store.kitchenId);
      this.close();
    },
    isTable(product) {
      return product.categoryId == 5;
    },
    isFacade(product) {
      return product.categoryId == 3;
    },
    isSameCategory(product, category) {
      return product.categoryId == category.id;
    },
    close() {
      this.mutations.setActiveSidebar('');
      this.mutations.toggleCover();
    }
  },
  computed: {
    isFirstStep() {
      return Object.keys(this.store.cart).length == 0 && this.store.step == 1 && this.store.subStep == 1;
    },
    categories() {
      return this.store.subcategories.flat().filter(category => category.id != 6).slice().reverse();
    }
  }
}
</script>

<style scoped>
.slide-enter-active, 
.slide-leave-active {
  transition: transform .3s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100%);
}


.order {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.order__box {
  background: #FFFFFF;
  border-radius: 30px 30px 0px 0px;
  padding-bottom: 10px;
  margin-top: 64px;
}

.order__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 3px 4px 3px 16px;
  margin-bottom: 32px;
}

.order__grid {
  padding: 0 16px;
}

.order__item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.order__imgbox {
  position: relative;
  width: 96px;
  height: 96px;
  background: #F5F5F5;
  border-radius: 8px;
  margin-right: 24px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order__img {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: auto;
  height: 100%;
  max-width: initial !important;
}

.order__content {
  flex: 1;
  font-size: 14px;
  line-height: 24px;
}

.order__name {
  font-size: 14px;
  line-height: 24px;
  color: rgba(64, 64, 64, 0.5);
  max-height: 48px;
  overflow: hidden;
}

.order__circle {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; 
  top: 0;
  margin: auto;
  cursor: pointer;
}

.order__summ {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.order__close {
  width: 64px;
  height: 64px;
  background: #F1F1F1;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 72px auto 56px auto;
}

.order__close--nomargin {
  margin: unset;
  background: white;
}
</style>