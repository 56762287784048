<template>
  <section class="body" v-if="store.step < 4">
    <transition name="fade" mode="out-in">
      <div class="body__cover" v-if="store.showBodyCover">
        <div class="lds-ring lds-ring--active"><div></div><div></div><div></div><div></div></div>
        <div class="body__loading">Загрузка</div>
      </div>
      <div v-else>
        <div v-if="store.productsAll != ''">
          <div class="body__title">{{ mutations.currentCategory().name }}</div>
          <!-- <div class="body__description" v-if="mutations.currentCategory().description != ''">{{ mutations.currentCategory().description }}</div> -->
          <div class="body__description" :class="{'body__description--normal':lockedDescription}">
            <div v-if="mutations.currentCategory().id == 1">
              Для выбора нужного шкафа нажмите на карточку товара. Используйте кнопки фильтрации для упрощения поиска в каталоге.
            </div>
            <div v-else-if="mutations.currentCategory().id == 2">
              Для выбора нужного шкафа нажмите на карточку товара.
            </div>
            <div v-else-if="mutations.currentCategory().id == 3">
              Для выбора нужного цвета, добавьте его в заказ, указав к какому типу шкафов он относится.
            </div>
            <div v-else-if="mutations.currentCategory().id == 4">
              Барный стол – это отличное дополнение современной кухни.
            </div>
            <div v-else-if="mutations.currentCategory().id == 5">
              Выберите цвет и необходимое количество раздельных отрезков столешницы. Одна, если столешница общая по всем шкафам, два и более, если кухня угловая, либо разделяется отдельно стоящей плитой. Указание размеров и формы по каждому отрезку предусмотрено на следующем шаге.
            </div>
            <div v-else-if="mutations.currentCategory().id == 6">
              Выберите форму и укажите размер каждого отрезка столешницы. Максимальная длина одного отрезка 3000 мм."
            </div>
            <div v-else-if="mutations.currentCategory().id == 7">
              Добавьте в заказ стеновую панель, если вы не планируете кухонный фартур из плитки.
            </div>
            <div v-else-if="mutations.currentCategory().id == 8">
              Выберите нужное количество планок в соответствии с выбранной толщиной столешницы и наличия стеновой панели.
            </div>
            <div v-else-if="mutations.currentCategory().id == 9">
              Выберите плинтус близкий по тональности к декору выбранной столешницы.
            </div>
            <div v-else-if="mutations.currentCategory().id == 10">
              Выбирая мойку, обратите внимание на размер, он не должен превышать размер шкафа, который вы определили под мойку.
            </div>
            <div v-else-if="mutations.currentCategory().id == 11">
              Выбирая сушку, обратите внимание на размер, он должен соответствовать размеру шкафа который вы определили под сушку.
            </div>
            <div v-else-if="mutations.currentCategory().id == 12">
              Выберите нужное количество доводчиков, дополнительно к стандартной комплектации.
            </div>
            <div v-else-if="mutations.currentCategory().id == 13">
              Выберите термопланки в соответствии с техникой в вашей кухне.
            </div>
            <div v-else-if="mutations.currentCategory().id == 14">
              Выберите цоколь и заглушки исходя из габаритных размеров гарнитура.
            </div>
            <svg class="body__close" :class="{'body__close--normal':lockedDescription}" @click="unfix" width="48" height="48" viewBox="0 0 48 48"><path d="M24.9106 24L29.5248 18.4998C29.6022 18.4084 29.5371 18.2695 29.4176 18.2695H28.0149C27.9323 18.2695 27.8532 18.3064 27.7987 18.3697L23.993 22.9066L20.1873 18.3697C20.1346 18.3064 20.0555 18.2695 19.9711 18.2695H18.5684C18.4488 18.2695 18.3838 18.4084 18.4612 18.4998L23.0754 24L18.4612 29.5002C18.4438 29.5206 18.4327 29.5455 18.4291 29.572C18.4255 29.5985 18.4296 29.6255 18.4409 29.6497C18.4522 29.674 18.4702 29.6945 18.4928 29.7088C18.5154 29.7231 18.5416 29.7306 18.5684 29.7305H19.9711C20.0537 29.7305 20.1328 29.6936 20.1873 29.6303L23.993 25.0934L27.7987 29.6303C27.8514 29.6936 27.9305 29.7305 28.0149 29.7305H29.4176C29.5371 29.7305 29.6022 29.5916 29.5248 29.5002L24.9106 24Z" fill="#404040"/></svg>
          </div>

          <ButtonMobile class="button button--bordered button--full button--big button--centered " v-if="mutations.currentCategory().name != 'Размер столешницы'" @click="openFilter">
            Фильтровать
            <svg class="button__icon" style="margin-left: 8px" width="14" height="14" viewBox="0 0 14 14"><path d="M11.813 3.50616H2.188C1.946 3.50616 1.75 3.70216 1.75 3.94416V4.07916C1.757 4.26816 1.995 4.48516 2.184 4.49016H11.838C12.061 4.49016 12.25 4.30316 12.25 4.08016V3.94416C12.25 3.70216 12.055 3.50616 11.813 3.50616Z" fill="#404040"/><path d="M8.44621 9.50665H5.55954C5.41828 9.50665 5.30444 9.70265 5.30444 9.94465V10.0797C5.30853 10.2687 5.44746 10.4857 5.55779 10.4907H8.46081C8.59157 10.4907 8.7019 10.3037 8.7019 10.0807V9.94465C8.7019 9.70265 8.58748 9.50665 8.44621 9.50665Z" fill="#404040"/><path d="M9.98826 6.50781H3.88288C3.69423 6.50781 3.54144 6.70381 3.54144 6.94581V7.08081C3.5469 7.26981 3.73243 7.48681 3.87976 7.49181H10.0077C10.1816 7.49181 10.3289 7.30481 10.3289 7.08181V6.94581C10.3289 6.70381 10.1769 6.50781 9.98826 6.50781Z" fill="#404040"/></svg>
          </ButtonMobile>
          
          
              
          <div class="body__buttons" v-if="mutations.currentCategory().id == 1">
            <ButtonMobile class="button button--bordered button--big body__button" @click="toggleHeight('720')">
              Высота: 720 мм
              <svg v-if="mutations.isFilteredBy720()" style="margin-left: 8px;" width="14" height="14" viewBox="0 0 14 14"><path d="M7.70831 7L11.2972 2.72207C11.3573 2.65098 11.3068 2.54297 11.2138 2.54297H10.1228C10.0585 2.54297 9.99698 2.57168 9.9546 2.6209L6.99464 6.14961L4.03468 2.6209C3.99366 2.57168 3.93214 2.54297 3.86652 2.54297H2.7755C2.68253 2.54297 2.63195 2.65098 2.6921 2.72207L6.28097 7L2.6921 11.2779C2.67863 11.2938 2.66998 11.3132 2.66719 11.3338C2.6644 11.3544 2.66759 11.3754 2.67637 11.3942C2.68515 11.4131 2.69916 11.429 2.71673 11.4402C2.7343 11.4513 2.7547 11.4571 2.7755 11.457H3.86652C3.93077 11.457 3.9923 11.4283 4.03468 11.3791L6.99464 7.85039L9.9546 11.3791C9.99562 11.4283 10.0571 11.457 10.1228 11.457H11.2138C11.3068 11.457 11.3573 11.349 11.2972 11.2779L7.70831 7Z" fill="#404040"/></svg>
            </ButtonMobile>
            <ButtonMobile class="button button--bordered button--big body__button" @click="toggleHeight('913')">
              Высота: 913 мм
              <svg v-if="mutations.isFilteredBy913()" style="margin-left: 8px;" width="14" height="14" viewBox="0 0 14 14"><path d="M7.70831 7L11.2972 2.72207C11.3573 2.65098 11.3068 2.54297 11.2138 2.54297H10.1228C10.0585 2.54297 9.99698 2.57168 9.9546 2.6209L6.99464 6.14961L4.03468 2.6209C3.99366 2.57168 3.93214 2.54297 3.86652 2.54297H2.7755C2.68253 2.54297 2.63195 2.65098 2.6921 2.72207L6.28097 7L2.6921 11.2779C2.67863 11.2938 2.66998 11.3132 2.66719 11.3338C2.6644 11.3544 2.66759 11.3754 2.67637 11.3942C2.68515 11.4131 2.69916 11.429 2.71673 11.4402C2.7343 11.4513 2.7547 11.4571 2.7755 11.457H3.86652C3.93077 11.457 3.9923 11.4283 4.03468 11.3791L6.99464 7.85039L9.9546 11.3791C9.99562 11.4283 10.0571 11.457 10.1228 11.457H11.2138C11.3068 11.457 11.3573 11.349 11.2972 11.2779L7.70831 7Z" fill="#404040"/></svg>
            </ButtonMobile>
          </div>

          <div class="body__buttons" v-else-if="mutations.currentCategory().id == 5">
            <ButtonMobile class="button button--bordered button--big body__button" @click="toggleThickness('26')">
              Толщина: 26 мм
              <svg v-if="mutations.isFilteredBy26()" style="margin-left: 8px;" width="14" height="14" viewBox="0 0 14 14"><path d="M7.70831 7L11.2972 2.72207C11.3573 2.65098 11.3068 2.54297 11.2138 2.54297H10.1228C10.0585 2.54297 9.99698 2.57168 9.9546 2.6209L6.99464 6.14961L4.03468 2.6209C3.99366 2.57168 3.93214 2.54297 3.86652 2.54297H2.7755C2.68253 2.54297 2.63195 2.65098 2.6921 2.72207L6.28097 7L2.6921 11.2779C2.67863 11.2938 2.66998 11.3132 2.66719 11.3338C2.6644 11.3544 2.66759 11.3754 2.67637 11.3942C2.68515 11.4131 2.69916 11.429 2.71673 11.4402C2.7343 11.4513 2.7547 11.4571 2.7755 11.457H3.86652C3.93077 11.457 3.9923 11.4283 4.03468 11.3791L6.99464 7.85039L9.9546 11.3791C9.99562 11.4283 10.0571 11.457 10.1228 11.457H11.2138C11.3068 11.457 11.3573 11.349 11.2972 11.2779L7.70831 7Z" fill="#404040"/></svg>
            </ButtonMobile>
            <ButtonMobile class="button button--bordered button--big" @click="toggleThickness('38')">
              Толщина: 38 мм
              <svg v-if="mutations.isFilteredBy38()" style="margin-left: 8px;" width="14" height="14" viewBox="0 0 14 14"><path d="M7.70831 7L11.2972 2.72207C11.3573 2.65098 11.3068 2.54297 11.2138 2.54297H10.1228C10.0585 2.54297 9.99698 2.57168 9.9546 2.6209L6.99464 6.14961L4.03468 2.6209C3.99366 2.57168 3.93214 2.54297 3.86652 2.54297H2.7755C2.68253 2.54297 2.63195 2.65098 2.6921 2.72207L6.28097 7L2.6921 11.2779C2.67863 11.2938 2.66998 11.3132 2.66719 11.3338C2.6644 11.3544 2.66759 11.3754 2.67637 11.3942C2.68515 11.4131 2.69916 11.429 2.71673 11.4402C2.7343 11.4513 2.7547 11.4571 2.7755 11.457H3.86652C3.93077 11.457 3.9923 11.4283 4.03468 11.3791L6.99464 7.85039L9.9546 11.3791C9.99562 11.4283 10.0571 11.457 10.1228 11.457H11.2138C11.3068 11.457 11.3573 11.349 11.2972 11.2779L7.70831 7Z" fill="#404040"/></svg>
            </ButtonMobile>
          </div>

          <div class="body__buttons" v-else-if="mutations.currentCategory().id == 10">
            <ButtonMobile class="button button--bordered button--big body__button" @click="toggleSinks('Мойка')">
              Мойки
              <svg v-if="mutations.isFilteredBySink()" style="margin-left: 8px;" width="14" height="14" viewBox="0 0 14 14"><path d="M7.70831 7L11.2972 2.72207C11.3573 2.65098 11.3068 2.54297 11.2138 2.54297H10.1228C10.0585 2.54297 9.99698 2.57168 9.9546 2.6209L6.99464 6.14961L4.03468 2.6209C3.99366 2.57168 3.93214 2.54297 3.86652 2.54297H2.7755C2.68253 2.54297 2.63195 2.65098 2.6921 2.72207L6.28097 7L2.6921 11.2779C2.67863 11.2938 2.66998 11.3132 2.66719 11.3338C2.6644 11.3544 2.66759 11.3754 2.67637 11.3942C2.68515 11.4131 2.69916 11.429 2.71673 11.4402C2.7343 11.4513 2.7547 11.4571 2.7755 11.457H3.86652C3.93077 11.457 3.9923 11.4283 4.03468 11.3791L6.99464 7.85039L9.9546 11.3791C9.99562 11.4283 10.0571 11.457 10.1228 11.457H11.2138C11.3068 11.457 11.3573 11.349 11.2972 11.2779L7.70831 7Z" fill="#404040"/></svg>
            </ButtonMobile>
            <ButtonMobile class="button button--bordered button--big" @click="toggleSinks('Смеситель')">
              Смесители
              <svg v-if="mutations.isFilteredByMixer()" style="margin-left: 8px;" width="14" height="14" viewBox="0 0 14 14"><path d="M7.70831 7L11.2972 2.72207C11.3573 2.65098 11.3068 2.54297 11.2138 2.54297H10.1228C10.0585 2.54297 9.99698 2.57168 9.9546 2.6209L6.99464 6.14961L4.03468 2.6209C3.99366 2.57168 3.93214 2.54297 3.86652 2.54297H2.7755C2.68253 2.54297 2.63195 2.65098 2.6921 2.72207L6.28097 7L2.6921 11.2779C2.67863 11.2938 2.66998 11.3132 2.66719 11.3338C2.6644 11.3544 2.66759 11.3754 2.67637 11.3942C2.68515 11.4131 2.69916 11.429 2.71673 11.4402C2.7343 11.4513 2.7547 11.4571 2.7755 11.457H3.86652C3.93077 11.457 3.9923 11.4283 4.03468 11.3791L6.99464 7.85039L9.9546 11.3791C9.99562 11.4283 10.0571 11.457 10.1228 11.457H11.2138C11.3068 11.457 11.3573 11.349 11.2972 11.2779L7.70831 7Z" fill="#404040"/></svg>
            </ButtonMobile>
          </div>

          <!-- <div class="body__buttons" v-else-if="mutations.currentCategory().id == 11">
            <ButtonMobile class="button button--bordered button--big body__button">Сушки</ButtonMobile>
            <ButtonMobile class="button button--bordered button--big">Тарелки</ButtonMobile>
          </div>

          <div class="body__buttons" v-else-if="mutations.currentCategory().id == 12">
            <ButtonMobile class="button button--bordered button--big body__button">Петли с доводчиком</ButtonMobile>
            <ButtonMobile class="button button--bordered button--big">Направляющие с доводчиком</ButtonMobile>
          </div> -->

          <TableMobile v-if="mutations.currentCategory().id == 6"/>

          <div v-else>
            <div class="error" v-if="store.productsAll == null">
              <div class="error__header">
                <div class="error__note">Товаров с заданными характеристиками не существует.</div>
                <div class="error__subnote">Измените параметры поиска.</div>

                <ButtonMobile class="button button--bordered" @click="mutations.resetProducts();">
                  Очистить фильтр
                  <svg class="button__icon" style="margin-left: 24px;" width="14" height="14" viewBox="0 0 14 14"><path d="M12.4291 2.86152L11.658 3.46445C10.6066 2.12051 8.97149 1.25781 7.13536 1.25781C3.96485 1.25781 1.39864 3.82129 1.39454 6.99316C1.39043 10.1678 3.96211 12.7422 7.13536 12.7422C9.61407 12.7422 11.7264 11.1699 12.5303 8.96738C12.5508 8.90996 12.5207 8.8457 12.4633 8.82656L11.6881 8.55996C11.6611 8.5507 11.6315 8.55238 11.6057 8.56464C11.5799 8.5769 11.5599 8.59878 11.55 8.62559C11.5254 8.69395 11.4981 8.7623 11.4693 8.8293C11.2328 9.38984 10.8938 9.89297 10.4617 10.325C10.0332 10.7544 9.52578 11.0969 8.96739 11.334C8.38907 11.5787 7.77247 11.7031 7.13809 11.7031C6.50235 11.7031 5.88711 11.5787 5.30879 11.334C4.74985 11.0979 4.24228 10.7552 3.81446 10.325C3.38475 9.89651 3.04252 9.3885 2.80684 8.8293C2.56211 8.24961 2.4377 7.63438 2.4377 6.99863C2.4377 6.36289 2.56211 5.74766 2.80684 5.16797C3.04336 4.60742 3.38243 4.1043 3.81446 3.67227C4.24649 3.24023 4.74961 2.90117 5.30879 2.66328C5.88711 2.41855 6.50372 2.29414 7.13809 2.29414C7.77383 2.29414 8.38907 2.41855 8.96739 2.66328C9.52633 2.89932 10.0339 3.24204 10.4617 3.67227C10.5971 3.80762 10.7242 3.95117 10.8418 4.10156L10.0188 4.74414C10.0025 4.75674 9.99007 4.77368 9.98298 4.79301C9.9759 4.81234 9.97441 4.83328 9.9787 4.85341C9.98298 4.87355 9.99286 4.89207 10.0072 4.90684C10.0215 4.92161 10.0398 4.93204 10.0598 4.93691L12.4606 5.5248C12.5289 5.54121 12.5959 5.48926 12.5959 5.41953L12.6068 2.94629C12.6055 2.85605 12.5002 2.80547 12.4291 2.86152Z" fill="#404040"/></svg>
                </ButtonMobile>
              </div>
            </div>

            <div v-else>
              <div class="body__grid">
                <ThumbMobile v-for="(product, index) in mutations.productsCurrent()" :key="index" :product="product"/>
              </div>

              <div class="body__footer" v-if="store.productsAll.length > store.perPage">
                <PaginationMobile :productsAllCount="store.productsAll.length"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import ThumbMobile from '../../components/mobile/ThumbMobile.vue'
import TableMobile from '../../components/mobile/TableMobile.vue'
import PaginationMobile from '../../components/mobile/PaginationMobile.vue'
import ButtonMobile from './ButtonMobile.vue'

export default {
  inject: ['store', 'mutations'],
  components: {
    ThumbMobile,
    TableMobile,
    PaginationMobile,
    ButtonMobile
  },
  data: function () {
    return {
      lockedDescription: false,
    }
  },
  methods: {
    toggleHeight(val) {
      if(this.store.filtersFields.VYSOTA_VERKHNEGO_SHKAFA.includes(val)) {
        let index  = this.store.filtersFields.VYSOTA_VERKHNEGO_SHKAFA.indexOf(val);
        this.store.filtersFields.VYSOTA_VERKHNEGO_SHKAFA.splice(index, 1);
      } else {
        this.store.filtersFields.VYSOTA_VERKHNEGO_SHKAFA.push(val);
      }
      this.mutations.filterProducts();
      this.$parent.$refs.filterMobile.$forceUpdate();
    },
    toggleThickness(val) {
      if(this.store.filtersFields.TOLSHCHINA_MM.includes(val)) {
        let index = this.store.filtersFields.TOLSHCHINA_MM.indexOf(val);
        this.store.filtersFields.TOLSHCHINA_MM.splice(index, 1);
      } else {
        this.store.filtersFields.TOLSHCHINA_MM.push(val);
      }
      this.mutations.filterProducts();
      this.$parent.$refs.filterMobile.$forceUpdate();
    },
    toggleSinks(val) {
      if(this.store.filtersFields.TIP_MODULYA.includes(val)) {
        let index = this.store.filtersFields.TIP_MODULYA.indexOf(val);
        this.store.filtersFields.TIP_MODULYA.splice(index, 1);
      } else {
        this.store.filtersFields.TIP_MODULYA.push(val);
      }
      this.mutations.filterProducts();
      this.$parent.$refs.filterMobile.$forceUpdate();
    },
    openFilter() {
      this.mutations.setActiveSidebar('filter');
      this.mutations.toggleCover();
    },
    observeDescription() {
      if(this.$el.nodeValue != 'v-if') {
        const handler = (entries) => {
          if (entries[0].isIntersecting) {
            this.lockedDescription = true;
          } 
          else {
            this.lockedDescription = false;
          }
        }
        const observer = new window.IntersectionObserver(handler)
        observer.observe(this.$parent.$refs.subnavigationMobile.$el)
      }
    },
    unfix() {
      this.lockedDescription = true;
    }
  },
  mounted() {
    this.observeDescription()
  }
}
</script>

<style scoped>
.lds-ring {
  margin: auto;
  width: 100%;
  height: 300px;
  display: none;
  align-items: center;
  justify-content: center;
}

.lds-ring--active {
  display: flex;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #CC3333 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active, 
.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.body {
  background: #FFFFFF;
  padding: 16px 16px 24px 16px;
}

.body__cover {
  position: relative;
  height: 1200px;
}

.body__loading {
  font-size: 18px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  top: 210px;
}

.body__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.body__description {
  position: sticky; 
  top: 0;
  background: white;
  z-index: 5;
  padding: 16px 0 24px 0;
  font-size: 14px;
  line-height: 24px;
}

.body__description--normal {
  position: static; 
}

.body__close--normal {
  display: none;
}

.body__close {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.body__buttons {
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
}

.body__button {
  margin-right: 16px;
  white-space: nowrap;
}

.body__grid {
  display: flex;
  flex-wrap: wrap;
  margin: 24px -16px 0 0;
}

.body__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.error {
  border-top: 1px solid #E5E5E5;
  padding: 32px 0;
  margin-top: 24px;
}

.error:last-of-type {
  border-bottom: 1px solid #E5E5E5;
}

.error__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

.error__note {
  width: 100%;
  margin-bottom: 16px;
}

.error__subnote {
  width: 100%;
  font-weight: 400;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 24px;
}
</style>