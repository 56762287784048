<template>
  <div class="app">
    <Cover v-if="store.kitchen == '' && (store.productsAll == '' || store.productsAll == null)"/>

    <HeaderMobile/>
    <IntroMobile/>
    <SubnavigationMobile ref="subnavigationMobile"/>
    <BodyMobile ref="bodyMobile"/>
    <DetailsMobile/>
    <OrderMobile/>
    <FilterMobile ref="filterMobile"/>
    <CheckoutMobile/>
    <SuccessMobile/>
    <FooterMobile/>
    <PanelMobile/>
    <ModalMobile/>
    <CoverMobile/>
  </div>
</template>

<script>
import Cover from '../components/Cover.vue'
import HeaderMobile from '../components/mobile/HeaderMobile.vue'
import IntroMobile from '../components/mobile/IntroMobile.vue'
import SubnavigationMobile from '../components/mobile/SubnavigationMobile.vue'
import BodyMobile from '../components/mobile/BodyMobile.vue'
import OrderMobile from '../components/mobile/OrderMobile.vue'
import DetailsMobile from '../components/mobile/DetailsMobile.vue'
import FilterMobile from '../components/mobile/FilterMobile.vue'
import FooterMobile from '../components/mobile/FooterMobile.vue'
import PanelMobile from '../components/mobile/PanelMobile.vue'
import CheckoutMobile from '../components/mobile/CheckoutMobile.vue'
import SuccessMobile from '../components/mobile/SuccessMobile.vue'
import ModalMobile from '../components/mobile/ModalMobile.vue'
import CoverMobile from '../components/mobile/CoverMobile.vue'

export default {
  inject: ['store', 'mutations'],
  components: {
    Cover,
    HeaderMobile,
    IntroMobile,
    SubnavigationMobile,
    BodyMobile,
    OrderMobile,
    DetailsMobile,
    FilterMobile,
    FooterMobile,
    PanelMobile,
    CheckoutMobile,
    SuccessMobile,
    ModalMobile,
    CoverMobile
  },
  watch: {
    'store.subStep'() {
      localStorage.setItem('stepKit', this.store.step);
      localStorage.setItem('subStepKit', this.store.subStep);
      localStorage.setItem('maxStepKit', this.store.maxStep);
      localStorage.setItem('maxSubStepKit', this.store.maxSubStep);
    },
    'store.step'() {
      if(this.store.step == 4) localStorage.setItem('stepKit', this.store.step);
      if(this.store.step == 5) localStorage.setItem('stepKit', this.store.step);
    },
    'store.cart': {
      handler: function() {
        let cartString = JSON.stringify(this.store.cart);
        localStorage.setItem('cartKit', cartString);
      },
      deep: true
    }
  },
  created() {
    this.store.kitchenId = this.$route.params.id;
  
    if(this.store.kitchenId == localStorage.getItem('kitechenIdKit') && localStorage.getItem('stepKit') != 5) {
      this.mutations.updateStore();
    } else {
      this.mutations.cleanStore();
    }

    if(this.store.step < 4) this.mutations.getProducts(this.store.kitchenId);
  },
}
</script>