import { createRouter, createWebHashHistory } from "vue-router";
import { store } from "../store";
import Index from "../views/Index.vue";
import Description from "@/views/Description.vue";
import Kitchen from "../views/Kitchen.vue";
import Error from "../views/Error.vue";
import CodeForm from "../views/CodeForm.vue";

const getKitchen = async (to) => {
  const data = await fetch(`https://33komoda.ru/kitchen-planning/?do=getKitchen&id=${to.params.id}`);
  const response = await data.json();

  if (Object.hasOwn(response, "item")) {
    store.kitchen = response.item;
    return true;
  } else {
    return false;
  }
};
const getPartner = async () => {
  const dataPartner = await fetch(`https://33komoda.ru/kitchen-planning/?do=getPartner&id=${store.partnerId}`);
  const responsePartner = await dataPartner.json();

  if (Object.hasOwn(responsePartner, "partner")) {
    store.partner = responsePartner.partner;
    return true;
  } else {
    return false;
  }
};

const validateFront = async (to) => {
  const hasPartnerData = await getPartner();

  const isKomody = !store.isPartner;
  const isPartnerFull = store.isPartner && hasPartnerData;

  if (isKomody || isPartnerFull) {
    return true;
  }
  // else {
  //   return {
  //     name: "not-found",
  //     params: { pathMatch: to.path.substring(1).split("/") },
  //     query: to.query,
  //     hash: to.hash,
  //   };
  // }
};

const validateKitchen = async (to) => {
  const hasKitchenData = await getKitchen(to);
  const hasPartnerData = await getPartner();

  const isKomodyFull = !store.isPartner && hasKitchenData;
  const isPartnerFull = store.isPartner && hasKitchenData && hasPartnerData;

  if (isKomodyFull || isPartnerFull) {
    window.document.title = store.kitchen.NAME;
    return true;
  } else {
    return {
      name: "not-found",
      params: { pathMatch: to.path.substring(1).split("/") },
      query: to.query,
      hash: to.hash,
    };
  }
};

const routes = [
  {
    path: "/",
    component: store.isPartner && store.partnerId != "" ? Index : CodeForm,
    meta: { title: "Кухни" },
    redirect: !store.isPartner ? "/143670" : false,
    beforeEnter: (to) => validateFront(to),
  },
  {
    path: "/:id",
    component: !store.isPartner || (store.isPartner && store.partnerId != "") ? Kitchen : Error,
    meta: { title: "Кухня" },
    beforeEnter: (to) => validateKitchen(to),
  },
  {
    path: "/:id/description",
    component: !store.isPartner || (store.isPartner && store.partnerId != "") ? Description : Error,
    meta: { title: "Кухня" },
    beforeEnter: (to) => validateKitchen(to),
  },
  // {
  //   path: "/code",
  //   name: "code",
  //   meta: { title: "Вход в конструктор" },
  //   component: CodeForm,
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: Error,
    meta: { title: "Страница не найдена" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;
