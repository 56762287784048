<template>
  <transition name="slide" mode="out-in">
    <section class="details" v-if="store.activeSidebar == 'details' && store.step < 4">
      <div class="details__box">
        <header class="details__header">
          Обзор товара
          <svg @click="close" width="48" height="48" viewBox="0 0 48 48"><path d="M24.9106 24L29.5248 18.4998C29.6022 18.4084 29.5371 18.2695 29.4176 18.2695H28.0149C27.9323 18.2695 27.8532 18.3064 27.7987 18.3697L23.993 22.9066L20.1873 18.3697C20.1346 18.3064 20.0555 18.2695 19.9711 18.2695H18.5684C18.4488 18.2695 18.3838 18.4084 18.4612 18.4998L23.0754 24L18.4612 29.5002C18.4438 29.5206 18.4327 29.5455 18.4291 29.572C18.4255 29.5985 18.4296 29.6255 18.4409 29.6497C18.4522 29.674 18.4702 29.6945 18.4928 29.7088C18.5154 29.7231 18.5416 29.7306 18.5684 29.7305H19.9711C20.0537 29.7305 20.1328 29.6936 20.1873 29.6303L23.993 25.0934L27.7987 29.6303C27.8514 29.6936 27.9305 29.7305 28.0149 29.7305H29.4176C29.5371 29.7305 29.6022 29.5916 29.5248 29.5002L24.9106 24Z" fill="#404040"/></svg>
        </header>

        <div class="swiper-details swiper-container" v-if="store.activeThumb.MORE_PHOTO">
          <div class="swiper-wrapper">
            <div class="details__imgbox swiper-slide">
              <img class="div details__img" :class="imgClass" :src="`https://33komoda.ru${store.activeThumb.PREVIEW_PICTURE}`">
            </div>
            <div class="details__imgbox swiper-slide">
              <img class="swiper-slide details__img" :class="imgClass" :src="`https://33komoda.ru${store.activeThumb.MORE_PHOTO}`">
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>

        <div class="details__imgbox" v-else>
          <img class="details__img" :class="imgClass" v-if="store.activeThumb.PREVIEW_PICTURE" :src="`https://33komoda.ru${store.activeThumb.PREVIEW_PICTURE}`">
          <svg v-else height="120" viewBox="0 0 24 24" width="120" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
        </div>

        <div class="details__content">
          <div class="details__pricebox">
            <div class="details__price">{{ Number(store.activeThumb.CATALOG_PRICE_4).toLocaleString() }} ₽</div>
            <div class="details__sku">#{{ store.activeThumb.XML_ID }}</div>
          </div>

          <ButtonMobile v-if="!inCart" @click="addHandler" class="button button--red button--full button--centered button--big">
            Добавить в заказ
          </ButtonMobile>

          <div class="details__buttons" v-if="inCart">
            <Quantity :product="store.activeThumb"/>

            <ButtonMobile class="details__remove" @click="mutations.removeAll(store.activeThumb)">
              <svg width="14" height="14" viewBox="0 0 14 14"><path d="M4.92188 2.51562H4.8125C4.87266 2.51562 4.92188 2.46641 4.92188 2.40625V2.51562H9.07812V2.40625C9.07812 2.46641 9.12734 2.51562 9.1875 2.51562H9.07812V3.5H10.0625V2.40625C10.0625 1.92363 9.67012 1.53125 9.1875 1.53125H4.8125C4.32988 1.53125 3.9375 1.92363 3.9375 2.40625V3.5H4.92188V2.51562ZM11.8125 3.5H2.1875C1.94551 3.5 1.75 3.69551 1.75 3.9375V4.375C1.75 4.43516 1.79922 4.48438 1.85938 4.48438H2.68516L3.02285 11.6348C3.04473 12.101 3.43027 12.4688 3.89648 12.4688H10.1035C10.5711 12.4688 10.9553 12.1023 10.9771 11.6348L11.3148 4.48438H12.1406C12.2008 4.48438 12.25 4.43516 12.25 4.375V3.9375C12.25 3.69551 12.0545 3.5 11.8125 3.5ZM9.99824 11.4844H4.00176L3.6709 4.48438H10.3291L9.99824 11.4844Z" fill="#CC3333"/></svg>
              Удалить
            </ButtonMobile>
          </div>

          <div class="details__title">{{ store.activeThumb.NAME_CUSTOM }}</div>

          <div v-if="charsNotEmpty">
            <div v-for="(value, key) in store.activeThumb.CHARS" :key="key">
              <div class="details__char" v-if="key != 'VYSOTA_MM'">
                {{ Object.keys(value)[0] }}:
                <div class="details__value">{{ Object.values(value)[0] }} <span v-if="Object.keys(value)[0] == 'Высота' || Object.keys(value)[0] == 'Ширина' || Object.keys(value)[0] == 'Глубина' || Object.keys(value)[0] == 'Толщина' ">мм</span></div>
              </div>
            </div>
          </div>

          <div v-if="store.activeThumb.DETAIL_TEXT">
            <div class="details__title details__title--margin">Описание</div>
            <div class="details__description" v-html="store.activeThumb.DETAIL_TEXT"></div>
          </div>

          <div class="details__close" @click="close">
            <svg width="22" height="22" viewBox="0 0 22 22"><path d="M12.1129 11L17.7526 4.27754C17.8471 4.16582 17.7676 3.99609 17.6215 3.99609H15.9071C15.8061 3.99609 15.7094 4.04121 15.6428 4.11855L10.9915 9.66367L6.34009 4.11855C6.27564 4.04121 6.17896 3.99609 6.07583 3.99609H4.36138C4.21528 3.99609 4.13579 4.16582 4.23032 4.27754L9.86997 11L4.23032 17.7225C4.20915 17.7474 4.19556 17.7778 4.19118 17.8102C4.1868 17.8426 4.1918 17.8756 4.2056 17.9052C4.2194 17.9348 4.24141 17.9599 4.26903 17.9774C4.29664 17.9949 4.32869 18.0041 4.36138 18.0039H6.07583C6.17681 18.0039 6.27349 17.9588 6.34009 17.8814L10.9915 12.3363L15.6428 17.8814C15.7073 17.9588 15.804 18.0039 15.9071 18.0039H17.6215C17.7676 18.0039 17.8471 17.8342 17.7526 17.7225L12.1129 11Z" fill="#404040"/></svg>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import Quantity from '../Quantity.vue'
import ButtonMobile from './ButtonMobile.vue'
import 'swiper/swiper.min.css'

import Swiper, { Pagination } from 'swiper';
Swiper.use([Pagination]);

export default {
  inject: ['store', 'mutations'],
  components: { 
    Quantity, 
    ButtonMobile,
  },
  methods: {
    addHandler() {
       if(this.mutations.currentCategory().id == 3) {
        this.addFacadeColor();
      } else if(this.mutations.currentCategory().id == 5) {
        this.addTable();
      } else if(this.mutations.currentCategory().id == 7) {
        this.addWallPanel();
      } else {
        this.mutations.addItem(this.store.activeThumb, this.store.bufferCount);
      }
    },
    addFacadeColor() {
      this.store.imgModal = 'unset';
      this.store.currentFacadeColor = this.store.activeThumb;
      this.mutations.toggleModal();
    },
    addTable() {
      this.tablesInCart.forEach(product => this.mutations.removeAll(product));
      this.mutations.addItem(this.store.activeThumb, this.store.bufferCount);
    },
    addWallPanel() {
      this.wallPanelsInCart.forEach(product => this.mutations.removeAll(product));
      this.mutations.addItem(this.store.activeThumb, this.store.bufferCount);
    },
    close() {
      this.mutations.setActiveThumb('');
      this.mutations.setActiveSidebar('');
      this.mutations.toggleCover();
    }
  },
  computed: {
    tablesInCart() {
      let cartArray = Object.values(this.store.cart);
      return cartArray.filter(product => product.categoryId == 5);
    },
    wallPanelsInCart() {
      let cartArray = Object.values(this.store.cart);
      return cartArray.filter(product => product.categoryId == 7);
    },
    inCart() {
      return Object.keys(this.store.cart).includes(this.store.activeThumb.ID);
    },
    charsNotEmpty() {
      return Object.prototype.hasOwnProperty.call(this.store.activeThumb, "CHARS") && Object.values(this.store.activeThumb.CHARS).filter(el => el != null).length > 0;
    },
    imgClass() {
      let imgClass;

      if(this.mutations.currentCategory().id == 1 || this.mutations.currentCategory().id == 2) {
        imgClass = 'details__img--special';
      } else if( this.mutations.currentCategory().id == 4 || 
        this.mutations.currentCategory().id == 8 || 
        this.mutations.currentCategory().id == 9 || 
        this.mutations.currentCategory().id == 10 || 
        this.mutations.currentCategory().id == 11 || 
        this.mutations.currentCategory().id == 12 || 
        this.mutations.currentCategory().id == 13 || 
        this.mutations.currentCategory().id == 14) {
        imgClass = 'details__img--contain';
      } else {
        imgClass = 'details__img--cover';
      }
      return imgClass;
    }
  },
  watch: {
    "store.activeSidebar": function (val) {
      if(val == 'details') {
        setTimeout(() => { 
          new Swiper('.swiper-details', {
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            }
          })
        }, 0);
      }
    }
  },
}
</script>

<style>
.swiper-details {
  margin-bottom: 8px;
}
.swiper-details .swiper-pagination {
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.swiper-details .swiper-pagination-bullet {
  display: block;
  height: 4px;
  flex: 1;
  background: #E5E5E5;
  margin: 0 8px 0 0 !important;
  border-radius: 8px;
  opacity: 1;
}

.swiper-details .swiper-pagination-bullet:last-of-type {
  margin: 0;
}

.swiper-details .swiper-pagination-bullet-active{
  background: #CC3333;
}
</style>


<style scoped>
.slide-enter-active, 
.slide-leave-active {
  transition: transform .3s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100%);
}

.details {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.details__box {
  background: #FFFFFF;
  border-radius: 30px 30px 0px 0px;
  padding-bottom: 10px;
  margin-top: 64px;
}

.details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 3px 4px 3px 16px;
}

.details__imgbox {
  position: relative;
  width: 100%;
  height: 350px;
  background: #F5F5F5;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details__img--special {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: auto;
  height: 100%;
  max-width: initial !important;
}

.details__img--cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details__img--contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.details__content {
  padding: 0 16px 0 16px;
}

.details__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  max-height: 56px;
  overflow: hidden;
  margin-top: 40px;
}

.details__title--margin {
  margin-top: 32px;
}

.details__sku {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #CC3333;
  text-transform: uppercase;
}

.details__pricebox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.details__price {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.details__char {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.details__value {
  font-weight: 600;
  margin-left: 5px;
}

.details__description {
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;
}

.details__button {
  margin-bottom: 16px;
}

.details__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

.details__remove {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 28px;
  color: #CC3333;
}

.details__remove svg {
  margin-right: 8px;
}

.details__close {
  width: 64px;
  height: 64px;
  background: #F1F1F1;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 72px auto 56px auto;
}
</style>