<template>
  <section class="success" v-if="store.step == 5">
    <div class="success__title">Оформление заказа</div>
    <div class="success__text">
      Ваш заказ <span class="success__bold">успешно создан.</span>
    </div>
    <div class="success__text success__text--last">
      В ближайшее время мы свяжемся с вами для согласования деталей. Спасибо за
      обращение!
    </div>
    <img class="success__image" src="../../assets/success.svg" />
    <a
      class="button button--red button--full button--big button--centered"
      :href="backLink"
      >Перейти на главную</a
    >
  </section>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  computed: {
    backLink () {
      return this.store.isPartner
        ? `/#/?partnerId=${this.$route.query.partnerId}`
        : '/'
    }
  }
}
</script>

<style scoped>
.success {
  background: #ffffff;
  padding: 0px 16px 64px 16px;
}

.success__title {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  margin-bottom: 24px;
  border-bottom: 1px solid #f1f1f1;
}

.success__text {
  font-size: 14px;
  line-height: 24px;
}

.success__bold {
  font-weight: 600;
}

.success__text--last {
  margin: 8px 0 32px 0;
}

.success__image {
  width: 100%;
  margin-bottom: 40px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background: white;
}

.button--full {
  width: 100%;
}

.button--centered {
  justify-content: center;
}

.button--big {
  padding: 16px;
}

.button--red {
  background: #cc3333;
  color: white;
  border: unset;
  transition: 0.3s;
}
</style>
