<template>
  <section class="success" v-if="store.step == 5">
    <div class="success__content">
      <div class="success__title">Оформление заказа</div>

      <div class="success__column">
        <div class="success__text">
          Ваш заказ <span class="success__bold">успешно создан.</span>
        </div>
        <div class="success__text success__text--last">
          В ближайшее время мы свяжемся с вами для согласования деталей. Спасибо
          за обращение!
        </div>

        <Button
          class="button button--red button--full button--big button--centered"
          ><a :href="backLink" style="color: white"
            >Перейти на главную</a
          ></Button
        >

        <div class="success__label">#1354</div>
      </div>

      <div class="success__column">
        <img class="" src="../../assets/success.svg" />
      </div>
    </div>
  </section>
</template>

<script>
import Button from './Button.vue'

export default {
  inject: ['store', 'mutations'],
  components: {
    Button
  },
  computed: {
    backLink () {
      return this.store.isPartner
        ? `/#/?partnerId=${this.$route.query.partnerId}`
        : '/'
    }
  }
}
</script>

<style scoped>
.success {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 110px 0;
}

.success__content {
  width: 960px;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin: 0 auto;
  padding: 32px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.success__title {
  width: 100%;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 32px;
}

.success__column {
  width: 424px;
}

.success__text {
  font-size: 14px;
  line-height: 24px;
}

.success__bold {
  font-weight: 600;
}

.success__text--last {
  margin: 8px 0 32px 0;
}

.success__label {
  font-weight: 600;
  font-size: 146px;
  line-height: 171px;
  color: rgba(64, 64, 64, 0.1);
  margin-top: 22px;
}
</style>
