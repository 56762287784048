<template>
  <header class="header">
    <div class="header__content">
      <a :href="backLink">
        <img class="header__logo" :src="logoUrl" />
      </a>

      <a class="header__back clicktrack" :href="`tel:${phoneNumber}`" v-if="$route.path == '/'">
        {{ styledPhoneNumber }}
      </a>
      <a class="header__back" :href="backLink" v-else> Вернуться в каталог </a>
    </div>
  </header>
</template>

<script>
export default {
  inject: ["store"],
  name: "Header",
  computed: {
    backLink() {
      return this.store.isPartner ? `/#/?partnerId=${this.$route.query.partnerId}` : "/";
    },
    logoUrl() {
      return this.store.isPartner
        ? `https://33komoda.ru/${this.store.partner.DETAIL_PICTURE}`
        : require("@/assets/logo.svg");
    },
    phoneNumber() {
      return this.store.isPartner ? this.store.partner.PHONE : "+73433511975";
    },
    styledPhoneNumber() {
      return `${this.phoneNumber.slice(0, 2)} (${this.phoneNumber.slice(2, 5)}) ${this.phoneNumber.slice(
        5,
        8
      )}-${this.phoneNumber.slice(8, 10)}-${this.phoneNumber.slice(10, 12)}`;
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  background: white;
}

.header__content {
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  height: 28px;
}

.header__back {
  font-size: 12px;
  line-height: 14px;
}
</style>
