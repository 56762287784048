<template>
  <section class="filter" v-if="store.activeSidebar == 'filter' && store.step < 4">
    <header class="filter__header">
      Фильтр
      <svg class="filter__close" @click="close" width="22" height="23" viewBox="0 0 22 23"><path d="M12.1131 11.5L17.7527 4.77754C17.8472 4.66582 17.7678 4.49609 17.6217 4.49609H15.9072C15.8062 4.49609 15.7095 4.54121 15.6429 4.61855L10.9916 10.1637L6.34021 4.61855C6.27576 4.54121 6.17908 4.49609 6.07595 4.49609H4.3615C4.21541 4.49609 4.13591 4.66582 4.23045 4.77754L9.87009 11.5L4.23045 18.2225C4.20927 18.2474 4.19568 18.2778 4.1913 18.3102C4.18692 18.3426 4.19193 18.3756 4.20572 18.4052C4.21952 18.4348 4.24154 18.4599 4.26915 18.4774C4.29676 18.4949 4.32881 18.5041 4.3615 18.5039H6.07595C6.17693 18.5039 6.27361 18.4588 6.34021 18.3814L10.9916 12.8363L15.6429 18.3814C15.7074 18.4588 15.8041 18.5039 15.9072 18.5039H17.6217C17.7678 18.5039 17.8472 18.3342 17.7527 18.2225L12.1131 11.5Z" fill="#404040"/></svg>
    </header>

    <div class="filter__block" v-if="mutations.currentCategory().id != 3">
      <div class="filter__title">Цена</div>
      <div class="range">
        <div class="range__box">
          <input class="range__input" type="number" id="1" name="price_min" :placeholder="`от ${minPrice.toLocaleString()}`" v-model="store.filtersFields.price[0]" @change="filterInputHandler">
        </div>
        <div class="range__box range__box--last">
          <input class="range__input" type="number" id="2" name="price_max" :placeholder="`до ${maxPrice.toLocaleString()}`" v-model="store.filtersFields.price[1]" @change="filterInputHandler">
        </div>
        <!-- <div class="range__slider"></div> -->
      </div>
    </div>

    <div class="filter__block" v-for="filterName, filterId in store.filters" :key="filterName">
      <div v-if="uniqueChars(filterName, filterId).length > 1">
        <div class="filter__title">{{ filterName }}</div>

        <label class="checkbox" v-for="char, ind in uniqueChars(filterName, filterId)" :key="char">
          <input type="checkbox" :id="ind" :value="char" v-model="store.filtersFields[filterId]" @click="filterInputHandler">
          <div class="checkbox__checkbox"></div>
          <div>{{ char }} <span v-if="filterName == 'Высота' || filterName == 'Ширина' || filterName == 'Глубина' || filterName == 'Толщина' ">мм</span></div>
        </label>
      </div>
    </div>


    <Button class="button button--red button--full filter__button" @click="filterButtonHandler">
      Применить
      <svg class="button__icon" width="14" height="14" viewBox="0 0 14 14"><path d="M12.469 2.59766H11.5133C11.3793 2.59766 11.2522 2.65918 11.1701 2.76445L5.53323 9.90527L2.8303 6.48047C2.78941 6.42855 2.73729 6.38658 2.67786 6.35769C2.61842 6.3288 2.55322 6.31375 2.48714 6.31367H1.53147C1.43987 6.31367 1.38928 6.41895 1.44534 6.49004L5.19006 11.2342C5.36506 11.4557 5.70139 11.4557 5.87776 11.2342L12.5551 2.77266C12.6112 2.70293 12.5606 2.59766 12.469 2.59766Z" fill="white"/></svg>
    </Button>
    <Button class="button button--bordered button--full" @click="mutations.resetProducts()">
      Очистить фильтр
      <svg class="button__icon" width="14" height="14" viewBox="0 0 14 14"><path d="M12.4291 2.86152L11.658 3.46445C10.6066 2.12051 8.97149 1.25781 7.13536 1.25781C3.96485 1.25781 1.39864 3.82129 1.39454 6.99316C1.39043 10.1678 3.96211 12.7422 7.13536 12.7422C9.61407 12.7422 11.7264 11.1699 12.5303 8.96738C12.5508 8.90996 12.5207 8.8457 12.4633 8.82656L11.6881 8.55996C11.6611 8.5507 11.6315 8.55238 11.6057 8.56464C11.5799 8.5769 11.5599 8.59878 11.55 8.62559C11.5254 8.69395 11.4981 8.7623 11.4693 8.8293C11.2328 9.38984 10.8938 9.89297 10.4617 10.325C10.0332 10.7544 9.52578 11.0969 8.96739 11.334C8.38907 11.5787 7.77247 11.7031 7.13809 11.7031C6.50235 11.7031 5.88711 11.5787 5.30879 11.334C4.74985 11.0979 4.24228 10.7552 3.81446 10.325C3.38475 9.89651 3.04252 9.3885 2.80684 8.8293C2.56211 8.24961 2.4377 7.63438 2.4377 6.99863C2.4377 6.36289 2.56211 5.74766 2.80684 5.16797C3.04336 4.60742 3.38243 4.1043 3.81446 3.67227C4.24649 3.24023 4.74961 2.90117 5.30879 2.66328C5.88711 2.41855 6.50372 2.29414 7.13809 2.29414C7.77383 2.29414 8.38907 2.41855 8.96739 2.66328C9.52633 2.89932 10.0339 3.24204 10.4617 3.67227C10.5971 3.80762 10.7242 3.95117 10.8418 4.10156L10.0188 4.74414C10.0025 4.75674 9.99007 4.77368 9.98298 4.79301C9.9759 4.81234 9.97441 4.83328 9.9787 4.85341C9.98298 4.87355 9.99286 4.89207 10.0072 4.90684C10.0215 4.92161 10.0398 4.93204 10.0598 4.93691L12.4606 5.5248C12.5289 5.54121 12.5959 5.48926 12.5959 5.41953L12.6068 2.94629C12.6055 2.85605 12.5002 2.80547 12.4291 2.86152Z" fill="#404040"/></svg>
    </Button>
  </section>
</template>

<script>
import Button from './Button.vue'

export default {
  inject: ['store', 'mutations'],
  components: { Button },
  methods: {
    close() {
      this.mutations.setActiveSidebar('order');
    },
    uniqueChars(filterName, filterId) {
      let array = this.store.productsAllFixed.map(product => product.CHARS[filterId][filterName]).filter(product =>  product != null).sort((a, b) => a - b);
      return [...new Set(array)]
    },
    filterInputHandler() {
      setTimeout(() => {
        this.mutations.filterProducts();
      }, 0);
    },
    filterButtonHandler() {
      setTimeout(() => {
        this.mutations.filterProducts();
      }, 0);
      this.store.activeSidebar = 'order';
    }
  },
  computed: {
    minPrice() {
      let array = this.store.productsAllFixed.map(product =>  Number(product.CATALOG_PRICE_4));
      return Math.min( ...array );
    },
    maxPrice() {
      let array = this.store.productsAllFixed.map(product =>  Number(product.CATALOG_PRICE_4));
      return Math.max( ...array );
    }
  }
}
</script>

<style scoped>
.filter {
  position: relative;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 32px 40px;
  position: relative;
  z-index: 1;
}

.filter__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 48px;
}

.filter__close {
  cursor: pointer;
}

.filter__block {
  margin-bottom: 32px;
}

.filter__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
}

.filter__button {
  margin-bottom: 16px;
}



.range {
  position: relative;
  margin-right: -12px;
}

.range__box {
  position: relative;
  display: inline-block;
  width: calc(50% - 12px);
  margin-right: 24px;
}

.range__box--last {
  margin-right: 0;
}

.range__input {
  width: 100%;
  height: 40px;
  background: white;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  color: #404040;
  text-align: center;
  transition: .3s;
}

.range__input:hover {
  border-color: #404040;
  transition: .3s;
}

.range__input::placeholder {
  color: rgba(64, 64, 64, 0.5);
  transition: .3s;
}

.range__item:last-of-type {
  margin-right: 0;
}

.range__slider {
  margin: 28px 0 34px 0;
}


.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
  cursor: pointer;
}

.checkbox:hover .checkbox__checkbox {
  border-color: #404040;
  transition: .3s;
}

.checkbox:last-of-type {
  margin-bottom: 0;
}

.checkbox input {
  display: none;
}

.checkbox input:checked ~ .checkbox__checkbox::after {
  visibility: visible;
  opacity: 1;
  transition: .3s;
}

.checkbox__checkbox {
  width: 32px;
  height: 32px;
  background: white;
  margin-right: 16px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.checkbox__checkbox::after {
  content: '';
  width: 10px;
  height: 10px;
  background: #CC3333;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}



.radio {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
  cursor: pointer;
}

.radio:hover .radio__checkbox {
  border-color: #404040;
  transition: .3s;
}

.radio:last-of-type {
  margin-bottom: 0;
}

.radio input {
  display: none;
}

.radio input:checked ~ .radio__checkbox::after {
  visibility: visible;
  opacity: 1;
  transition: .3s;
}

.radio__checkbox {
  width: 32px;
  height: 32px;
  background: white;
  border: 1px solid #E5E5E5;
  border-radius: 100%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.radio__checkbox::after {
  content: '';
  width: 10px;
  height: 10px;
  background: #CC3333;
  border-radius: 100%;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}
</style>