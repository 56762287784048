<template>
  <div class="pagination">
    <div class="pagination__item" v-if="store.currentPage > 1" @click="shiftPage('backward')">
      <svg width="14" height="15" viewBox="0 0 14 15"><path d="M7.75427 4.1709L4.42519 7.49999L7.75427 10.8291L8.5791 10.0042L6.07485 7.49999L8.5791 4.99574L7.75427 4.1709Z" fill="#404040"/></svg>
    </div>
    <div class="pagination__item" v-for="index in pagination(store.currentPage, pagesCount)" 
      :class="{
        'pagination__item--active': store.currentPage == index,
        'pagination__item--disabled': index === '...'
      }"
      :key="index" 
      @click="selectPage(index)"
    >
      {{ index }}
    </div>
    <div class="pagination__item" v-if="store.currentPage < pagesCount" @click="shiftPage('forward')">
      <svg width="14" height="14" viewBox="0 0 14 14"><path d="M6.24573 10.3291L9.57481 6.99998L6.24573 3.6709L5.4209 4.49573L7.92515 6.99998L5.4209 9.50423L6.24573 10.3291Z" fill="#404040"/></svg>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  props: ['productsAllCount'],
  methods: {
    selectPage(index) {
      this.scrollToSubNavigation();
      this.mutations.setCurrentPage(index);
    },
    shiftPage(direction) {
      this.scrollToSubNavigation();
      direction == 'forward' ? this.mutations.setCurrentPage(this.store.currentPage + 1) : this.mutations.setCurrentPage(this.store.currentPage - 1);
    },
    scrollToSubNavigation() {
      window.scrollTo({
        top: 630,
        left: 0,
        behavior: 'smooth'
      });
    },
    pagination(c, m) {
      var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    }
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.productsAllCount / this.store.perPage);
    },
    
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  align-items: center;
}

.pagination__item {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
}

.pagination__item--active {
  color: #CC3333;
  background: #FAEBEB;
  cursor: default;
}

.pagination__item--disabled {
  pointer-events: none;
}
</style>