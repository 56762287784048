<template>
  <div class="app app--mobile">
    <Cover v-if="kitchens == ''" />
    <HeaderMobile />

    <div class="grid">
      <div class="grid__content">
        <router-link v-for="kitchen in kitchens" :key="kitchen.id" class="grid__item" :to="kitchenLink(kitchen.ID)">
          <swiper class="grid__imgbox" :slides-per-view="1">
            <swiper-slide v-for="(pic, index) in kitchen.PICTURES" :key="index">
              <img v-lazy="`https://33komoda.ru/${pic}`" class="grid__img" />
            </swiper-slide>
          </swiper>

          <div class="grid__name">
            {{ kitchen.NAME.replace(/\..*/g, "$'") }}
          </div>
        </router-link>
      </div>
    </div>
    <FooterMobile />
    <CallMobile />
  </div>
</template>

<script setup>
import Cover from '@/components/Cover.vue'
import HeaderMobile from '@/components/mobile/HeaderMobile.vue'
import FooterMobile from '@/components/mobile/FooterMobile.vue'
import CallMobile from '@/components/mobile/CallMobile.vue'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

import { ref } from 'vue'

const kitchens = ref('')
import { store } from '@/store'

const kitchenLink = id => {
  return !store.isPartner ? `/${id}` : `${id}/description?partnerId=${store.partnerId}`
}

const getKitchens = async () => {
  try {
    const data = await fetch(`https://33komoda.ru/kitchen-planning/?do=getKitchens`)
    const response = await data.json()
    kitchens.value = response.items
  } catch (err) {
    console.log(err)
  }
}

getKitchens()
</script>

<style scoped>
.grid {
  padding: 0 16px;
}
.grid__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
}
.grid__item {
  position: relative;
  width: calc(100% / 2 - 16px);
  margin: 0 16px 24px 0;
}
.grid__imgbox {
  width: 100%;
  height: 164px;
  margin-bottom: 8px;
  border: 1px solid #e5e5e5;
  background: #f4f4f4;
  border-radius: 8px;
  overflow: hidden;
}
.grid__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid__name {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
  padding: 0 8px;
}
.grid__count {
  font-size: 12px;
  line-height: 18px;
  padding: 0 8px;
}
</style>
