<template>
  <section v-if="store.kitchen != '' && store.step < 4" class="intro">
    <div class="intro__column intro__column--first">
      <swiper
        class="intro__imgbox"
        :navigation="{
          nextEl: '.intro__arrow--next',
          prevEl: '.intro__arrow--prev'
        }"
        :slides-per-view="1"
      >
        <swiper-slide v-for="(pic, index) in store.kitchen.PICTURES" :key="index">
          <img v-lazy="`https://33komoda.ru/${pic}`" class="intro__img" />
        </swiper-slide>

        <div class="intro__arrows">
          <div class="intro__arrow intro__arrow--prev">
            <ArrowLeftSvg class="intro__icon--prev" />
          </div>
          <div class="intro__arrow intro__arrow--next">
            <ArrowRightSvg class="intro__icon--next" />
          </div>
        </div>
      </swiper>
    </div>

    <div class="intro__column">
      <div class="intro__header">
        <div class="intro__title">
          {{ store.kitchen.NAME.replace(/\..*/g, "$'") }}
        </div>
        <div class="intro__article">#{{ store.kitchen.XML_ID }}</div>
      </div>

      <Nav />
    </div>
  </section>
</template>

<script>
import Nav from './Navigation.vue'

import ArrowLeftSvg from '@/components/svg/ArrowLeftSvg.vue'
import ArrowRightSvg from '@/components/svg/ArrowRightSvg.vue'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation])

export default {
  components: {
    Nav,
    Swiper,
    SwiperSlide,
    ArrowLeftSvg,
    ArrowRightSvg
  },
  inject: ['store', 'mutations']
}
</script>

<style scoped>
.intro {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 40px auto;
  max-width: 1440px;
  padding: 0 40px;
  min-width: 1024px;
}

.intro__column {
  width: calc(50% - 20px);
  height: 470px;
}

.intro__column--first {
  border-radius: 8px;
  overflow: hidden;
}

.intro__imgbox {
  height: 100%;
}

.intro__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.intro__arrow {
  cursor: pointer;
  width: 118px;
  height: 118px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: 0.3s;
}

.intro__icon--next {
  position: relative;
  right: 20px;
}

.intro__icon--prev {
  position: relative;
  left: 20px;
}

.intro__arrow.swiper-button-disabled {
  cursor: default;
  opacity: 0.5;
  transition: 0.3s;
}

.intro__arrows {
  position: absolute;
  width: calc(100% + 118px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: -59px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.3s;
}

.intro__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 41px;
}

.intro__title {
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  height: 37px;
  overflow: hidden;
}

.intro__article {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #cc3333;
  margin-left: 24px;
}
</style>
