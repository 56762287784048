import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Maska from 'maska'
import VScrollLock from 'v-scroll-lock'
import VueLazyLoad from 'vue3-lazyload'


const app = createApp(App)
app.use(VueLazyLoad, {observer: true});
app.use(router);
app.use(Maska);
app.use(VScrollLock);
app.mount('#app')
