<template>
  <section class="modal" v-if="store.showModal" @click.self="close">

    <div class="modal__imgbox" v-if="mutations.currentCategory().id == 3 && store.imgModal != 'unset' || mutations.currentCategory().id == 5 && store.imgModal != 'unset' || mutations.currentCategory().id == 7 && store.imgModal != 'unset' || mutations.currentCategory().id == 8 && store.imgModal != 'unset' || mutations.currentCategory().id == 9 && store.imgModal != 'unset' || mutations.currentCategory().id == 10 && store.imgModal != 'unset' || mutations.currentCategory().id == 12 && store.imgModal != 'unset' || mutations.currentCategory().id == 13 && store.imgModal != 'unset' || mutations.currentCategory().id == 14 && store.imgModal != 'unset' && store.modalFinish">
      <img class="modal__img" v-if="store.imgModal != ''" :src="`https://33komoda.ru/${store.imgModal}`">
      <svg v-else height="200" viewBox="0 0 24 24" width="200" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
      <div class="modal__close" @click="close">
        <svg width="22" height="22" viewBox="0 0 22 22"><path d="M12.1131 11L17.7527 4.27754C17.8472 4.16582 17.7678 3.99609 17.6217 3.99609H15.9072C15.8062 3.99609 15.7096 4.04121 15.643 4.11855L10.9916 9.66367L6.34021 4.11855C6.27576 4.04121 6.17908 3.99609 6.07595 3.99609H4.3615C4.21541 3.99609 4.13591 4.16582 4.23045 4.27754L9.8701 11L4.23045 17.7225C4.20927 17.7474 4.19568 17.7778 4.1913 17.8102C4.18692 17.8426 4.19193 17.8756 4.20572 17.9052C4.21952 17.9348 4.24154 17.9599 4.26915 17.9774C4.29676 17.9949 4.32881 18.0041 4.3615 18.0039H6.07595C6.17693 18.0039 6.27361 17.9588 6.34021 17.8814L10.9916 12.3363L15.643 17.8814C15.7074 17.9588 15.8041 18.0039 15.9072 18.0039H17.6217C17.7678 18.0039 17.8472 17.8342 17.7527 17.7225L12.1131 11Z" fill="#404040"/></svg>
      </div>
    </div>

    <div class="modal__content" v-if="mutations.isFacadeColourStep() && store.imgModal == 'unset'">
      <div class="modal__title">Укажите фасады</div>
      <label class="radio">
        <input type="radio" v-model="facade" value="both">
        <div class="radio__checkbox"></div>
        Верхние и нижние
      </label>
      <label class="radio">
        <input type="radio" v-model="facade" value="top">
        <div class="radio__checkbox"></div>
        Только верхние
      </label>
      <label class="radio">
        <input type="radio" v-model="facade" value="bottom">
        <div class="radio__checkbox"></div>
        Только нижние
      </label>

      <Button class="button button--red button--full modal__button" @click="processFacades">
        Подтвердить
        <svg width="14" height="14" viewBox="0 0 14 14"><path d="M12.469 2.59766H11.5133C11.3793 2.59766 11.2522 2.65918 11.1701 2.76445L5.53323 9.90527L2.8303 6.48047C2.78941 6.42855 2.73729 6.38658 2.67786 6.35769C2.61842 6.3288 2.55322 6.31375 2.48714 6.31367H1.53147C1.43987 6.31367 1.38928 6.41895 1.44534 6.49004L5.19006 11.2342C5.36506 11.4557 5.70139 11.4557 5.87776 11.2342L12.5551 2.77266C12.6112 2.70293 12.5606 2.59766 12.469 2.59766Z" fill="white"/></svg>
      </Button>
      <Button class="button button--bordered button--centered button--full" @click="close">Отмена</Button>
    </div>

    <div class="modal__content" v-if="mutations.currentCategory().id == 14 && !store.modalFinish">
      <div class="modal__title">Готовы оформить?</div>
      <div class="modal__text">Поздравляем вы прошли все шаги и теперь осталось оформить заказ.</div>

      <Button class="button button--red button--full modal__button" @click="goToCheckout">
        Перейти к оформлению
        <svg width="14" height="14" viewBox="0 0 14 14"><path d="M12.469 2.59766H11.5133C11.3793 2.59766 11.2522 2.65918 11.1701 2.76445L5.53323 9.90527L2.8303 6.48047C2.78941 6.42855 2.73729 6.38658 2.67786 6.35769C2.61842 6.3288 2.55322 6.31375 2.48714 6.31367H1.53147C1.43987 6.31367 1.38928 6.41895 1.44534 6.49004L5.19006 11.2342C5.36506 11.4557 5.70139 11.4557 5.87776 11.2342L12.5551 2.77266C12.6112 2.70293 12.5606 2.59766 12.469 2.59766Z" fill="white"/></svg>
      </Button>
      <Button class="button button--bordered button--centered button--full" @click="close">Изменить заказ</Button>
    </div>
    
  </section>
</template>

<script>
import Button from './Button.vue'

export default {
  inject: ['store', 'mutations'],
  components: { Button },
  data: function () {
    return {
      facade: 'both',
      finish: false,
    }
  },
  methods: {
    goToCheckout() {
      this.mutations.toggleModal();
      this.mutations.setStep(4);
    },
    close() {
      this.mutations.toggleModal();
      this.store.imgModal = '';
    },
    addFacadeColor() {
      this.store.currentFacadeColor.facade = this.facade;
      this.mutations.addItem(this.store.currentFacadeColor, 1);
      this.close();
      this.closeDetails();
    },
    removeFacadeColor() {
      this.mutations.removeItem(this.store.currentFacadeColor, 1);
    },
    processFacades() {
      if(this.facadesInCart.length == 0) {
        console.log('add empty');
        this.addFacadeColor();
      } else if(this.hasFacadeWithValue) {
        this.mutations.removeItem(this.facadeWithValue);
        this.addFacadeColor();
        console.log('has facade/rewrite');
      } else if(this.facade == 'both') {
        this.facadesInCart.forEach(product => this.mutations.removeItem(product));
        this.addFacadeColor();
        console.log('both/clean and rewrite');
      } else if(!this.facadeWithBoth) {
        console.log('add final');
        this.addFacadeColor();
      } else {
        this.facadesInCart.forEach(product => this.mutations.removeItem(product));
        this.addFacadeColor();
      }
    },
    closeDetails() {
      this.mutations.setActiveThumb('');
      this.mutations.setActiveSidebar('order');
    }
  },
  computed: {
    facadesInCart() {
      let cartArray = Object.values(this.store.cart);
      return cartArray.filter(product => Object.prototype.hasOwnProperty.call(product, 'facade'));
    },
    hasFacadeWithValue() {
      return this.facadesInCart.filter(product => product.facade == this.facade).length > 0;
    },
    facadeWithValue() {
      return this.facadesInCart.filter(product => product.facade == this.facade)[0];
    },
    facadeWithBoth() {
      return this.facadesInCart.filter(product => product.facade == 'both').length > 0;
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 100;
  cursor: pointer;
}

.modal__imgbox {
  position: relative;
  width: 600px;
  height: 600px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.modal__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal__close {
  position: absolute;
  top: -24px;
  right: -24px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  cursor: pointer;
}

.modal__content {
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 32px 40px;
  cursor: default;
}

.modal__title {
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 32px;
}

.modal__text {
  max-width: 254px;
  font-size: 14px;
  line-height: 21px;
}

.modal__button {
  margin: 32px 0 16px 0;
}


.radio {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
  cursor: pointer;
}

.radio:hover .radio__checkbox {
  border-color: #404040;
  transition: .3s;
}

.radio:last-of-type {
  margin-bottom: 0;
}

.radio input {
  display: none;
}

.radio input:checked ~ .radio__checkbox::after {
  visibility: visible;
  opacity: 1;
  transition: .3s;
}

.radio__checkbox {
  width: 32px;
  height: 32px;
  background: white;
  border: 1px solid #E5E5E5;
  border-radius: 100%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.radio__checkbox::after {
  content: '';
  width: 10px;
  height: 10px;
  background: #CC3333;
  border-radius: 100%;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}
</style>