<template>
  <div class="pagination">
    <div class="pagination__item" v-if="store.currentPage > 1" @click="shiftPage('backward')">
      <svg width="16" height="16" viewBox="0 0 24 24"><path d="M6.0539 12.5906L16.6195 20.843C16.6471 20.8647 16.6803 20.8782 16.7153 20.8819C16.7502 20.8857 16.7855 20.8795 16.8171 20.864C16.8486 20.8486 16.8752 20.8246 16.8938 20.7948C16.9123 20.7649 16.922 20.7304 16.9219 20.6953V18.8836C16.9219 18.7688 16.868 18.6586 16.7789 18.5883L8.3414 12L16.7789 5.41172C16.8703 5.34141 16.9219 5.23125 16.9219 5.11641V3.30469C16.9219 3.14766 16.7414 3.06094 16.6195 3.15703L6.0539 11.4094C5.9641 11.4794 5.89146 11.569 5.84149 11.6714C5.79153 11.7737 5.76555 11.8861 5.76555 12C5.76555 12.1139 5.79153 12.2263 5.84149 12.3286C5.89146 12.431 5.9641 12.5206 6.0539 12.5906Z" fill="#404040"/></svg>
    </div>
    <div class="pagination__item" v-for="index in pagination(store.currentPage, pagesCount)" 
      :class="{
        'pagination__item--active': store.currentPage == index,
        'pagination__item--disabled': index === '...'
      }"
      :key="index" 
      @click="selectPage(index)"
    >
      {{ index }}
    </div>
    <div class="pagination__item" v-if="store.currentPage < pagesCount" @click="shiftPage('forward')">
      <svg width="16" height="16" viewBox="0 0 16 16"><path d="M11.9641 7.60637L4.92031 2.10481C4.90191 2.09032 4.87979 2.08131 4.8565 2.07883C4.8332 2.07634 4.80968 2.08048 4.78863 2.09076C4.76758 2.10104 4.74986 2.11704 4.7375 2.13694C4.72514 2.15684 4.71864 2.17982 4.71875 2.20325V3.41106C4.71875 3.48762 4.75469 3.56106 4.81406 3.60793L10.4391 8.00012L4.81406 12.3923C4.75313 12.4392 4.71875 12.5126 4.71875 12.5892V13.797C4.71875 13.9017 4.83906 13.9595 4.92031 13.8954L11.9641 8.39387C12.0239 8.34717 12.0724 8.28744 12.1057 8.21921C12.139 8.15098 12.1563 8.07605 12.1563 8.00012C12.1563 7.92419 12.139 7.84927 12.1057 7.78104C12.0724 7.71281 12.0239 7.65307 11.9641 7.60637Z" fill="#404040"/></svg>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  props: ['productsAllCount'],
  methods: {
    selectPage(index) {
      this.mutations.setCurrentPage(index);
    },
    shiftPage(direction) {
      direction == 'forward' ? this.mutations.setCurrentPage(this.store.currentPage + 1) : this.mutations.setCurrentPage(this.store.currentPage - 1);
    },
    pagination(c, m) {
      var current = c,
        last = m,
        delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i >= left && i < right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return range;
    }
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.productsAllCount / this.store.perPage);
    }
  }
}
</script>

<style scoped>
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__item {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 16px;
}

.pagination__item--active {
  color: #CC3333;
  background: #FAEBEB;
  cursor: default;
}

.pagination__item--disabled {
  pointer-events: none;
}
</style>