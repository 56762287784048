<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
    <rect width="34" height="34" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)" fill="transparent" />
    <path
      d="M20.9188 10.2778L13.3633 18.7778L20.9188 27.2778"
      stroke="#404040"
      stroke-width="1.02778"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
