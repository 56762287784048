<template>
  <section class="subnavigation" v-if="store.step < 4">
    <div class="subnavigation__content">
      <div class="subnavigation__item" v-for="(subcategory, index) in subcategories" 
        :key="index"
        :class="{
          'subnavigation__item--long': store.step == 1,
          'subnavigation__item--skiped': (!mutations.inCartSubStep(index + 1) && store.step < store.maxStep) || (!mutations.inCartSubStep(index + 1) && index < store.maxSubStep),
          'subnavigation__item--active': store.subStep == index + 1,
          'subnavigation__item--selected': mutations.inCartSubStep(index + 1) || (subcategory.id == 6 && mutations.hasChoosenTables()),
          'subnavigation__item--clickable': (store.maxStep > store.step) || (store.maxSubStep > index),
        }"
        @click="goToSubStep(index + 1, subcategory)"
      >
        <svg class="subnavigation__tail" width="12" height="80" viewBox="0 0 12 80" fill="white"><path d="M24 40V0H0L24 40Z"/><path d="M24 40V80H0L24 40Z"/></svg>
        {{ subcategory.name }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  computed: {
    subcategories() {
      return this.store.subcategories[this.store.step - 1];
    }
  },
  methods: {
    goToSubStep(subStep) {
      this.mutations.setSubStep(subStep);
      this.mutations.getProducts(this.store.kitchenId);
      this.closeDetails();
      this.mutations.setCurrentPage(1);
    },
    closeDetails() {
      this.mutations.setActiveThumb('');
      this.mutations.setActiveSidebar('order');
    }
  }
}
</script>

<style scoped>
.subnavigation {
  max-width: 1440px;
  padding: 0 40px;
  margin-bottom: 40px;
  margin: 0 auto 40px auto;
  min-width: 1024px;
}

.subnavigation__content {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.subnavigation__item {
  position: relative;
  width: 20%;
  height: 80px;
  background: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.subnavigation__item:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.subnavigation__item:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.subnavigation__item:after {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 24px solid white;
  top: 0;
  right: -12px;
  z-index: 2;
}

.subnavigation__tail {
  position: absolute;
  top: 0;
  left: -12px;
}

.subnavigation__item--skiped {
  background:#E5E5E5;
}

.subnavigation__item--skiped:after {
  border-left-color:#E5E5E5;
}

.subnavigation__item--skiped .subnavigation__tail {
  fill: #E5E5E5;
}

.subnavigation__item--selected {
  background:#F2F9E5;
  color: #80C300;
}

.subnavigation__item--selected:after {
  border-left-color:#F2F9E5;
}

.subnavigation__item--selected .subnavigation__tail {
  fill: #F2F9E5;
}

.subnavigation__item--active {
  background:#CC3333;
  pointer-events: none !important;
  color: white;
}

.subnavigation__item--active:after {
  border-left-color:#CC3333;
}

.subnavigation__item--active .subnavigation__tail {
  fill: #CC3333;
}

.subnavigation__item--clickable {
  cursor: pointer;
  pointer-events: unset;
}

.subnavigation__item--long {
  width: 100%;
}
</style>