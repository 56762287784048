<template>
  <div class="thumb">
    <div class="thumb__label" v-if="inCart" @click="mutations.removeAll(product)">
      <svg class="thumb__icon" width="14" height="14" viewBox="0 0 14 14"><path d="M12.469 2.59766H11.5133C11.3793 2.59766 11.2522 2.65918 11.1701 2.76445L5.53323 9.90527L2.8303 6.48047C2.78941 6.42855 2.73729 6.38658 2.67786 6.35769C2.61842 6.3288 2.55322 6.31375 2.48714 6.31367H1.53147C1.43987 6.31367 1.38928 6.41895 1.44534 6.49004L5.19006 11.2342C5.36506 11.4557 5.70139 11.4557 5.87776 11.2342L12.5551 2.77266C12.6112 2.70293 12.5606 2.59766 12.469 2.59766Z" fill="white"/></svg>
    </div>
    <div class="thumb__imgbox" @click="toggleActiveThumb">
      <img class="thumb__img" :class="imgClass" v-if="product.PREVIEW_PICTURE" v-lazy="`https://33komoda.ru${product.PREVIEW_PICTURE}`">
      <svg v-else height="72" viewBox="0 0 24 24" width="72" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
    </div>
    <div class="thumb__content">
      <div class="thumb__price">{{ Number(product.CATALOG_PRICE_4).toLocaleString() }} ₽</div>
      <div class="thumb__title">{{ product.NAME_CUSTOM }}</div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['store', 'mutations'],
  props: ['product'],
  methods: {
    toggleActiveThumb() {
      if(this.product.ID == this.store.activeThumb.ID) {
        this.mutations.setActiveThumb('');
        this.mutations.setActiveSidebar('order');
      } else {
        this.mutations.setActiveThumb(this.product);
        this.mutations.setActiveSidebar('details');
      }
      this.mutations.toggleCover();
    }
  },
  computed: {
    inCart() {
      return Object.keys(this.store.cart).includes(this.product.ID);
    },
    imgClass() {
      let imgClass;

      if(this.mutations.currentCategory().id == 1 || this.mutations.currentCategory().id == 2) {
        imgClass = 'thumb__img--special';
      } else if( this.mutations.currentCategory().id == 4 || 
        this.mutations.currentCategory().id == 8 || 
        this.mutations.currentCategory().id == 9 || 
        this.mutations.currentCategory().id == 10 || 
        this.mutations.currentCategory().id == 11 || 
        this.mutations.currentCategory().id == 12 || 
        this.mutations.currentCategory().id == 13 || 
        this.mutations.currentCategory().id == 14) {
        imgClass = 'thumb__img--contain';
      } else {
        imgClass = 'thumb__img--cover';
      }
      return imgClass;
    }
  }
}
</script>

<style scoped>
.thumb {
  position: relative;
  margin: 0 16px 24px 0;
  width: calc(100%/2 - 16px);
  overflow: hidden;
  border-radius: 8px;
}

.thumb__imgbox {
  position: relative;
  border: 1px solid #E5E5E5;
  background: #F5F5F5;
  border-radius: 8px;
  height: 216px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumb__img--special {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: auto;
  height: 100%;
  max-width: initial !important;
}

.thumb__img--cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumb__img--contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.thumb__label {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 48px solid #CC3333;
  border-left: 48px solid transparent;
  cursor: pointer;
  transition: .3s;
}

.thumb__icon {
  position: absolute;
  right: 6px;
  top: -40px;
}

.thumb__content {
  padding: 8px 8px 0 8px;
}

.thumb__price {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.thumb__title {
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  max-height: 36px;
  overflow: hidden;
}
</style>