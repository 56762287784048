<template>
  <section class="checkout" v-if="store.step == 4">
    <transition name="fade" mode="out-in">
      <div class="checkout__cover" v-if="showCover">
        <div class="lds-ring lds-ring--active"><div></div><div></div><div></div><div></div></div>
      </div>
    </transition>

    <div class="checkout__title">Оформление заказа</div>
    <div class="checkout__column">
      <div class="checkout__kitchen">
        <div class="checkout__imgbox">
          <img class="checkout__img" :src="`https://33komoda.ru${store.kitchen.PREVIEW_PICTURE}`"/>
        </div>
        <div class="checkout__content">
          <div class="checkout__name">{{ store.kitchen.NAME }}</div>
          <div class="checkout__sku">#{{ store.kitchen.XML_ID }}</div>
          <div>Выбрано {{ totalProductsCount }} {{ totalWord }}</div>
        </div>
      </div>

      <div class="checkout__subtitle">Корзина</div>


      <div class="product" v-for="product, index in mutations.facades()" :key="index">
        <div class="product__imgbox" @click="goToCategory(product.step, product.subStep)">
          <img class="product__img" v-if="product.PREVIEW_PICTURE" :src="`https://33komoda.ru${product.PREVIEW_PICTURE}`">
          <svg v-else height="48" viewBox="0 0 24 24" width="48" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
        </div>

        <div class="product__content">
          <div class="product__text">
            <div class="product__name" @click="goToCategory(product.step, product.subStep)">
              <span v-if="product.facade == 'top'">Верхние фасады</span>
              <span v-if="product.facade == 'bottom'">Нижние фасады</span>
              <span v-if="product.facade == 'both'">Верхние и нижние фасады</span>
            </div>
            <div class="product__subname">{{ product.NAME_CUSTOM }}</div>
          </div>             

          <div class="product__buttons">
            <Quantity class="product__quantity" v-if="product.COUNT > 1" :product="product"/>
            <button class="product__button" @click="mutations.removeAll(product)">
              <svg width="14" height="14" viewBox="0 0 14 14"><path d="M4.92188 2.51562H4.8125C4.87266 2.51562 4.92188 2.46641 4.92188 2.40625V2.51562H9.07812V2.40625C9.07812 2.46641 9.12734 2.51562 9.1875 2.51562H9.07812V3.5H10.0625V2.40625C10.0625 1.92363 9.67012 1.53125 9.1875 1.53125H4.8125C4.32988 1.53125 3.9375 1.92363 3.9375 2.40625V3.5H4.92188V2.51562ZM11.8125 3.5H2.1875C1.94551 3.5 1.75 3.69551 1.75 3.9375V4.375C1.75 4.43516 1.79922 4.48438 1.85938 4.48438H2.68516L3.02285 11.6348C3.04473 12.101 3.43027 12.4688 3.89648 12.4688H10.1035C10.5711 12.4688 10.9553 12.1023 10.9771 11.6348L11.3148 4.48438H12.1406C12.2008 4.48438 12.25 4.43516 12.25 4.375V3.9375C12.25 3.69551 12.0545 3.5 11.8125 3.5ZM9.99824 11.4844H4.00176L3.6709 4.48438H10.3291L9.99824 11.4844Z" fill="#404040" fill-opacity="0.5"/></svg>
              Удалить
            </button>
          </div>
        </div>
      </div>

      <div v-for="category in categories" :key="category.id">
        <div v-if="inCart(category.id)">
          <div v-for="product in mutations.cartArray()" :key="product.ID">

            <div class="product" v-if="isSameCategory(product, category) && !isTable(product) && !isFacade(product)">
              <div class="product__imgbox" @click="goToCategory(category.step, category.subStep)">
                <img class="product__img" v-if="product.PREVIEW_PICTURE" :src="`https://33komoda.ru/${product.PREVIEW_PICTURE}`">
                <svg v-else height="48" viewBox="0 0 24 24" width="48" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
              </div>

              <div class="product__content">
                <div class="product__text">   
                  <div class="product__name">{{ product.NAME_CUSTOM }}</div>
                  <div><span v-if="product.COUNT > 1">{{ product.COUNT }} x</span> {{ Number(product.CATALOG_PRICE_4).toLocaleString() }} ₽</div>
                </div>
                <div class="product__buttons">
                  <Quantity class="product__quantity" v-if="product.COUNT > 1" :product="product"/>
                  <button class="product__button" @click="mutations.removeAll(product)">
                    <svg width="14" height="14" viewBox="0 0 14 14"><path d="M4.92188 2.51562H4.8125C4.87266 2.51562 4.92188 2.46641 4.92188 2.40625V2.51562H9.07812V2.40625C9.07812 2.46641 9.12734 2.51562 9.1875 2.51562H9.07812V3.5H10.0625V2.40625C10.0625 1.92363 9.67012 1.53125 9.1875 1.53125H4.8125C4.32988 1.53125 3.9375 1.92363 3.9375 2.40625V3.5H4.92188V2.51562ZM11.8125 3.5H2.1875C1.94551 3.5 1.75 3.69551 1.75 3.9375V4.375C1.75 4.43516 1.79922 4.48438 1.85938 4.48438H2.68516L3.02285 11.6348C3.04473 12.101 3.43027 12.4688 3.89648 12.4688H10.1035C10.5711 12.4688 10.9553 12.1023 10.9771 11.6348L11.3148 4.48438H12.1406C12.2008 4.48438 12.25 4.43516 12.25 4.375V3.9375C12.25 3.69551 12.0545 3.5 11.8125 3.5ZM9.99824 11.4844H4.00176L3.6709 4.48438H10.3291L9.99824 11.4844Z" fill="#404040" fill-opacity="0.5"/></svg>
                    Удалить
                  </button>
                </div>
              </div>
            </div>



            <div v-else-if="isSameCategory(product, category) && isTable(product)">
              <div class="product" v-for="product, index in product.subproducts" :key="index">
                <div class="product__imgbox" @click="goToCategory(store.cart[product.id].step, store.cart[product.id].subStep)">
                  <img class="product__img" v-if="store.cart[product.id].PREVIEW_PICTURE" :src="`https://33komoda.ru${store.cart[product.id].PREVIEW_PICTURE}`">
                  <svg v-else height="48" viewBox="0 0 24 24" width="48" fill="#E5E5E5"><path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>
                </div>

                <div class="product__content"> 
                  <div class="product__text"> 
                    <div class="product__name">Столешница №{{ index + 1 }}</div>
                    <div class="product__name" v-if="product.form == '1'">Прямая</div>
                    <div class="product__name" v-if="product.form == '2'">Левый радиус</div>
                    <div class="product__name" v-if="product.form == '3'">Правый радиус</div>
                    <div class="product__name" v-if="product.form == '4'">Левый скос</div>
                    <div class="product__name" v-if="product.form == '5'">Правый скос</div>
                    <div v-if="product.length == null">{{ store.cart[product.id].NAME_CUSTOM }}</div>
                    <div v-else>{{ product.length.toLocaleString() }}мм / {{ product.price.toLocaleString()}} ₽</div>
                  </div>     
                  <div class="product__buttons">
                    <button class="product__button" @click="mutations.removeTable(store.cart[product.id]);">
                      <svg width="14" height="14" viewBox="0 0 14 14"><path d="M4.92188 2.51562H4.8125C4.87266 2.51562 4.92188 2.46641 4.92188 2.40625V2.51562H9.07812V2.40625C9.07812 2.46641 9.12734 2.51562 9.1875 2.51562H9.07812V3.5H10.0625V2.40625C10.0625 1.92363 9.67012 1.53125 9.1875 1.53125H4.8125C4.32988 1.53125 3.9375 1.92363 3.9375 2.40625V3.5H4.92188V2.51562ZM11.8125 3.5H2.1875C1.94551 3.5 1.75 3.69551 1.75 3.9375V4.375C1.75 4.43516 1.79922 4.48438 1.85938 4.48438H2.68516L3.02285 11.6348C3.04473 12.101 3.43027 12.4688 3.89648 12.4688H10.1035C10.5711 12.4688 10.9553 12.1023 10.9771 11.6348L11.3148 4.48438H12.1406C12.2008 4.48438 12.25 4.43516 12.25 4.375V3.9375C12.25 3.69551 12.0545 3.5 11.8125 3.5ZM9.99824 11.4844H4.00176L3.6709 4.48438H10.3291L9.99824 11.4844Z" fill="#404040" fill-opacity="0.5"/></svg>
                      Удалить
                    </button>
                  </div>         
                </div>         
              </div>
            </div>

          </div>
        </div>

        <div v-else>
          <div class="product" v-if="(category.step < store.maxStep) || (category.subStep <= store.maxSubStep && category.step == store.maxStep)" @click="goToCategory(category.step, category.subStep)">
            <div class="product__imgbox">
              <div class="product__close">
                <svg width="22" height="22" viewBox="0 0 22 22"><path d="M12.1131 11L17.7527 4.27754C17.8472 4.16582 17.7678 3.99609 17.6217 3.99609H15.9072C15.8062 3.99609 15.7096 4.04121 15.643 4.11855L10.9916 9.66367L6.34021 4.11855C6.27576 4.04121 6.17908 3.99609 6.07595 3.99609H4.3615C4.21541 3.99609 4.13591 4.16582 4.23045 4.27754L9.8701 11L4.23045 17.7225C4.20927 17.7474 4.19568 17.7778 4.1913 17.8102C4.18692 17.8426 4.19193 17.8756 4.20572 17.9052C4.21952 17.9348 4.24154 17.9599 4.26915 17.9774C4.29676 17.9949 4.32881 18.0041 4.3615 18.0039H6.07595C6.17693 18.0039 6.27361 17.9588 6.34021 17.8814L10.9916 12.3363L15.643 17.8814C15.7074 17.9588 15.8041 18.0039 15.9072 18.0039H17.6217C17.7678 18.0039 17.8472 17.8342 17.7527 17.7225L12.1131 11Z" fill="#404040"/></svg>
              </div>
            </div>
            <div class="product__content">
              <div class="product__text">
                <div class="product__name">Без товаров из раздела</div>
                <div class="product__subname">{{ category.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="checkout__summ">Итого: {{ mutations.totalPrice() }} ₽</div>
    </div>

    <div class="checkout__column">
      <div class="checkout__subtitle">Покупатель</div>
      <div class="checkout__inputbox">
        <input class="checkout__input" :class="{'checkout__input--filled': name != '', 'checkout__input--alert': name == '' && validationFailed}" placeholder="Ваше имя *" v-model="name">
        <svg class="checkout__icon" width="16" height="16" viewBox="0 0 16 16" fill="rgba(64, 64, 64, 0.5)" ><path d="M13.4141 11.9312C13.1194 11.2331 12.6916 10.5989 12.1547 10.0641C11.6195 9.52767 10.9854 9.10001 10.2875 8.80469C10.2813 8.80156 10.275 8.8 10.2688 8.79688C11.2422 8.09375 11.875 6.94844 11.875 5.65625C11.875 3.51562 10.1407 1.78125 8.00004 1.78125C5.85941 1.78125 4.12504 3.51562 4.12504 5.65625C4.12504 6.94844 4.75785 8.09375 5.73129 8.79844C5.72504 8.80156 5.71879 8.80312 5.71254 8.80625C5.01254 9.10156 4.38441 9.525 3.84535 10.0656C3.30896 10.6009 2.8813 11.235 2.58598 11.9328C2.29585 12.616 2.13939 13.3485 2.12504 14.0906C2.12462 14.1073 2.12755 14.1239 2.13364 14.1394C2.13974 14.1549 2.14888 14.1691 2.16053 14.181C2.17218 14.193 2.1861 14.2025 2.20147 14.2089C2.21684 14.2154 2.23336 14.2188 2.25004 14.2188H3.18754C3.25629 14.2188 3.31098 14.1641 3.31254 14.0969C3.34379 12.8906 3.82816 11.7609 4.68441 10.9047C5.57035 10.0188 6.74691 9.53125 8.00004 9.53125C9.25316 9.53125 10.4297 10.0188 11.3157 10.9047C12.1719 11.7609 12.6563 12.8906 12.6875 14.0969C12.6891 14.1656 12.7438 14.2188 12.8125 14.2188H13.75C13.7667 14.2188 13.7832 14.2154 13.7986 14.2089C13.814 14.2025 13.8279 14.193 13.8396 14.181C13.8512 14.1691 13.8603 14.1549 13.8664 14.1394C13.8725 14.1239 13.8755 14.1073 13.875 14.0906C13.8594 13.3438 13.7047 12.6172 13.4141 11.9312ZM8.00004 8.34375C7.28285 8.34375 6.60785 8.06406 6.10004 7.55625C5.59223 7.04844 5.31254 6.37344 5.31254 5.65625C5.31254 4.93906 5.59223 4.26406 6.10004 3.75625C6.60785 3.24844 7.28285 2.96875 8.00004 2.96875C8.71723 2.96875 9.39223 3.24844 9.90004 3.75625C10.4079 4.26406 10.6875 4.93906 10.6875 5.65625C10.6875 6.37344 10.4079 7.04844 9.90004 7.55625C9.39223 8.06406 8.71723 8.34375 8.00004 8.34375Z"/></svg>
      </div>
      <div class="checkout__alert" v-if="name == '' && validationFailed">Укажите ваше имя</div>
      <div class="checkout__inputbox">
        <input class="checkout__input" :class="{'checkout__input--filled': tel != '', 'checkout__input--alert': tel.length != 18 && validationFailed}" placeholder="Контактный телефон *" type="text" v-model="tel" v-maska="'+7 (###) ###-##-##'" @maska="editMask">
        <svg class="checkout__icon" width="14" height="14" viewBox="0 0 14 14" fill="rgba(64, 64, 64, 0.5)"><path d="M12.7047 2.72969L11.0406 1.06719C10.8375 0.864063 10.5656 0.75 10.2781 0.75C9.99062 0.75 9.71875 0.8625 9.51562 1.06719L7.72344 2.85625C7.52031 3.05938 7.40625 3.33281 7.40625 3.62031C7.40625 3.90938 7.51875 4.17969 7.72344 4.38438L9.12344 5.78594C8.80451 6.52904 8.34626 7.20421 7.77344 7.775C7.2 8.35156 6.52969 8.80625 5.78594 9.12813L4.38594 7.72656C4.18281 7.52344 3.91094 7.40938 3.62344 7.40938C3.48163 7.40885 3.34114 7.43663 3.21021 7.4911C3.07928 7.54557 2.96054 7.62562 2.86094 7.72656L1.06719 9.51562C0.864063 9.71875 0.75 9.99219 0.75 10.2797C0.75 10.5688 0.8625 10.8391 1.06719 11.0437L2.72969 12.7063C3.07656 13.0531 3.55469 13.2516 4.04531 13.2516C4.14687 13.2516 4.24531 13.2437 4.34531 13.2266C6.41406 12.8859 8.46719 11.7844 10.125 10.1281C11.7812 8.46875 12.8813 6.41563 13.225 4.34531C13.3234 3.75781 13.1266 3.15313 12.7047 2.72969ZM12.1172 4.15938C11.8125 6.00156 10.8219 7.83906 9.32969 9.33125C7.8375 10.8234 6.00156 11.8141 4.15938 12.1187C3.92813 12.1578 3.69062 12.0797 3.52187 11.9125L1.88906 10.2797L3.62031 8.54688L5.49219 10.4219L5.50625 10.4359L5.84375 10.3109C6.86707 9.9347 7.79635 9.34044 8.56717 8.56934C9.338 7.79825 9.93193 6.86877 10.3078 5.84531L10.4328 5.50781L8.54531 3.62187L10.2766 1.88906L11.9094 3.52187C12.0781 3.69062 12.1562 3.92813 12.1172 4.15938Z"/></svg>
      </div>
      <div class="checkout__alert" v-if="tel.length != 18 && validationFailed">Укажите ваш телефон</div>
      <div class="checkout__inputbox">
        <input class="checkout__input" :class="{'checkout__input--filled': email != ''}" placeholder="E-mail" v-model="email">
        <svg class="checkout__icon" width="16" height="16" viewBox="0 0 16 16" fill="rgba(64, 64, 64, 0.5)"><path d="M14.5 2.5H1.5C1.22344 2.5 1 2.72344 1 3V13C1 13.2766 1.22344 13.5 1.5 13.5H14.5C14.7766 13.5 15 13.2766 15 13V3C15 2.72344 14.7766 2.5 14.5 2.5ZM13.875 4.23125V12.375H2.125V4.23125L1.69375 3.89531L2.30781 3.10625L2.97656 3.62656H13.025L13.6938 3.10625L14.3078 3.89531L13.875 4.23125ZM13.025 3.625L8 7.53125L2.975 3.625L2.30625 3.10469L1.69219 3.89375L2.12344 4.22969L7.46094 8.37969C7.61444 8.49894 7.80328 8.56367 7.99766 8.56367C8.19203 8.56367 8.38088 8.49894 8.53438 8.37969L13.875 4.23125L14.3062 3.89531L13.6922 3.10625L13.025 3.625Z"/></svg>
      </div>
      <textarea class="checkout__textarea" placeholder="Комментарий" name="textarea" rows="4" cols="50" v-model="comments"></textarea>

      <div>
        <label class="checkout__filelabel" for="file" v-if="file == ''">
          <svg width="32" height="32" viewBox="0 0 24 24" fill="rgba(64, 64, 64, 0.5)"><path d="M12.1477 10.7575C12.1302 10.7351 12.1078 10.717 12.0822 10.7045C12.0566 10.692 12.0285 10.6855 12.0001 10.6855C11.9716 10.6855 11.9435 10.692 11.9179 10.7045C11.8923 10.717 11.8699 10.7351 11.8524 10.7575L9.2274 14.0786C9.20576 14.1062 9.19233 14.1394 9.18865 14.1743C9.18497 14.2092 9.19119 14.2444 9.20659 14.276C9.222 14.3075 9.24596 14.3341 9.27575 14.3526C9.30554 14.3712 9.33996 14.381 9.37506 14.3809H11.1071V20.0622C11.1071 20.1653 11.1915 20.2497 11.2946 20.2497H12.7008C12.804 20.2497 12.8883 20.1653 12.8883 20.0622V14.3833H14.6251C14.7821 14.3833 14.8688 14.2028 14.7727 14.0809L12.1477 10.7575Z"/><path d="M19.0172 8.59453C17.9438 5.76328 15.2086 3.75 12.0047 3.75C8.80078 3.75 6.06563 5.76094 4.99219 8.59219C2.98359 9.11953 1.5 10.95 1.5 13.125C1.5 15.7148 3.59766 17.8125 6.18516 17.8125H7.125C7.22813 17.8125 7.3125 17.7281 7.3125 17.625V16.2188C7.3125 16.1156 7.22813 16.0312 7.125 16.0312H6.18516C5.39531 16.0312 4.65234 15.7172 4.09922 15.1477C3.54844 14.5805 3.25547 13.8164 3.28125 13.0242C3.30234 12.4055 3.51328 11.8242 3.89531 11.3344C4.28672 10.8352 4.83516 10.4719 5.44453 10.3102L6.33281 10.0781L6.65859 9.22031C6.86016 8.68594 7.14141 8.18672 7.49531 7.73438C7.8447 7.28603 8.25857 6.89191 8.72344 6.56484C9.68672 5.8875 10.8211 5.52891 12.0047 5.52891C13.1883 5.52891 14.3227 5.8875 15.2859 6.56484C15.7523 6.89297 16.1648 7.28672 16.5141 7.73438C16.868 8.18672 17.1492 8.68828 17.3508 9.22031L17.6742 10.0758L18.5602 10.3102C19.8305 10.6523 20.7188 11.8078 20.7188 13.125C20.7188 13.9008 20.4164 14.632 19.868 15.1805C19.599 15.451 19.2791 15.6655 18.9266 15.8115C18.5742 15.9576 18.1963 16.0323 17.8148 16.0312H16.875C16.7719 16.0312 16.6875 16.1156 16.6875 16.2188V17.625C16.6875 17.7281 16.7719 17.8125 16.875 17.8125H17.8148C20.4023 17.8125 22.5 15.7148 22.5 13.125C22.5 10.9523 21.0211 9.12422 19.0172 8.59453Z"/></svg>
          <div class="checkout__filebold">Прикрепить файл</div>
          <div>Например, схема или чертеж вашей кухни</div>
        </label>
        <label class="checkout__filelabel" for="file" v-else>
          <svg width="32" height="32" viewBox="0 0 14 14" fill="#CC3333"><path d="M12.4687 2.59766H11.5131C11.3791 2.59766 11.2519 2.65918 11.1699 2.76445L5.53298 9.90527L2.83006 6.48047C2.78917 6.42855 2.73705 6.38658 2.67761 6.35769C2.61818 6.3288 2.55297 6.31375 2.48689 6.31367H1.53123C1.43963 6.31367 1.38904 6.41895 1.44509 6.49004L5.18982 11.2342C5.36482 11.4557 5.70115 11.4557 5.87752 11.2342L12.5549 2.77266C12.6109 2.70293 12.5603 2.59766 12.4687 2.59766Z"/></svg>
          <div class="checkout__filebold">Документ успешно загружен</div>
        </label>
        <input class="checkout__file" type="file" id="file" ref="myfile" @change="previewFiles">
      </div>

      <div class="checkout__note" v-if="!store.isPartner">Нажимая на кнопку, вы соглашаетесь с <a class="checkout__link" target="_blank" href="/polzovatelskoe-soglashenie/">политикой обработки персональных данных</a></div>

      <Button class="button button--red button--full button--centered" @click="validate">Оформить заказ</Button>
      <div class="checkout__button" @click="goToStart">Начать с начала</div>
    </div>
  </section>
</template>

<script>
import Quantity from '../Quantity.vue'
import Button from './Button.vue'


export default {
  inject: ['store', 'mutations'],
  components: { Quantity, Button },
  data: function () {
    return {
      showCover: false,
      name: '',
      tel: '',
      email: '',
      comments: '',
      validationFailed: false,
      file: ''
    }
  },
  methods: {
    editMask() {
      if(this.tel[4] == 8) this.tel = '+7';
    },
    previewFiles() {
      this.file = this.$refs.myfile.files[0]
    },
    inCart(id) {
      let array = Object.values(this.store.cart);
      return array.filter(product => product.categoryId == id).length > 0;
    },
    isTable(product) {
      return product.categoryId == 5;
    },
    isFacade(product) {
      return product.categoryId == 3;
    },
    isSameCategory(product, category) {
      return product.categoryId == category.id;
    },
    goToCategory(step, subStep) {
      this.mutations.setStep(step);
      this.mutations.setSubStep(subStep);
      this.mutations.getProducts(this.store.kitchenId);
    },
    removeTable(product) {
      this.mutations.removeTable(this.store.cart[product.id]);
      this.mutations.removeItem(this.store.cart[product.id]);
    },
    goToStart() {
      this.store.step = 1;
      this.store.subStep = 1;
    },
    validate() {
      if(this.name == '' || this.tel == '') {
        this.validationFailed = true;
      } else {
        // this.sendGoal();
        this.submit();
      }
    },
    sendGoal() {
      window.ym(38236335,'reachGoal','zakaz_modul');
      console.log("Цель отправлена 38236335: zakaz_modul");
    },
    submit() {
      this.showCover = true;

      let cartObject = {};
      if(this.name) cartObject.name = this.name;
      if(this.tel) cartObject.tel = this.tel;
      if(this.email) cartObject.email = this.email;
      if(this.store.isPartner) cartObject.partnerEmail = this.store.partner.EMAIL;
      if(this.comments) cartObject.comments = this.comments;
      cartObject.productName = this.store.kitchen.NAME;
      cartObject.city = this.city;
      cartObject.shop = this.shop;
      cartObject.totalPrice = this.mutations.totalPrice();
      cartObject.totalProductPositions = this.totalPositionsCount;
      cartObject.totalProducts = this.totalProductsCount;

      cartObject.cart = this.store.cart;
      console.log(cartObject);
      let formData = new FormData();
      formData.append('data', JSON.stringify(cartObject));
      formData.append('file', this.file);

      
      fetch(`https://33komoda.ru/kitchen-planning/send_order.php`, {
        method: 'POST',
        body: formData
      })
      .then((res) => res)
      .then(() => {
        this.mutations.setStep(5);
        this.showCover = false;
      })
    }
  },
  computed: {
    urlParams() {
      let url = new URL(location.href);
      let params = new URLSearchParams(url.search);
      return params;
    },
    city() {
      return this.urlParams.get('city');
    },
    shop() {
      return this.urlParams.get('shop');
    },
    categories() {
      return this.store.subcategories.flat().filter(category => category.id != 6);
    },
    totalPositionsCount() {
      return Object.values(this.store.cart).length;
    },
    totalProductsCount() {
      return Object.values(this.store.cart).map(el => el.COUNT).reduce((a, b) => a + b, 0).toLocaleString();
    },
    totalWord() {
      let cases = [2, 0, 1, 1, 1, 2];
      return ['товар', 'товара', 'товаров'][(this.totalProductsCount % 100 > 4 && this.totalProductsCount % 100 < 20) ? 2 : cases[(this.totalProductsCount % 10 < 5) ? this.totalProductsCount % 10 : 5]];
    },
  }
}
</script>

<style scoped>
.lds-ring {
  margin: auto;
  width: 100%;
  height: 300px;
  display: none;
  align-items: center;
  justify-content: center;
}

.lds-ring--active {
  display: flex;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #CC3333 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active, 
.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.checkout {
  position: relative;
  width: 960px;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin: 0 auto 33px auto;
  padding: 32px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.checkout__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, .6);
  z-index: 1;
  border-radius: 8px;
}

.checkout__title {
  width: 100%;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 32px;
}

.checkout__column {
  width: 424px;
}

.checkout__kitchen {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.checkout__imgbox {
  width: 180px;
  height: 120px;
  margin-right: 24px;
}

.checkout__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.checkout__content {
  flex: 1;
  font-size: 14px;
  line-height: 24px;
}

.checkout__name {
  font-weight: 600;
  height: 24px;
  overflow: hidden;
}

.checkout__sku {
  font-weight: 600;
  color: #CC3333;
}

.checkout__inputbox {
  position: relative;
  height: 40px;
  margin-bottom: 16px;
}

.checkout__input {
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  height: 100%;
  padding: 0 32px 0 16px;
  transition: .3s;
}

.checkout__input:hover {
  border-color:#404040;
  transition: .3s;
}

.checkout__input:hover ~ .checkout__icon {
  fill: #404040;
  transition: .3s;
}

.checkout__input::placeholder {
  font-family: Roboto;
  font-size: 14px;
  line-height: 38px;
  color: rgba(64, 64, 64, 0.5);
}

.checkout__input--filled ~ .checkout__icon {
  fill: #404040;
  transition: .3s;
}

.checkout__input--alert {
  border-color: #CC3333;
  transition: .3s;
}

.checkout__input--alert ~ .checkout__icon {
  fill: #CC3333;
  transition: .3s;
}

.checkout__icon {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: .3s;
}

.checkout__textarea {
  font-family: Roboto;
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 12px 16px;
  resize: none;
  transition: .3s;
}

.checkout__textarea:hover {
  border-color:#404040;
  transition: .3s;
}

.checkout__textarea::placeholder {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: rgba(64, 64, 64, 0.5);
}

.checkout__filebold {
  font-weight: 600;
  margin-bottom: 16px;
}

.checkout__filelabel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 16px;
  border: 2px dashed #E5E5E5;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  font-size: 14px;
  line-height: 16px;
  color: rgba(64, 64, 64, 0.5);
  cursor: pointer;
  transition: .3s;
}

.checkout__filelabel:hover {
  border-color:#404040;
  transition: .3s;
}

.checkout__file {
  display: none;
}

.checkout__alert {
  font-size: 12px;
  line-height: 14px;
  color: #CC3333;
  padding-left: 16px;
  margin-bottom: 24px;
}

.checkout__note {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
  color: rgba(64, 64, 64, 0.5);
}

.checkout__link {
  color: #404040;
  padding-bottom: 2px;
  transition: .3s;
}

.checkout__link:hover {
  border-bottom: 1px solid #404040;
  transition: .3s;
}

.checkout__button {
  font-size: 14px;
  line-height: 21px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  margin: 24px auto 0 auto;
  display: block;
}

.checkout__summ {
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  margin-top: 32px;
}

.checkout__subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 24px;
}


.product {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.product__imgbox {
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  margin-right: 24px;
  cursor: pointer;
  background: #F5F5F5;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__img {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: auto;
  height: 100%;
  max-width: initial !important;
}

.product__close {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; 
  top: 0;
  margin: auto;
  cursor: pointer;
}

.product__content {
  flex: 1;
  display: flex;
  align-items: center;
}

.product__text {
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  margin-right: 24px
}

.product__name {
  color: rgba(64, 64, 64, 0.5);
  max-height: 48px;
  overflow: hidden;
  cursor: pointer;
}

.product__subname {
  max-height: 24px;
  overflow: hidden;
}

.product__quantity {
  margin-bottom: 8px;
}

.product__buttons {
  width: 120px;
}

.product__button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  color: rgba(64, 64, 64, 0.5);
  cursor: pointer;
}

.product__button svg {
  margin-right: 8px;
}
</style>