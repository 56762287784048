<template>
  <section v-if="store.kitchen != '' && store.step < 4" class="intro">
    <swiper class="intro__imgbox" :slides-per-view="1" :thumbs="{ swiper: thumbsSwiper, multipleActiveThumbs: false }">
      <swiper-slide v-for="(pic, index) in store.kitchen.PICTURES" :key="index">
        <img v-lazy="`https://33komoda.ru/${pic}`" class="intro__img" />
      </swiper-slide>
    </swiper>

    <div class="swiper-thumbs">
      <swiper
        class="swiper-thumbs__swiper"
        :slides-per-view="5"
        watch-slides-visibility
        watch-slides-progress
        :touch-ratio="0"
        :slide-to-clicked-slide="true"
        @swiper="setThumbsSwiper"
      >
        <swiper-slide v-for="(pic, index) in store.kitchen.PICTURES" :key="index" class="swiper-thumbs__slide">
          <img v-lazy="`https://33komoda.ru/${pic}`" class="intro__img" />
        </swiper-slide>
      </swiper>
    </div>

    <div class="intro__header">
      <div class="intro__title">
        {{ store.kitchen.NAME }}
      </div>
      <div class="intro__article">#{{ store.kitchen.XML_ID }}</div>
      <router-link class="intro__button" :to="`/${store.kitchen.ID}?partnerId=${store.partnerId}`">
        Перейти в конструктор
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.09091 0.545455V0.990909C7.09091 1.04614 7.13568 1.09091 7.19091 1.09091H8.60727C9.44127 1.09091 10.1236 1.09826 10.1236 1.10725C10.1236 1.11624 8.6193 2.62796 6.78065 4.46662L3.5083 7.73901C3.46927 7.77804 3.46925 7.84131 3.50824 7.88038L3.81884 8.19148L4.1293 8.50248C4.16834 8.54158 4.23169 8.54161 4.27077 8.50254L7.55455 5.21939L10.9091 1.8655V3.38729V4.80909C10.9091 4.86432 10.9539 4.90909 11.0091 4.90909H11.4545H11.9C11.9552 4.90909 12 4.86432 12 4.80909V2.45455V0.1C12 0.0447715 11.9552 0 11.9 0H9.54545H7.19091C7.13568 0 7.09091 0.0447715 7.09091 0.1V0.545455ZM0 7.09091V11.9C0 11.9552 0.0447715 12 0.1 12H4.90909H9.71818C9.77341 12 9.81818 11.9552 9.81818 11.9V8.46017V6.16165C9.81818 6.07257 9.7105 6.02795 9.64749 6.09091L9.27273 6.46545L8.75658 6.98129C8.73782 7.00004 8.72727 7.02549 8.72727 7.05202V8.45983V10.9091H4.90909H1.09091V7.09091V3.27273H3.55108H4.9698C4.99633 3.27273 5.02177 3.26218 5.04053 3.24342L5.55636 2.72727L5.9309 2.35251C5.99387 2.2895 5.94925 2.18182 5.86017 2.18182H3.55073H0.0999998C0.0447713 2.18182 0 2.22659 0 2.28182V7.09091Z"
            fill="white"
          />
        </svg>
      </router-link>
    </div>
  </section>
</template>

<script setup>
import SwiperCore, { Navigation, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Thumbs])

import { ref, reactive, inject } from 'vue'

const store = inject('store')
const mutations = inject('mutations')
const kitchenData = ref('')
let thumbsSwiper = ref(null)

const setThumbsSwiper = swiper => {
  thumbsSwiper.value = swiper
}
</script>

<style scoped>
.intro {
  position: relative;
}

.intro__imgbox {
  height: 266px;
  margin-bottom: 16px;
}

.intro__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.intro__header {
  width: calc(100% - 32px);
  margin: 0 auto 16px auto;
  background: white;
  border-radius: 8px;
  padding: 16px;
}

.intro__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
}

.intro__article {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #cc3333;
  margin-bottom: 16px;
}

.intro__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  color: white;
  padding: 12px 16px;
  background: #cc3333;
  border-radius: 8px;
  cursor: pointer;
}

.swiper-thumbs {
  width: calc(100% - 32px);
  position: relative;
  height: 81px;
  background: white;
  padding: 8px 16px;
  border-radius: 11px;
  margin: 0 auto 16px auto;
}
.swiper-thumbs__swiper {
  height: 100%;
}
.swiper-thumbs .swiper-slide {
  opacity: 0.5;
  cursor: pointer;
}
.swiper-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-thumbs__arrow {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  transition: 0.3s;
}
.swiper-thumbs__arrow.swiper-button-disabled {
  opacity: 0.5;
  transition: 0.3s;
  pointer-events: none;
}
.swiper-thumbs__arrow--prev {
  left: 16px;
}
.swiper-thumbs__arrow--next {
  right: 16px;
}
</style>
