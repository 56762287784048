<template>
  <div class="app app--mobile code-form-page">
    <div class="code-form">
      <div class="code-form__wrapper">
        <div class="code-form__background">
          <img src="@/assets/bg.svg" alt="" class="code-form__bg--mobile" />
        </div>
        <div class="code-form__content--mobile" v-if="isEnter">
          <div class="code-form__title">Вход в конструктор</div>
          <div class="code-form__success">Вы успешно вошли</div>
          <div class="code-form__imagebox">
            <img class="code-form__img" src="@/assets/code-success.svg" alt="" />
          </div>
          <div class="code-form__button" @click="enterConstructor(code)">Скорей к конструктору</div>
        </div>
        <div class="code-form__content--mobile" v-else-if="isError">
          <div class="code-form__title">Вход в конструктор</div>
          <div class="code-form__success">Неверный код</div>
          <div class="code-form__imagebox">
            <img class="code-form__img" src="@/assets/code-error.svg" alt="" />
          </div>
          <div class="code-form__button" @click="retry">Попробовать снова</div>
        </div>
        <div class="code-form__content--mobile" v-else>
          <div class="code-form__title">Вход в конструктор</div>
          <input
            type="text"
            placeholder="Код партнера"
            class="code-form__input"
            :value="code"
            @input="code = $event.target.value"
          />
          <div class="code-form__text">
            Код партнера можно посмотреть в ЛК портала в разделе «панель», либо уточнить у персонального менеджера
          </div>
          <div class="code-form__button" @click="handleRedirect(code)">Войти</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const code = ref("");
const isEnter = ref(false);
const isError = ref(false);

const checkCode = async (code) => {
  const data = await fetch(`https://33komoda.ru/kitchen-planning/?do=getPartner&id=${code}`);
  const responsePartner = await data.json();

  if (Object.hasOwn(responsePartner, "partner")) {
    return true;
  } else {
    return false;
  }
};
const handleRedirect = async (code) => {
  const isExist = await checkCode(code);
  if (!isExist) {
    isError.value = true;
    return false;
  }
  isEnter.value = true;
};

const retry = () => {
  isError.value = false;
};

const enterConstructor = (code) => {
  window.location.replace(`#/?partnerId=${code}`);
  window.location.reload();
};
</script>

<style>
.code-form-page .header__cover {
  height: 0;
}
.code-form-page .footer,
.code-form-page .header {
  margin-bottom: 0;
}
.code-form {
  height: calc(100vh - 4px);
}

.code-form__wrapper {
  height: 100%;
  display: flex;
  position: relative;
}

.code-form__background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.code-form__bg--mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.code-form__content--mobile {
  box-shadow: 0px 4px 20px 0px #0000004d;
  background-color: #fff;
  border-radius: 16px;
  padding: 40px 16px;
  margin: auto;
  width: 315px;
  z-index: 2;
}

.code-form__title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-bottom: 24px;
}

.code-form__input {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  background-color: #f4f4f5;
  margin-bottom: 8px;
  border: 1px solid #f4f4f5;
  transition: 0.3s;
}

.code-form__input--error {
  border: 1px solid red;
}

.code-form__input::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.4;
  color: #000;
}

.code-form__text {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 24px;
  color: #040404;
}

.code-form__success {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-bottom: 24px;
}
.code-form__imagebox {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.code-form__button {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  background-color: #bd2727;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  padding: 16px 0;
  cursor: pointer;
}
</style>
