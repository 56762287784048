<template>
  <a class="call" :href="`tel:${store.partner.PHONE}`">
    <svg width="22" height="27" viewBox="0 0 22 27" fill="none">
      <path
        d="M16.278 26.564C6.72755 21.9081 1.75627 10.1587 0.214672 3.94479C0.0791774 3.39864 0.360941 2.84495 0.870847 2.60697L5.28819 0.54529C5.92105 0.249918 6.67185 0.550294 6.92635 1.20067L8.84808 6.11177C8.9971 6.49258 8.94364 6.9229 8.70597 7.25567L7.43769 9.03147C7.13693 9.45259 7.13734 10.0184 7.43872 10.4391L12.43 17.4062C12.7211 17.8125 13.2322 17.998 13.7161 17.8729L15.8427 17.3232C16.251 17.2176 16.6847 17.3323 16.9875 17.6258L21.058 21.5716C21.5655 22.0635 21.545 22.8837 21.0137 23.3497L17.558 26.3804C17.2044 26.6906 16.7008 26.7702 16.278 26.564Z"
        fill="white"
      />
    </svg>
  </a>
</template>

<script setup>
import { inject } from 'vue'

const store = inject('store')
</script>

<style scoped>
.call {
  position: fixed;
  background: #cc3333;
  right: 32px;
  bottom: 32px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
</style>
