<template>
  <div class="app app--desktop">
    <div class="error">
      <div class="error__content">
        <div>
          <div class="error__title">
            Упс
          </div>
          <div class="error__subtitle">
            Увы, но эта страница затерялась где-то в галактике Интернета
          </div>
        </div>
        <img v-lazy="require('@/assets/error.webp')" class="error__img" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.error {
  background: #e5e5e5;
  height: 100vh;
}
.error__content {
  max-width: 1040px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.error__title {
  font-weight: 900;
  font-size: 60px;
  line-height: 54px;
  color: #cf4646;
  margin-bottom: 16px;
}
.error__subtitle {
  font-size: 20px;
  line-height: 30px;
  max-width: 373px;
}
.error__img {
  width: 576px;
}
</style>
