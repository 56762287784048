<template>
  <section class="intro" v-if="store.kitchen != '' && store.step < 4">
    <swiper class="intro__imgbox" :slides-per-view="1">
      <swiper-slide v-for="(pic, index) in store.kitchen.PICTURES" :key="index">
        <img class="intro__img" v-lazy="`https://33komoda.ru/${pic}`" />
      </swiper-slide>
    </swiper>

    <div class="intro__header">
      <div class="intro__title">{{ store.kitchen.NAME }}</div>
      <div class="intro__article">#{{ store.kitchen.XML_ID }}</div>
    </div>

    <NavigationMobile />
  </section>
</template>

<script>
import NavigationMobile from '../../components/mobile/NavigationMobile.vue'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

export default {
  inject: ['store', 'mutations'],
  components: {
    NavigationMobile,
    Swiper,
    SwiperSlide
  }
}
</script>

<style scoped>
.intro {
  margin-bottom: 25px;
}

.intro__imgbox {
  height: 265px;
  width: 100%;
}

.intro__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.intro__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 24px;
}

.intro__header:after {
  content: '';
  height: 1px;
  width: calc(100% - 32px);
  background: #e5e5e5;
  position: absolute;
  left: 16px;
  bottom: 0;
}

.intro__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  height: 28px;
  overflow: hidden;
}

.intro__article {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #cc3333;
  margin-left: 24px;
}
</style>
