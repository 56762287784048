<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
    <rect width="34" height="34" transform="translate(34) rotate(90)" fill="transparent" />
    <path
      d="M13.0812 10.2778L20.6367 18.7778L13.0812 27.2778"
      stroke="#404040"
      stroke-width="1.02778"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
