<template>
  <section v-if="store.kitchen != '' && store.step < 4" class="intro">
    <div class="intro__column intro__column--first">
      <swiper
        class="intro__imgbox"
        :slides-per-view="1"
        :thumbs="{ swiper: thumbsSwiper, multipleActiveThumbs: false }"
        :touch-ratio="0"
        :navigation="{
          nextEl: '.intro__arrow--next',
          prevEl: '.intro__arrow--prev'
        }"
      >
        <swiper-slide v-for="(pic, index) in store.kitchen.PICTURES" :key="index">
          <img v-lazy="`https://33komoda.ru/${pic}`" class="intro__img" />
        </swiper-slide>

        <div class="intro__arrows">
          <div class="intro__arrow intro__arrow--prev">
            <ArrowLeftSvg class="intro__icon--prev" />
          </div>
          <div class="intro__arrow intro__arrow--next">
            <ArrowRightSvg class="intro__icon--next" />
          </div>
        </div>
      </swiper>
    </div>

    <div class="intro__column">
      <div class="intro__header">
        <div class="intro__title">
          {{ store.kitchen.NAME }}
        </div>
        <div class="intro__article">#{{ store.kitchen.XML_ID }}</div>
        <router-link class="intro__button" :to="`/${store.kitchen.ID}?partnerId=${store.partnerId}`">
          Перейти в конструктор
        </router-link>
      </div>

      <div class="swiper-thumbs">
        <swiper
          class="swiper-thumbs__swiper"
          :slides-per-view="5"
          watch-slides-visibility
          watch-slides-progress
          :touch-ratio="0"
          :slide-to-clicked-slide="true"
          @swiper="setThumbsSwiper"
        >
          <swiper-slide v-for="(pic, index) in store.kitchen.PICTURES" :key="index">
            <img v-lazy="`https://33komoda.ru/${pic}`" class="intro__img" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script setup>
import ArrowLeftSvg from '@/components/svg/ArrowLeftSvg.vue'
import ArrowRightSvg from '@/components/svg/ArrowRightSvg.vue'

import SwiperCore, { Navigation, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Thumbs])

import { ref, reactive, inject } from 'vue'

const store = inject('store')
const mutations = inject('mutations')
const kitchenData = ref('')
let thumbsSwiper = ref(null)

const setThumbsSwiper = swiper => {
  thumbsSwiper.value = swiper
}
</script>

<style scoped>
.intro {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 40px auto;
  max-width: 1440px;
  padding: 0 40px;
  min-width: 1024px;
}

.intro__column {
  width: calc(50% - 20px);
  height: 470px;
}

.intro__column--first {
  border-radius: 8px;
  overflow: hidden;
}

.intro__imgbox {
  height: 100%;
}

.intro__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.intro__arrow {
  cursor: pointer;
  width: 118px;
  height: 118px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: 0.3s;
}

.intro__icon--next {
  position: relative;
  right: 20px;
}

.intro__icon--prev {
  position: relative;
  left: 20px;
}

.intro__arrow.swiper-button-disabled {
  cursor: default;
  opacity: 0.5;
  transition: 0.3s;
}

.intro__arrows {
  position: absolute;
  width: calc(100% + 118px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: -59px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.3s;
}

.intro__header {
  margin-bottom: 41px;
  background: white;
  border-radius: 8px;
  padding: 40px;
}

.intro__title {
  font-weight: bold;
  font-size: 32px;
  line-height: 45px;
  margin-bottom: 24px;
}

.intro__article {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #cc3333;
  margin-bottom: 24px;
}

.intro__button {
  display: block;
  font-size: 18px;
  line-height: 30px;
  color: white;
  padding: 8px 24px;
  background: #cc3333;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
}

.swiper-thumbs {
  position: relative;
  height: 142px;
  background: white;
  padding: 8px 40px;
  border-radius: 11px;
}
.swiper-thumbs__swiper {
  height: 100%;
}
.swiper-thumbs .swiper-slide {
  opacity: 0.5;
  cursor: pointer;
}
.swiper-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-thumbs__arrow {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  transition: 0.3s;
}
.swiper-thumbs__arrow.swiper-button-disabled {
  opacity: 0.5;
  transition: 0.3s;
  pointer-events: none;
}
.swiper-thumbs__arrow--prev {
  left: 16px;
}
.swiper-thumbs__arrow--next {
  right: 16px;
}
</style>
